import { SocialNetworksMap } from "./types";

export const socialNetworksMap: SocialNetworksMap = {
  ua: [
    {
      name: "facebook",
      href: "https://www.facebook.com/Zakaz.ua",
    },
    {
      name: "instagram",
      href: "https://www.instagram.com/zakaz.ua/",
    },
    {
      name: "linkedin",
      href: "https://www.linkedin.com/company/zakaz-ua/",
    },
    {
      name: "tiktok",
      href: "https://www.tiktok.com/@zakaz.ua",
    },
  ],
  md: [
    {
      name: "facebook",
      href: "https://www.facebook.com/md.zakaz",
    },
    {
      name: "instagram",
      href: "https://www.instagram.com/md.zakaz_/",
    },
    {
      name: "linkedin",
      href: "https://www.linkedin.com/company/zakaz-ua/",
    },
  ],
};
