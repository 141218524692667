import css from 'styled-jsx/css';
import { phone_tablet, tablet, tablet_desktop } from '../styles/media';

export const styles = css`
    .GeneralPagesLayout {
      flex: 1;
    }
    .GeneralPagesLayout__contentWrapper {
      display: flex;
      margin: 0 auto;
      max-width: 1366px;
    }
    .GeneralPagesLayout_light {
      background-color: var(--colors-white);
    }
    .GeneralPagesLayout_muted:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      opacity: 0.5;
      z-index: 998;
    }
    .GeneralPagesLayout__loadingBarWrapper {
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
      width: 100%;
      border-radius: inherit;
      -webkit-border-radius: inherit;
      -moz-border-radius: inherit;
      overflow: hidden;
      z-index: 999;
    }
    .GeneralPagesLayout__notifications {
      position: absolute;
      width: 100%;
      margin-top: 12px;
      pointer-events: none;
    }
    .GeneralPagesLayout__notificationsWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    @media ${phone_tablet} {
      .GeneralPagesLayout__loadingBarWrapper {
        top: 50px;
      }
      .filtersStickyPortalContainer_sticky {
        position: sticky;
        top: 50px;
        z-index: 10;
      }
    }
    @media ${tablet_desktop} {
      .GeneralPagesLayout__notifications {
        margin-top: 8px;
        margin-right: 20px;
      }
      .GeneralPagesLayout__notificationsWrapper {
        align-items: flex-end;
        margin: 0 auto;
        max-width: 1366px;
        width: 100%;
        padding: 0 10px;
      }
    }
    @media ${tablet} {
      .GeneralPagesLayout__notificationsWrapper {
        padding: 0 20px;
      }
      .GeneralPagesLayout__notifications {
        margin-top: 15px;
      }
    }
    .GeneralPagesLayout_whiteBackground {
      background-color: #ffffff;
    }
  `;
