import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';

export const styles = css`
  .ZakazBanner {
    margin-bottom: 24px;
  }

  .ZakazBanner__logo {
    width: 145px;
    height: 39px;
    display: block;
  }

  @media ${phone} {
    .ZakazBanner__logo {
      margin-bottom: 30px;
    }
  }
`;
