import Router from 'next/router';
import { RefObject, useEffect, useState } from 'react';
import { Category } from 'src/redux/apiTypes';
import { selectCategories } from 'src/redux/categories/categoriesSelectors';
import { useAppSelector } from 'src/redux/hooks';
import useLocalization from 'src/services/localization/useLocalization';
import MobileMenuArrowBackBlock from './MobileMenuArrowBackBlock';
import MobileMenuCategoriesBlock from './MobileMenuCategoriesBlock';
import { styles } from './MobileMenuContent.styles';

interface Props {
  setIsScrollArrowDisplayed: (value: boolean) => void;
  contentBodyRef: RefObject<HTMLDivElement>;
  onClose(): void;
}

const MobileMenuContent = (props: Props) => {
  const { onClose, contentBodyRef, setIsScrollArrowDisplayed } = props;
  const localize = useLocalization();

  useEffect(() => {
    Router.events.on('routeChangeStart', onClose);

    return () => {
      Router.events.off('routeChangeStart', onClose);
    };
  }, [onClose]);

  const categories = useAppSelector(selectCategories);

  const [selectedCategories, setSelectedCategories] = useState([{ children: categories } as Category]);   //@todo: remove `as Category[]`
  const isMainMenu = selectedCategories.length === 1;

  const handlePreviousCategoryClick = () => {
    setSelectedCategories(selectedCategories.slice(0, -1));
  };

  return (
    <div
      className='MobileMenuContent'
      data-marker='Content Menu mobile'
    >
      {!isMainMenu &&
        <MobileMenuArrowBackBlock
          onClick={handlePreviousCategoryClick}
          label={selectedCategories[selectedCategories.length - 1].title}
        />
      }

      {
        isMainMenu && (
          <div className='MobileMenuContent__header'>
            <span className="MobileMenuContent__title">
              {localize('categories.title')}
            </span>
            <button
              type='button'
              onClick={onClose}
              className='MobileMenuContent__close icon-close'
              data-marker='close mobile menu'
            />
          </div>
        )
      }

      <MobileMenuCategoriesBlock
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        isMainMenu={isMainMenu}
        setIsScrollArrowDisplayed={setIsScrollArrowDisplayed}
        contentBodyRef={contentBodyRef}
      />
      <style jsx>{styles}</style>
    </div>
  );
};

export default MobileMenuContent;
