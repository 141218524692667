import * as React from 'react';
import { styles } from './MobileMenuContent.styles';

interface Props {
  label: string
  onClick(): void
}

const MobileMenuArrowBackBlock: React.FC<Props> = props => {
  return (
    <div
      className='MobileMenuContent__menuItem'
      onClick={props.onClick}
      data-testid='arrowBack'
    >
      <span className='MobileMenuContent__menuItemIcon MobileMenuContent__menuItemIcon_dark icon-arrow-back' />
      <span className='MobileMenuContent__menuItemText MobileMenuContent__menuItemText_bold'>
        {props.label}
      </span>
      <style jsx>{styles}</style>
    </div>
  );
};

export default MobileMenuArrowBackBlock;
