import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .Tag {
    display: inline-block;
    padding: 4px 12px;
    ${typography.text}
    line-height: 24px;
    border-radius: var(--border-radius-block);
    border: 1px solid var(--colors-brandLightGrey1);
    background: var(--colors-white);
  }

  .Tag:first-letter {
    text-transform: uppercase;
  }

  .Tag_clickable {
    cursor: pointer;
    transition: 0.2s color ease-in-out;
  }

  .Tag_clickable:hover {
    border-color: var(--colors-mainBrand);
  }

  .Tag_active {
    border-color: var(--colors-mainBrand);
    font-weight: 600;
  }
`;
