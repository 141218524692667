import { useEffect } from "react";
import LoadingRing from "src/components/views/Loaders/LoadingRing/LoadingRing";
import { disableBodyScroll, enableBodyScroll } from "src/utils/system/scrollLock";

interface Props {
  topPositionInPx?: number;
}

const CategoryLoader = (props: Props) => {
  const { topPositionInPx = 0 } = props;

  useEffect(() => {
    disableBodyScroll();

    return enableBodyScroll;
  }, []);

  return (
    <div className="CategoryLoader">
      <div className="CategoryLoader__ring">
        <LoadingRing color="var(--colors-mainBrand)" size="44" />
      </div>

      <style jsx>{`
        .CategoryLoader {
          top: ${topPositionInPx}px;
          left: 0;
          position: fixed;
          height: 100%;
          width: 100%;
          background-color: rgba(248, 248, 248, 0.48);
          z-index: 3;
          overflow: hidden;
        }
        .CategoryLoader__ring {
          margin-top: 250px;
        }
      `}
      </style>
    </div>
  );

};

export default CategoryLoader;
