import usePopularTags from "src/hooks/usePopularTags/usePopularTags";
import useSearchExperiment from "./useSearchExperiment";

interface PopularTagsParams {
  searchBoxIsActive: boolean;
  resultsIsVisible: boolean;
}

const useSearchPopularTags = (params: PopularTagsParams) => {
  const { searchBoxIsActive, resultsIsVisible }  = params;
  const searchVariant = useSearchExperiment();

  const shouldFetchPopularProducts = searchBoxIsActive && searchVariant === 1;
  const { tags, isLoadingTags } = usePopularTags(shouldFetchPopularProducts);

  const popularIsVisible = searchBoxIsActive && !resultsIsVisible && tags.length > 0 && searchVariant === 1;

  return {
    tags,
    isLoadingTags,
    popularIsVisible,
  };
};

export default useSearchPopularTags;
