import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .ZsuHelp {
    ${typography.h9}
    text-decoration: none;
    transition: none;
  }

  .ZsuHelp_notAccented {
    ${typography.notAccentedText}
  }

  .ZsuHelp:hover,
  .ZsuHelp:hover .ZsuHelp__icon  {
    color: var(--colors-zakazGreen);
  }

  .ZsuHelp_notAccented:hover,
  .ZsuHelp_notAccented:hover .ZsuHelp__icon {
    color: var(--colors-white);
  }

  .ZsuHelp__icon {
    font-size: 8px;
    padding-left: 4px;
    color: var(--colors-brandLightGrey8);
  }

  .ZsuHelp__icon_notAccented {
    font-size: 10px;
  }
`;
