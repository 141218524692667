import cn from 'classnames';
import dynamic from 'next/dynamic';
import * as React from 'react';
import { Fragment, useRef, useState } from 'react';
import CategoriesMenu from 'src/components/containers/CategoriesMenu/CategoriesMenu';
import Responsive from 'src/components/views/Responsive/Responsive';
import useToggles from 'src/services/toggles/useToggles';
import { Facet } from '../components/containers/CatalogActiveFiltersList/getActiveFilters';
import { GetHandlerDeleteFiltersFunc, HandleFilterFunc } from '../components/containers/CatalogFilters/CatalogFilters';
import Footer from '../components/containers/Footer/Footer';
import Header from '../components/containers/Header/Header';
import CatalogFiltersMobile from '../components/views/CatalogFiltersMobile/CatalogFiltersMobile';
import LoadingBarIndicator from '../components/views/LoadingBarIndicator/LoadingBarIndicator';
import useHeaderBottomPosition from '../hooks/useHeaderBottomPosition/useHeaderBottomPosition';
import { Filter } from '../redux/apiTypes';
import { styles } from './GeneralPagesLayout.style';

const CookiesPolicyPopup = dynamic(
  () => import('../components/containers/CookiesPolicyPopup/CookiesPolicyPopup'),
  { ssr: false },
);

const Notifications = dynamic(
  () => import('../components/containers/Notifications/Notifications'),
  { ssr: false },
);

export interface GeneralPagesLayoutProps {
  isBgLight?: boolean;
  isLoading?: boolean;
  whiteBackground?: boolean;
  withStickyFilters?: boolean;
  showFilters?: boolean;
  filters?: Filter[];
  activeFilters?: Facet[];
  handleFilter?: HandleFilterFunc;
  isFetchingProducts?: boolean;
  excludeParams?: string[];
  deleteFilters?: GetHandlerDeleteFiltersFunc;
  showFooter?: boolean;
  withNotifyGamify?: boolean;
  fixedMenu?: boolean;
}


export const GeneralPagesLayout: React.FC<GeneralPagesLayoutProps> = props => {
  const {
    children,
    isBgLight,
    isLoading,
    whiteBackground,
    showFooter = true,
    withNotifyGamify,
    fixedMenu,
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { WITH_COOKIE_POLICY } = useToggles();

  const headerElRef = useRef();

  const { headerBottomPosition } = useHeaderBottomPosition(headerElRef);

  return (
    <Fragment>
      {isLoading && (
        <div className='GeneralPagesLayout__loadingBarWrapper'>
          <LoadingBarIndicator />
        </div>
      )}

      <Header
        rootElRef={headerElRef}
        headerBottomPosition={headerBottomPosition}
        desktopMenuIsFixed={fixedMenu}
        desktopMenuIsOpen={menuIsOpen}
        toggleDesktopMenu={setMenuIsOpen}
        filtersSlot={
          props.withStickyFilters && props.showFilters ? (
            <CatalogFiltersMobile
              filters={props.filters}
              activeFilters={props.activeFilters}
              handleFilter={props.handleFilter}
              isFetchingProducts={props.isFetchingProducts}
              excludeParams={props.excludeParams}
              deleteFilters={props.deleteFilters}
            />
          ) : null
        }
        notificationSlot={
          <div className='GeneralPagesLayout__notifications'>
            <div className='GeneralPagesLayout__notificationsWrapper'>
              <Notifications withNotifyGamify={withNotifyGamify} />
            </div>
          </div>
        }
      />
      <div
        className={cn(
          'GeneralPagesLayout', {
            'GeneralPagesLayout_light': isBgLight,
            'GeneralPagesLayout_muted': isLoading,
            'GeneralPagesLayout_whiteBackground': whiteBackground,
          })}
      >
        <div className="GeneralPagesLayout__contentWrapper">
          {(fixedMenu || menuIsOpen) &&
              <Responsive desktop displayNoneMode>
                <CategoriesMenu
                  topPosition={headerBottomPosition}
                  onCloseMenu={() => setMenuIsOpen(false)}
                />
              </Responsive>
          }

          {children}
        </div>
      </div>
      {showFooter && <Footer />}

      { WITH_COOKIE_POLICY && <CookiesPolicyPopup /> }
      <style jsx>{styles}</style>
      <style jsx>{`
        ${ headerBottomPosition !== 0 ? `
          .GeneralPagesLayout__contentWrapper {
            padding-top: ${ headerBottomPosition || 148 }px;
          }
        ` : '' }
      `}</style>
    </Fragment>
  );
};

export default GeneralPagesLayout;
