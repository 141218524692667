import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  .FilterableLayoutHeaderSlot__title {
    ${typography.h1}
    margin: 24px 0;
  }
  @media ${phone} {
    .FilterableLayoutHeaderSlot__title {
      font-size: 22px;
      line-height: 26px;
      margin: 16px 0;
    }
  }
`;
