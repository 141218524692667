import { CustomRouter, UniversalObject } from 'globals';
import { NOT_MULTI_FILTER_PARAMS } from '../../redux/constants';
import makeSearchString from '../system/makeSearchString';
import filterSearchParamsArr from './filterSearchParamsArr';
import isCategoriesPage from './isCategoriesPage';
import toggleQueryFilter from './toggleQueryFilter';

function deleteFiltersInQueryString(
  type: string | null,
  router: CustomRouter<UniversalObject>,
  excludeParams: string[],
) {
  const { query, push, asPath, pathname } = router;
  const newQuery = { ...query };

  delete newQuery.page;

  if (!type) {
    const unremovableParams = [...excludeParams, 'sort', 'per_page', 'q'];
    Object.keys(newQuery).forEach(key => {
      if (unremovableParams.indexOf(key) === -1) {
        delete newQuery[key];
      }
    });
  } else {
    delete newQuery[type];
  }

  const path = asPath.split('?')[0];
  const searchString = makeSearchString(newQuery, excludeParams);
  push({
    pathname,
    query: newQuery,
  }, `${ path }${ searchString }`);
}

function deleteFiltersWithFilterSegmentOfURL(
  type: string | null,
  router: CustomRouter<UniversalObject>,
  excludeParams: string[],
) {
  const { query, push, asPath, pathname } = router;
  const [path, params] = asPath.split('?');

  let newPath = path;
  let newQuery = { ...query };
  let newPathname = pathname;

  delete newQuery.page;

  if (!type) {
    const unremovableParams = [...excludeParams, 'sort', 'per_page', 'q'];

    Object.keys(newQuery).forEach(key => {
      if (unremovableParams.indexOf(key) === -1) {
        delete newQuery[key];
      }
    });

    newPathname = newPathname.replace('/[firstLevelFilter]', "");

    // 0 - root; 1 - lang; 2 - root of categories; 3 - category Name; 4 - first filter param
    const segments = newPath.split('/');
    newPath = segments.slice(0, 4).join('/') + '/';

    delete newQuery.firstLevelFilter;
  } else {
    delete newQuery[type];

    if(newQuery.firstLevelFilter) {
      const [firstLevelFilterType] = newQuery.firstLevelFilter.split('=');

      if(firstLevelFilterType === type) {
        const searchParamsArr = params ? params.split('&') : [];
        const otherFilterParams = filterSearchParamsArr(searchParamsArr, [...NOT_MULTI_FILTER_PARAMS, type]);

        if (otherFilterParams.length > 0) {
          //если есть другие не фильтровые параметры нужно вставить их
          const firstFilterParam = otherFilterParams[0];
          const [newType, newName] = decodeURIComponent(firstFilterParam).split('=');
          newQuery = toggleQueryFilter(newType, newName, newQuery);
          newQuery.firstLevelFilter = firstFilterParam;

          // 0 - root; 1 - lang; 2 - root of categories; 3 - category Name; 4 - first filter param
          const segments = path.split('/');
          segments[4] = firstFilterParam;
          newPath = segments.join('/') ;
        } else {
          //если нет удалить сигмент фильтра
          delete newQuery.firstLevelFilter;
          newPathname = newPathname.replace('/[firstLevelFilter]', '');

          // 0 - root; 1 - lang; 2 - root of categories; 3 - category Name; 4 - first filter param
          const segments = path.split('/');
          newPath = segments.slice(0, 4).join('/') + '/';
        }
      }
    }
  }

  const searchString = makeSearchString(newQuery, excludeParams);

  push({
    pathname: newPathname,
    query: newQuery,
  }, `${newPath }${ searchString }`);
}

const deleteFilters = (
  type: null | string,
  router: CustomRouter<UniversalObject>,
  excludeParams: string[],
) => {
  if(isCategoriesPage(router.asPath)) {
    deleteFiltersWithFilterSegmentOfURL(type, router, excludeParams);
  } else {
    deleteFiltersInQueryString(type, router, excludeParams);
  }
};

export default deleteFilters;
