import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { styles } from './CategoriesMenuListItem.styles';

export interface CategoriesMenuListItemProps {
  hasChild?: boolean;
  isActive?: boolean;
  linkHref: string;
  imageUrl?: string;
  title: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

const CategoriesMenuListItem = (props: CategoriesMenuListItemProps) => {
  const {
    hasChild,
    isActive,
    linkHref,
    imageUrl,
    title,
    onMouseEnter,
    onMouseLeave,
    onClick,
  } = props;

  return (
    <li title={title} className='CategoriesMenuListItem'>
      <Link
        href={linkHref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-status={isActive ? 'active' : null}
        onClick={onClick}
      >
        <span
          className={
            classNames(
              'CategoriesMenuListItem__link',
              {
                'CategoriesMenuListItem__link_withChildren': hasChild,
                'CategoriesMenuListItem__link_active': isActive,
              },
            )
          }
        >
          {imageUrl ?
            (
              <div className="CategoriesMenuListItem__icon">
                <Image
                  src={imageUrl}
                  alt={title}
                  width={18}
                  height={18}
                />
              </div>
            )
            : null
          }
          <span className='CategoriesMenuListItem__title'>
            {title}
          </span>
        </span>
      </Link>
      <style jsx>{styles}</style>
    </li>
  );
};

export default CategoriesMenuListItem;
