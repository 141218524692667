import { DeliveryType } from "src/redux/apiTypes";
import { LocalizeFunc } from "src/services/localization/types";
import { TimeWindow } from "src/swr/useScheduleDelivery/scheduleDeliveryTypes";
import convertTimeWindowToString from "src/utils/delivery/convertTimeWindowToString";

interface Params {
  timeWindow: TimeWindow | null;
  deliveryType: DeliveryType | null;
  localize: LocalizeFunc;
}

export const getTimeWindowText = ({ timeWindow, deliveryType, localize }: Params) => {
  if (!timeWindow) {
    return null;
  }

  const firstPart = deliveryType === 'pickup'
    ? localize('slot_text_first_part_pickup')
    : localize('slot_text_first_part_deliver');


  const slotTimeText = convertTimeWindowToString(timeWindow, localize);

  return `${firstPart} ${slotTimeText}`;
};
