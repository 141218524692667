import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getCanonicalHref } from 'src/components/containers/RobotsAndCanonicalModeManager/RobotsAndCanonicalModeManager';
import getAbsoluteUrl from 'src/utils/api/getAbsoluteUrl';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { definePromotionsRobotsAndCanonicalMode } from 'src/utils/marketing/defineRobotsAndCanonicalMode/definePromotionsRobotsAndCanonicalMode';
import getSeoPagination from 'src/utils/marketing/getSeoPagination';

interface Props {
  count?: number;
  title: string;
  description: string;
}

const PromotionsPageHead = (props: Props) => {
  const { count, title, description } = props;
  const { host } = useAppConfig();
  const router = useRouter();
  const withGetParam = router.asPath.includes('/?');

  const [state, setState] = useState(definePromotionsRobotsAndCanonicalMode({
    urlStr: router.asPath,
    router,
  }));

  useEffect(() => {
    setState(definePromotionsRobotsAndCanonicalMode({ urlStr: router.asPath }));
  }, [router.asPath]);

  return (
    <Head>
      {state.withCanonicalTag &&
        <link
          rel="canonical"
          href={getCanonicalHref(getAbsoluteUrl(router.asPath, host).fullUrl, withGetParam)}
        />
      }
      {
        getSeoPagination({ router, countItem: count, host })
      }
      {state.indexAndFollow &&
        <meta name="robots" content="index, follow" />
      }
      {(state.noIndexNoFollow) &&
        <meta name="robots" content="noindex, nofollow" />
      }
      <title key='title'>
        {title}
      </title>
      <meta name='description' content={description} key='meta-description' />
    </Head>
  );
};

export default PromotionsPageHead;
