import css from 'styled-jsx/css';
import { desktop, phone, tablet } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
    .Footer {
      position: relative;
      z-index: 50;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      color: var(--colors-white);
      background-color: var(--colors-text);
    }
    .Footer__bottom {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .Footer__bottomInner {
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .Footer__copyright {
      ${typography.text};
      color: var(--colors-white);
      opacity: 0.5;
    }
    .Footer__main {
      min-height: 336px;
    }
    .Footer__mainInner {
      display: flex;
      padding-top: 60px;
      padding-bottom: 50px;
    }
    .Footer__block {
      flex-basis: 25%;
    }
    .Footer__menu {
      margin-bottom: 50px;
    }
    .Footer__socials {
      margin-top: 16px;
    }
    .Footer__blockLine {
      margin-bottom: 24px;
    }
    @media ${desktop} {
      .Footer__logo {
        flex-grow: 2
      }
    }
    @media ${tablet} {
      .Footer__mainInner {
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      .Footer__block {
        flex-basis: 50%;
      }
    }
    @media ${phone} {
      .Footer__mainInner {
        flex-direction: column;
        padding-bottom: 0;
      }
      .Footer__block {
        margin-bottom: 30px;
      }
      .Footer__logo {
        margin: 0;
      }
      .Footer__blockLine {
        margin-bottom: 20px;
      }
    }
  `;
