import { FetchBannersParams, GetFetchBannersParamsFn } from '../types';

export function getFetchBannersParams(options: GetFetchBannersParamsFn): FetchBannersParams {
  const { place, storeId, categoryId, apiRoot } = options;
  const baseUrl = `${apiRoot}/partners/`;


  if (place === 'category_page') {
    const url = `${baseUrl}?platform=web&place=catalog&category_id=${categoryId}&storeId=${storeId}`;

    return {
      cacheKey: `${storeId}-${categoryId}-slides`,
      url,
    };
  }

  if (place ==='home_page_for_store') {
    const url = `${baseUrl}?platform=web&place=main_page&store_id=${storeId}`;

    return {
      cacheKey: `home-page-slides-${storeId}`,
      url,
    };
  }

  const url = `${baseUrl}?platform=web&place=main_page`;

  return {
    cacheKey: `home-page-slides`,
    url,
  };
}
