import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
  .ChooseLanguage__language {
    ${typography.notAccentedText}
    padding: 0 16px;
    border-left: 1px solid var(--colors-brandLightGrey8);
    text-decoration: none;
  }
  .ChooseLanguage__language:hover {
    color: var(--colors-white);
  }
  .ChooseLanguage__language_active {
    color: var(--colors-white);
    font-weight: 600;
  }
  .ChooseLanguage__language:first-child {
    border-left: 0;
    padding-left: 0;
  }
`;
