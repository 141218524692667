import { LocalizeFunc } from "src/services/localization/types";
import { TimeWindow } from "src/swr/useScheduleDelivery/scheduleDeliveryTypes";
import convertDateToString from "../date/convertDateToString";

const convertTimeWindowToString = (
  timeWindow: TimeWindow,
  localize: LocalizeFunc,
) => {
  const datePart = convertDateToString(new Date(timeWindow.date), localize);

  const [timeFrom, timeTo] = timeWindow.time_range.split(' - ');
  let timePart = `${localize('general_from')} ${timeFrom}`;

  if ( timeTo ) {
    timePart += ` ${localize('general_to')} ${timeTo}`;
  }

  return `${datePart} ${timePart}`;
};

export default convertTimeWindowToString;
