import { FetchConfig } from 'src/redux/apiTypes';
import { Chain, ChainDto } from 'src/services/chain/types';
import transformDtoToChain from 'src/services/chain/utils/transformDtoToChain';

export async function getChains(config: FetchConfig): Promise<Chain[]> {
  const { apiRoot, xVersion } = config;
  const url = `${apiRoot}/retail_chains/?accepts_excise_payment_online=true`;
  const response = await fetch(
    url,
    {
      headers: {
        'x-version': xVersion,
      },
    },
  );

  if (!response.ok) {
    throw new Error(`An error occurred while fetching the data: ${url}`);
  }

  const data: ChainDto[] = await response.json();

  return data.map(transformDtoToChain);
}
