import { Fragment, MouseEventHandler, useEffect, useState } from "react";
import BurgerButton from "src/components/views/BurgerButton/BurgerButton";
import { sendChainsBurgerClick } from "src/utils/marketing/enhancedEcommerce";
import dynamic from "next/dynamic";
import { ModalLoader } from "src/components/containers/Modals/Modals";
import useChainsForSidebar from "../../hooks/useChainsForSidebar";
import { useSessionStorage } from "usehooks-ts";

const ChainsSidebar = dynamic(() => import('../ChainsSidebar/ChainsSidebar'), { ssr: false, loading: ModalLoader });

const RED_DOT_KEY = 'redDotWasShown';

const ChooseChains = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { retailInfoLists, skeletonIsDisplayed, chainsWithCartItemsCount } = useChainsForSidebar();

  const [redDotWasShown, setRedDotWasShown] = useSessionStorage(RED_DOT_KEY, false);
  const redDotCount = chainsWithCartItemsCount > 0 && !redDotWasShown ? chainsWithCartItemsCount : null;

  const handleClickBurger: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsOpened(!isOpened);
    sendChainsBurgerClick();

    if(redDotCount) {
      setRedDotWasShown(true);
    }
  };

  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpened(false);
      }
    };

    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);

  return (
    <Fragment>
      <BurgerButton
        isActive={isOpened}
        onClick={handleClickBurger}
        dataMarker="Open chains"
        count={redDotCount}
      />
      {
        isOpened && <ChainsSidebar
          onClose={() => setIsOpened(false)}
          retailInfoLists={retailInfoLists}
          skeletonIsDisplayed={skeletonIsDisplayed}
        />
      }
    </Fragment>
  );
};

export default ChooseChains;
