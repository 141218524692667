import Divider from 'src/components/views/Divider/Divider';
import Breadcrumbs, { BreadcrumbsItem } from '../../views/Breadcrumbs/Breadcrumbs';
import { styles } from './FilterableLayoutHeaderSlot.styles';

interface Props {
  categoryId: string;
  title: string;
  language: string;
  baseURL: string;
}

const FilterableLayoutHeaderSlot = (props: Props) => {
  const {
    categoryId,
    title,
    language,
    baseURL,
  } = props;

  const breadcrumbs: BreadcrumbsItem[] = [{
    id: categoryId,
    title,
    as: `/${categoryId}/`,
    to: `/${categoryId}/`,
    type: 'category',
  }];

  return (
    <div>
      <Breadcrumbs
        items={breadcrumbs}
        language={language}
        baseURL={baseURL}
        microMarkingIsShown
        noTopMargin
      />
      <Divider />

      <h1 className='FilterableLayoutHeaderSlot__title'>{title}</h1>

      <style jsx>{styles}</style>
    </div>
  );
};

export default FilterableLayoutHeaderSlot;
