import Image from "next/image";
import { Fragment } from "react";
import Responsive from "src/components/views/Responsive/Responsive";

interface ZakazLogoImageProps {
  country: string;
  imgRoot: string;
}

const ZakazLogoImage = (props: ZakazLogoImageProps) => {
  const { country, imgRoot } = props;
  const logoBase = `${imgRoot}/store_logos`;
  const desktopLogoUrl = `${logoBase}/zakaz_${country}.svg`;
  const mobileLogoUrl = `${logoBase}/zakaz.svg`;
  const sideLength = 28;

  return (
    <Fragment>
      <Responsive desktop>
        <img
          src={desktopLogoUrl}
          alt='zakaz desktop logo'
          height={sideLength}
        />
      </Responsive>
      <Responsive phoneTablet>
        <Image
          alt='zakaz mobile logo'
          src={mobileLogoUrl}
          height={sideLength}
          width={sideLength}
        />
      </Responsive>
    </Fragment>
  );
};

export default ZakazLogoImage;
