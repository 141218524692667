import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  .Breadcrumbs {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .Breadcrumbs_noTopMargin {
    margin-top: 0;
  }
  .Breadcrumbs__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
  }
  .Breadcrumbs__item {
    position: relative;
    padding-right: 24px;
    height: 17px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Breadcrumbs__item:before {
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 8px;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: top;
    opacity: 0.4;
  }
  .Breadcrumbs__item:last-child {
    padding-right: 0;
  }
  .Breadcrumbs__item_disabled .Breadcrumbs__link {
    cursor: auto;
    color: var(--colors-doveGray);
  }
  .Breadcrumbs__link {
    ${typography.text}
    text-decoration: none;
  }
  .Breadcrumbs__link:hover {
    color: var(--uiColors-Breadcrumbs-linkHoverText);
  }
  .Breadcrumbs__link:first-letter {
    text-transform: uppercase;
  }
  @media ${phone} {
    .Breadcrumbs {
      margin-top: 20px;
    }

    .Breadcrumbs_noTopMargin {
      margin-top: 0;
    }
  }
  `;
