import { useDispatch } from "react-redux";
import setSearchBoxIsActive from "src/redux/search/setSearchBoxIsActive";

const SearchButton = () => {
  const dispatch = useDispatch();

  const handleOpenSearch = () => {
    dispatch(setSearchBoxIsActive(true));
  };

  return (
    <button
      onClick={handleOpenSearch}
      type='button'
      data-marker='Loupe mobile'
      className='SearchButton'
    >
      <i className='icon-loupe' />
      <style jsx>{`
        .SearchButton {
          display: block;
          background: transparent;
          outline: none;
          border: 0;
          padding: 0 13px;
          font-size: 18px;
          height: 18px;
          color: var(--colors-white);
        }
      `}</style>
    </button>
  );
};

export default SearchButton;
