import cn from 'classnames';
import { Fragment, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Responsive from "src/components/views/Responsive/Responsive";
import useLocalization from 'src/services/localization/useLocalization';
import { desktop } from "src/styles/media";
import MobileMenu from "../MobileMenu/MobileMenu";
import { styles } from "./CategoriesMenuButton.styles";

export const buttonElementId = 'categories-menu-button';

export interface Props {
  desktopMenuIsFixed: boolean;
  desktopMenuIsOpened: boolean;
  toggleDesktopMenu(isActive: boolean): void;
}

const CategoriesMenuButton = (props: Props) => {
  const { desktopMenuIsFixed, desktopMenuIsOpened, toggleDesktopMenu } = props;
  const localize = useLocalization();

  const isDesktop = useMediaQuery({ query: desktop });
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const handleClick = () => {
    if (!isDesktop) {
      setMobileMenuIsOpen(!mobileMenuIsOpen);
    } else if (!desktopMenuIsFixed) {
      toggleDesktopMenu(!desktopMenuIsOpened);
    }
  };

  return (
    <Fragment>
      <button
        id={buttonElementId}
        type='button'
        className='CategoriesMenuButton'
        onClick={handleClick}
        data-marker='Categories of goods'
        data-testid='categories_menu_button'
      >
        <span className='CategoriesMenuButton__icon icon-categories' />
        <Responsive desktop>
          <span className='CategoriesMenuButton__label'>{localize('categories.title')}</span>
          {!desktopMenuIsFixed &&
            <span className={cn(
              'CategoriesMenuButton__caret icon-caret-bottom',
              { 'icon-caret-top': desktopMenuIsOpened },
            )}
              data-testid='caret'
            />
          }
        </Responsive>
      </button>

      {mobileMenuIsOpen && <MobileMenu onClose={() => setMobileMenuIsOpen(false)} />}

      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default CategoriesMenuButton;
