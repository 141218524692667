import css from 'styled-jsx/css';
import { desktop, phone, tablet } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
    .EmptyCategory {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--colors-white)
    }
    .EmptyCategory__title {
      ${typography.h2}
      width: 420px;
      text-align: center;
      margin: 32px 0;
    }
    .EmptyCategory__buttons {
        display: flex;
    }
    .EmptyCategory__link {
      margin-left: 10px;
    }
    .EmptyCategory__icon {
      font-size: 50px;
      color: var(--colors-brandLightGrey2);
    }

    @media ${desktop} {
      .EmptyCategory {
        height: 578px;
      }
    }

    @media ${tablet} {
      .EmptyCategory {
        height: 390px;
      }
    }

    @media ${phone} {
      .EmptyCategory {
        height: 360px;
      }
      .EmptyCategory__title {
        ${typography.h3}
        width: auto;
        padding: 10px;
      }
      .EmptyCategory__buttons {
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
      }
      .EmptyCategory__link {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  `;
