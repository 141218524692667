import Link from "next/link";
import { MouseEventHandler } from "react";
import { styles } from "./SearchHistoryItem.styles";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";

interface Props {
  onRemove(): void;
  onClick(): void;
  text: string;
}

const SearchHistoryItem = (props: Props) => {
  const { onRemove, onClick, text } = props;
  const { fetchConfig: { language } } = useAppConfig();

  const handleRemoveClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove();
  };

  const encodedInputValue = encodeURIComponent(text);
  const href = `/[lang]/search/?q=${encodedInputValue}`;
  const as = `/${language}/search/?q=${encodedInputValue}`;

  return (
    <Link
      href={href}
      as={as}
      onClick={onClick}
    >
      <div className="SearchHistoryItem" data-marker='Search history item'>
        <div className="SearchHistoryItem__content">
          <i className="SearchHistoryItem__icon icon-history-clock" />
          <div className="SearchHistoryItem__text">{text}</div>
        </div>

        <button
          type="button"
          onClick={handleRemoveClick}
          data-marker='Remove item'
          className="SearchHistoryItem__remove"
        >
          <i className="icon-close" />
        </button>
        <style jsx>{styles}</style>
      </div>
    </Link>
  );
};

export default SearchHistoryItem;
