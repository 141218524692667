import { tablet_desktop } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .CategoriesMenuButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    height: 20px;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    ${typography.btn}
    color: var(--colors-white);
    border: none;
    border-radius: var(--border-radius-roundBox);
    background-color: transparent;
  }
  .CategoriesMenuButton__icon {
    font-size: 20px;
  }
  .CategoriesMenuButton__label {
    margin-left: 8px;
  }
  .CategoriesMenuButton__caret {
    font-size: 10px;
    margin-left: 4px;
  }

  @media ${tablet_desktop} {
    .CategoriesMenuButton {
      height: 40px;
      background-color: var(--uiColors-CategoriesMenu-toggleBackground);
      transition: 0.2s background-color ease-in-out;
      padding: 0 15px;
    }
    .CategoriesMenuButton:hover {
      background-color: var(--uiColors-CategoriesMenu-toggleHoverBackground);
    }
  }
  `;
