import { useRouter } from 'next/router';
import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from 'src/redux/apiTypes';
import { placeProductToCart } from 'src/redux/cart/general/operations/placeProductToCart';
import updateAmount from 'src/redux/cart/general/operations/updateAmount';
import selectSelectedDelivery from 'src/redux/cart/general/selectSelectedDelivery';
import { openModal } from 'src/redux/modals';
import openProductModalOrPage from 'src/redux/modals/openProductModalOrPage';
import useFavorites from 'src/services/favorites/useFavorites';
import useNotification from 'src/services/notification/useNotification';
import { getInitialAmount } from 'src/utils/cart/getInitialAmount';
import useAddProductTo from '../useAddProductTo/useAddProductTo';

export default function useProductsCarouselHandlers(place: string) {
  const dispatch = useDispatch();
  const router = useRouter();
  const isUserAddressSet = !!useSelector(selectSelectedDelivery);
  const addProductTo = useAddProductTo();
  const { toggleFavorite } = useFavorites();
  const { openProductNotification, openTextNotification } = useNotification();

  const handleProductTileClick = (
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
    product: Product,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(openProductModalOrPage({
      ean: product.ean,
      slug: product.slug,
      backUrl: router.asPath,
      ctrlKeyIsPressed: event.ctrlKey,
      metaKeyIsPressed: event.metaKey,
    }));
  };

  const handleAmountChange = (amount: number, product: Product) => {
    dispatch(updateAmount(amount, place, product, openTextNotification));
  };

  const handleFavoriteClick = (product: Product) => {
    toggleFavorite({ product });
  };

  const handleAddToCart = (product: Product) => {
    const { unit, quantity, ean } = product;
    const amount = getInitialAmount(unit, quantity, ean);

    const addProductToCart = () => {
      dispatch(placeProductToCart({ ...product, amount }, place, openProductNotification));
    };

    if (isUserAddressSet) {
      addProductTo(addProductToCart, product);
    } else {
      dispatch(openModal('addressModal', null, { onSetNewDelivery: addProductToCart }));
    }
  };

  return {
    handleProductTileClick,
    handleFavoriteClick,
    handleAmountChange,
    handleAddToCart,
  };
}
