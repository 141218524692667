import css from 'styled-jsx/css';

export const styles = css`
    .CategoriesMenuListContainer__firstLevel {
      position: sticky;
      /* top and height values are set dynamically in the component */
      overflow: visible;
      width: 220px;
      margin-bottom: 26px;
      margin-left: 10px;
      margin-top: 16px;
    }
    .CategoriesMenuListContainer__secondLevel {
      position: absolute;
      left: 222px;
      top: 0px;
      height: 100%;
      z-index: 10;
    }
    .CategoriesMenuListContainer__thirdLevel {
      position: absolute;
      left: 444px;
      top: 0px;
      height: 100%;
      z-index: 10;
    }
    .CategoriesMenuListContainer__list {
      position: sticky;
      /* the top position is set dynamically in the component */
      width: 220px;
      background-color: var(--colors-white);
      max-height: 100%;
      padding: 12px 0;
      overflow: auto;
      overflow-x: hidden;
      box-shadow: 0 3px 8px rgba(0,0,0,0.1);
      border-radius: var(--border-radius-block);
      scroll-behavior: smooth;
    }
    .CategoriesMenuListContainer__list_nested {
      /* the max-height is set dynamically in the component */
      padding: 0;
      margin-bottom: 12px;
      margin-left: 10px;
    }
    .CategoriesMenuListContainer__scrollButton {
      z-index: 2;
      height: 25px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: flex;
      background-color: var(--colors-white);
      border-radius: var(--border-radius-block);
      position: sticky;
      bottom: 0;
      left: 0;
      width: 220px;
      height: 0;
      transform: translate(0, 12px);
    }
    .CategoriesMenuListContainer__scrollButton_nested {
      transform: translate(0, 1px);
    }
    .CategoriesMenuListContainer__scrollButton:before {
      content: '';
      position: absolute;
      display: block;
      bottom: 19px;
      height: 30px;
      pointer-events: none;
      width: 220px;
      background: linear-gradient(to top, var(--colors-white), #ffffff00);
    }
    .CategoriesMenuListContainer__scrollButton:after {
      content: "${'\\e901'}";
      font-family: 'icons';
      position: absolute;
      bottom: 0;
      font-size: 8px;
      width: 220px;
      height: 20px;
      text-align: center;
      background: var(--colors-white);
    }
  `;
