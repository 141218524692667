import { CustomRouter } from 'globals';
import { ParsedUrlQuery } from 'querystring';
import { NOT_MULTI_FILTER_PARAMS, SEGMENT_OF_CATEGORIES } from '../../redux/constants';
import { sendFilterChoose } from '../marketing/enhancedEcommerce';
import makeSearchString from '../system/makeSearchString';
import filterSearchParamsArr from './filterSearchParamsArr';
import toggleQueryFilter from './toggleQueryFilter';

export interface Query extends ParsedUrlQuery {
  firstLevelFilter?: string;
  page: string;
}

// The old implementation for other places of using (search page etc)
function toggleInQueryString(
  type: string,
  name: string,
  router: CustomRouter<Query>,
  excludeParams: string[],
) {
  const { pathname, push, query, asPath } = router;
  const newQuery = toggleQueryFilter(type, name, query);
  const searchParams = makeSearchString(newQuery, excludeParams);
  const [path] = asPath.split('?');

  // The old implementation for other places of using
  push({
    pathname,
    query: newQuery,
  }, `${ path }${ searchParams }`);
}

// First filter put to the filter segment(firstLevelFilter) other to query string.
function toggleWithFilterSegmentOfURL(
  type: string,
  name: string,
  router: CustomRouter<Query>,
  excludeParams: string[],
) {
  const {
    query,
    query: {
      firstLevelFilter,
    },
    asPath,
    pathname,
  } = router;

  const [path, params] = asPath.split('?');
  const newFilterParam = `${ encodeURIComponent(type) }=${ encodeURIComponent(name) }`;

  if(!firstLevelFilter) {
    // Filter segment is empty.
    // Add a new filter there.
    const newQuery = {
      ...query,
      firstLevelFilter: newFilterParam,
    };

    // "sort" should leave in query string but "page" should be reset
    delete newQuery.page;

    const newParams = makeSearchString(newQuery, excludeParams);

    router.push({
      pathname: `${ pathname }/[firstLevelFilter]`,
      query: newQuery,
    }, `${ path }${ newFilterParam }/${ newParams ? newParams : '' }`);
  } else {

    const isNewFilterEqualSegmentFilter = firstLevelFilter === `${type}=${name}`;

    if(!isNewFilterEqualSegmentFilter) {
      // Filter segment does not change
      // remove or add selected filter params to query string
      const newQuery = toggleQueryFilter(type, name, query);
      const searchParams = makeSearchString(newQuery, excludeParams);

      router.push({
        pathname,
        query: newQuery,
      }, `${ path }${ searchParams ? searchParams: ''}`);

    } else {
      // The selected filter params match the filter params in the filter segment
      const searchParamsArr = params ? params.split('&') : [];
      const filterParamsArr = filterSearchParamsArr(searchParamsArr, NOT_MULTI_FILTER_PARAMS);

      if (filterParamsArr.length > 0) {
        // There is at least one filter param for replacing it in the filter segment
        // Change the filter parameters in the segment to the first filter from the query string and exclude it from there
        const firstFilterParam = filterParamsArr[0];
        const [newType, newName] = decodeURIComponent(firstFilterParam).split('=');
        const newQuery = toggleQueryFilter(newType, newName, query);
        newQuery.firstLevelFilter = firstFilterParam;
        const newSearchParams = makeSearchString(newQuery, excludeParams);

        // 0 - root; 1 - lang; 2 - root of categories; 3 - category Name; 4 - first filter param
        const segments = path.split('/');
        segments[4] = firstFilterParam;
        const newPath = segments.join('/');

        router.push({
          pathname,
          query: newQuery,
        }, `${ newPath }${ newSearchParams ? newSearchParams : '' }`);
      } else {
        // No filter params to insert into the filter segment from the query string
        // Clean the filter segment
        const newQuery = { ...query };
        delete newQuery.firstLevelFilter;

        // 0 - root; 1 - lang; 2 - root of categories; 3 - category Name; 4 - first filter param
        const segments = path.split('/');
        const newPath = segments.slice(0, 4).join('/');

        // for removing page and leaving sort in query string
        const newSearchParams = makeSearchString(newQuery, excludeParams);

        router.push({
          pathname: pathname.replace('/[firstLevelFilter]', ''),
          query: newQuery,
        }, `${ newPath + '/' }${ newSearchParams ? newSearchParams : '' }`);
      }
    }
  }
}

/**
 *  Toggles filters differently on different pages.
 *  On category pages, the first selected filter option
 *  places into the URL segment named firstLevelFilter in
 *  the request object. The other filter parameters will
 *  be placed in the query string.
 *  On the other pages (search, promotion), all filter
 *  parameters are placed in the query string.
 * @param type {String} - selected filter type
 * @param name {String} - selected filter name
 * @param router {Object} - the router of next.js
 * @param router.asPath {String} - real page URL
 * @param router.pathname {String} - path to the "page" file
 * @param router.query {Object} - contains ids from URL segments
 *  and params of the query string
 * @param excludeParams {Array} - an array of params which
 *  shouldn't  places in the query string
 */
const toggleFilter = (type, name, router, excludeParams) => {
  const {  asPath = '' } = router;

  const filterTypeIsPrice = type === 'price';

  if( filterTypeIsPrice ) {
    sendFilterChoose('price');
  } else if (!asPath.includes(`${type}=${name}`)) {

    sendFilterChoose(type);
  }

  const [path] = asPath.split('?');

  // 0 - root; 1 - lang; 2 - page;
  const segments = path.split('/');

  if(segments[2] === SEGMENT_OF_CATEGORIES && !filterTypeIsPrice) {
    toggleWithFilterSegmentOfURL(type, name, router, excludeParams);
  } else {
    toggleInQueryString(type, name, router, excludeParams);
  }
};

export default toggleFilter;
