import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import styles from './ProductTilePlaceholder.styles';

interface Props {
  repeat: number;
}

const ProductTilePlaceholder = ({ repeat }: Props) => (
  <div className="ProductTilePlaceholder">
    <div className='ProductTilePlaceholder__list'>
      {[...Array(repeat)].map((_, i) => (
        <div key={i} className='ProductTilePlaceholder__item'>
          <div className='ProductTilePlaceholder__itemImage'>
            <RectShape color='#F8F8F8' className='ProductTilePlaceholder__itemShape' />
          </div>
          <div className='ProductTilePlaceholder__itemButton'>
            <RectShape color='#F8F8F8' className='ProductTilePlaceholder__itemShape' />
          </div>
          <div className='ProductTilePlaceholder__details'>
            <TextBlock rows={4} color='#F8F8F8' />
          </div>
        </div>
      ))}
    </div>

    <style jsx>{styles}</style>
  </div>
);

export default ProductTilePlaceholder;
