import { NextRouter } from "next/router";

const getLinkToFilterPage = (
  type: string,
  name: string,
  router: Partial<NextRouter>,
) => {
  const { asPath = ''} = router;
  let uri = asPath.split('?')[0];
  uri = uri[uri.length - 1] === '/' ? uri : uri + '/';

  return `${ uri }${ encodeURIComponent(type) }=${ encodeURIComponent(name) }/`;
};

export default getLinkToFilterPage;
