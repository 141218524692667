import cn from 'classnames';
import Link from 'next/link';
import useLocalization from 'src/services/localization/useLocalization';
import getBreadcrumbsMicroMarking from '../../../utils/marketing/microMarking/getBreadcrumbsMicroMarking';
import ScriptLdJson from '../ScriptLdJson/ScriptLdJson';
import { styles } from './Breadcrumbs.styles';

export interface BreadcrumbsItem {
  title: string;
  type?: string;
  id?: string;
  as?: string;
  to?: string;
}

export interface BreadcrumbsProps {
  items?: BreadcrumbsItem[];
  microMarkingIsShown?: boolean;
  noTopMargin?: boolean;
  language: string;
  baseURL: string;
  onBreadcrumbClick?(categoryId: string | null): void;
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const {
    items = [],
    noTopMargin,
    microMarkingIsShown = true,
    language,
    baseURL,
    onBreadcrumbClick,
  } = props;

  const localize = useLocalization();

  if (items.length === 0) return null;

  const handleBreadcrumbClick = (categoryId: string) => {
    if (onBreadcrumbClick) {
      onBreadcrumbClick(categoryId);
    }
  };

  const homeBreadcrumb = { to: '/', as: '/', title: localize('general.main'), type: 'main', id: 'main'};
  const breadcrumbs = [homeBreadcrumb, ...items];
  const lastIndex = breadcrumbs.length - 1;
  const breadcrumbsMicroMarking = getBreadcrumbsMicroMarking(breadcrumbs, baseURL);

  return (
    <div
      className={cn(
        'Breadcrumbs', {
        'Breadcrumbs_noTopMargin': noTopMargin,
      })}
      data-marker='Breadcrumbs'
    >
      <ol className='Breadcrumbs__list'>
        {breadcrumbs.map((item, index) => {
          const { to, as, title, id } = item;
          const key = `${title}-${index}`;

          return index === lastIndex ? (
            <li
              key={key}
              className='Breadcrumbs__item Breadcrumbs__item_disabled'
              data-marker='Breadcrumbs item'
            >
              <span
                className="Breadcrumbs__link"
                data-marker='Disabled Breadcrumb'
                itemProp='title'>{title}
              </span>
            </li>
          ) : (
            <li
              key={key}
              className='Breadcrumbs__item icon-caret-bottom'
            >
              <Link
                href={`/[lang]${to}`}
                as={`/${language}${as}`}
                data-marker={`Breadcrumb Category ${index}`}
                onClick={() => handleBreadcrumbClick(id)}
                itemProp='url'
              >
                <span className="Breadcrumbs__link">
                  {title}
                </span>
              </Link>
            </li>
          );
        })}
      </ol>
      <style jsx>{styles}</style>
      { microMarkingIsShown && <ScriptLdJson body={breadcrumbsMicroMarking}/> }
    </div>
  );
};

export default Breadcrumbs;
