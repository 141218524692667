import { ParsedUrlQuery } from 'querystring';
import { Filter, Option } from '../../../redux/apiTypes';

export interface Facet {
  type: string;
  option?: Option;
  price?: string;
}

const findFilter = (filterKey: string, query: string, filters: Filter[]): Facet => {
  const filterGroup = filters.find(filter => filter.key === filterKey);

  if (!filterGroup || (filterGroup.type !== 'range' && filterGroup.options.length <= 0)) {
    return null;
  }

  // Transform to string if query is number
  const option = filterGroup.type !== 'range' && filterGroup.options.find(o => `${o.value}` === query);

  if (!option) {
    return null;
  }

  return {
    type: filterKey,
    option,
  };
};

const getActiveFilters = (query: ParsedUrlQuery, filters: Filter[]): Facet[] => {
  const queryKeys = Object.keys(query);

  const activeFilters = queryKeys.flatMap(
    key => {
      const value = query[key];

      if (key === 'price') {
        return {
          type: key,
          price: value,
        } as Facet;
      }

      if (Array.isArray(value)) {
        return value.map(v => findFilter(key, v, filters));
      }

      return findFilter(key, value, filters);
    },
  );

  return activeFilters.filter(filter => !!filter);
};

export default getActiveFilters;
