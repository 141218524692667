import { Fragment, ReactNode } from 'react';
import styles from './PageWrapBody.styles';

export interface Props {
  children: ReactNode;
  phoneTabletModeIsOn?: boolean;
}

export default function PageWrapBody(props: Props) {
  const { children, phoneTabletModeIsOn } = props;

  const id = phoneTabletModeIsOn
    ? 'PageWrapBody_phoneTabletMode'
    : 'PageWrapBody_desktopMode';

  return (
    <Fragment>
      <div id={id} className='PageWrapBody'>
        {children}
      </div>

      <style jsx>{styles}</style>
    </Fragment>
  );
}
