import { MutableRefObject, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import useHeaderVisibilityOnScroll from '../../../hooks/useHeaderVisibilityOnScroll/useHeaderVisibilityOnScroll';
import { HeaderPromotionLine, useHeaderPromotionContext } from '../../../modules/HeaderPromotionLine';
import { useAppSelector } from '../../../redux/hooks';
import setSearchBoxIsActive from '../../../redux/search/setSearchBoxIsActive';
import ScheduleDeliveryAnalyticsWatcher from '../ScheduleDeliveryAnalyticsWatcher/ScheduleDeliveryAnalyticsWatcher';
import { styles } from './Header.styles';
import HeaderBottomLine from './components/HeaderBottomLine/HeaderBottomLine';
import HeaderTopLine from './components/HeaderTopLine/HeaderTopLine';

export interface HeaderProps {
  desktopMenuIsFixed: boolean;
  desktopMenuIsOpen: boolean;
  notificationSlot?: ReactNode;
  filtersSlot?: ReactNode;
  rootElRef: MutableRefObject<HTMLDivElement>;
  headerBottomPosition: number;
  toggleDesktopMenu(isActive: boolean): void;
}

const Header = (props: HeaderProps) => {
  const {
    desktopMenuIsFixed,
    desktopMenuIsOpen,
    toggleDesktopMenu,
    notificationSlot,
    filtersSlot,
    rootElRef,
    headerBottomPosition,
  } = props;

  const dispatch = useDispatch();

  const searchBoxIsActive = useAppSelector(s => s.search.searchBoxIsActive);

  const {
    promotionLineIsVisibleByScroll,
  } = useHeaderVisibilityOnScroll();

  const {
    isClosed: promotionLineIsClosed,
    promotionLineData,
    close: handleCloseHeaderPromotion,
  } = useHeaderPromotionContext();

  const promotionLineIsVisible = promotionLineIsVisibleByScroll &&
    !promotionLineIsClosed && promotionLineData !== null;

  const handleClick = () => {
    if (searchBoxIsActive) {
      dispatch(setSearchBoxIsActive(false));
    }
  };

  return (
    <header
      className='Header'
      data-testid='header'
      id='Header'
      onMouseDown={handleClick}
      ref={rootElRef}
    >
      <ScheduleDeliveryAnalyticsWatcher event={'delivery_contract_set'} />

      {
        promotionLineIsVisible && (
          <HeaderPromotionLine
            content={promotionLineData.content}
            link={promotionLineData.link}
            linkLabel={promotionLineData.linkLabel}
            onClose={handleCloseHeaderPromotion}
          />
        )
      }

      <HeaderTopLine />
      <HeaderBottomLine
        searchBoxIsActive={searchBoxIsActive}
        desktopMenuIsFixed={desktopMenuIsFixed}
        desktopMenuIsOpen={desktopMenuIsOpen}
        toggleDesktopMenu={toggleDesktopMenu}
        headerBottomPosition={headerBottomPosition}
      />

      {filtersSlot}
      {notificationSlot}

      <style jsx>{styles}</style>
    </header>
  );
};

export default Header;
