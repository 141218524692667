import { getCategoryUrl } from "src/modules/Categories";
import { Category } from "src/redux/apiTypes";
import { SliderItem } from "../components/CategoriesMainSlider/CategoriesMainSlider";

const getSliderItemsFromCategories = (categories: Category[], language: string): SliderItem[] => {
  return categories.map((category) => ({
    href: getCategoryUrl({
      language,
      categoryId: category.id,
    }),
    imageSrc: category.image_url?.main_category_preview,
    title: category.title,
    categoryId: category.id,
  }));
};

export default getSliderItemsFromCategories;
