import css from 'styled-jsx/css';

export const styles = css`
    .CategoryProductBox__loadMore {
      text-align: center;
      margin-top: 60px;
      margin-bottom: 40px;
    }

    .CategoryProductBox__paginationWithMargin {
      margin-top: 60px;
    }
  `;
