import { phone } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .SearchHistoryItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${typography.text};
    line-height: 24px;
    padding: 6px 20px;
  }

  .SearchHistoryItem__content {
     display: flex;
  }

  .SearchHistoryItem__icon {
    color: var(--colors-brandLightGrey8);
    font-size: 20px;
    margin-right: 10px;
  }

  .SearchHistoryItem__remove {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: var(--colors-brandLightGrey8);
    width: 16px;
    height: 16px;
    font-size: 13px;
    padding: 0;
  }

  .SearchHistoryItem__remove:hover {
    color: var(--colors-text);
  }

  @media ${phone} {
    .SearchHistoryItem {
      padding: 9px 10px;
    }
  }
`;
