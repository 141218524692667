import Link from "next/link";
import Responsive from "src/components/views/Responsive/Responsive";
import { styles } from "./PageButtons.styles";
import usePageButtons from "./usePageButtons";

const PageButtons = () => {
  const buttons = usePageButtons();
  return (
    <ul className='PageButtons'>
      {
        buttons.map((button) => {
          const innerButton = (
            <span
              className="PageButtons__itemWrapper"
              onClick={button.buttonProps?.onClick}
              role={button.buttonProps ? 'button' : undefined}>
              <span className={`PageButtons__icon icon-${button.iconName}`} />
              <Responsive desktop>
                <span className='PageButtons__label'>{button.label}</span>
              </Responsive>
            </span>
          );

          return (
            <li
              className='PageButtons__item'
              key={button.label}
              data-marker={button.dataMarker}
            >
              {
                button.linkProps
                  ? <Link
                      href={button.linkProps.href}
                      as={button.linkProps.as}
                      data-marker={button.linkProps.dataMarker}
                    >
                      {innerButton}
                    </Link>
                  : innerButton
              }
            </li>
          );
        })
      }
      <style jsx>{styles}</style>
    </ul>
  );
};


export default PageButtons;
