import { Fragment } from "react";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { sendZakazLogoClick } from "src/utils/marketing/enhancedEcommerce";
import ZakazLogoImage from "./ZakazLogoImage";

const ZakazLogo = () => {
  const { country, imgRoot, fetchConfig: { language } } = useAppConfig();
  const domainName = `zakaz.${country}`;

  const img = <ZakazLogoImage country={country} imgRoot={imgRoot} />;

  return (
    <Fragment>
      {country === 'ua'
        ? <a
          href={`https://${domainName}/${language}/`}
          target="_blank"
          rel="noreferrer"
          className="ZakazLogo"
          data-marker="Zakaz logo"
          onClick={sendZakazLogoClick}
        >
          {img}
        </a>
        : <span className="ZakazLogo">
          {img}
        </span>
      }

      <style jsx>{`
        .ZakazLogo {
          height: 28px;
          display: block;
        }
      `}</style>
    </Fragment>
  );
};

export default ZakazLogo;
