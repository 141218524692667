//pages
export { default as CustomCategoryPage } from './components/CustomCategoryPage/CustomCategoryPage';
export { default as B2BPage} from './components/B2BPage/B2BPage';

//components
export { default as CategoryPageContentHeader } from './components/CategoryPageContentHeader/CategoryPageContentHeader';
export { default as CategoryLoader } from './components/CategoryLoader/CategoryLoader';
export { default as CategoriesMainSlider } from './components/CategoriesMainSlider/CategoriesMainSlider';
export { default as B2BProductsSlider } from './components/B2BProductsSlider/B2BProductsSlider';
export { default as PromotionsCarousel } from './components/PromotionsCarousel/PromotionsCarousel';

//hooks
export { default as useFiltersLikeCategoriesMenuItems } from './hooks/useFiltersLikeCategoriesMenuItems';

//helpers
export { sendSubcategoryClick } from './helpers/analytics';
export { getUrlString, excludeParams, getB2BPageUrl, getCustomCategoryUrl } from './helpers/customCategoryPageHelpers';
export { getCategoryUrl } from './helpers/getCategoryUrl';
export { getPageMustBeReset } from './helpers/getPageMustBeReset';

//requests
export { requestGetFiltersLikeCategoriesProducts } from './ajax/requestGetFiltersLikeCategoriesProducts';

//types
export type { CustomCategoryPageProps } from './components/CustomCategoryPage/CustomCategoryPage';

