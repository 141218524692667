import { useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { selectCategories } from "src/redux/categories/categoriesSelectors";
import { useAppSelector } from "src/redux/hooks";
import { tablet } from "src/styles/media";
import { groupArrayElements } from "src/utils/js-helpers/groupArrayElements";
import { getCategoriesInSlideCount } from "../../helpers/getCategoriesInSlideCount";
import getSliderItemsFromCategories from "../../helpers/getSliderItemsFromCategories";
import useFiltersLikeCategoriesMenuItems from "../../hooks/useFiltersLikeCategoriesMenuItems";
import { SliderItem } from "./CategoriesMainSlider";

const useCategoriesMainSliderProps = () => {
  const categories = useAppSelector(selectCategories);
  const language = useAppSelector(s => s.app.language);
  const [placeholderIsVisible, setPlaceholderIsVisible] = useState(true);

  const isTablet = useMediaQuery({ query: tablet });
  const categoriesInSlideCount = getCategoriesInSlideCount(isTablet);

  const filtersLikeCategories = useFiltersLikeCategoriesMenuItems();

  const customCategories: SliderItem[] = filtersLikeCategories.map(i => ({
    categoryId: i.id,
    title: i.title,
    imageSrc: i.preview,
    href: i.href,
  }));

  const totalCategoriesLength = categories.length + customCategories.length;

  const categoriesSlides = useMemo(() => getSliderItemsFromCategories(categories, language), [categories, language]);
  const slidesGroup = groupArrayElements([...customCategories, ...categoriesSlides], categoriesInSlideCount);
  const withNavButtons = totalCategoriesLength > categoriesInSlideCount;

  const handleImpression = () => {
   setPlaceholderIsVisible(false);
  };

  return {
    slidesGroup,
    withNavButtons,
    placeholderIsVisible,
    handleImpression,
  };
};

export default useCategoriesMainSliderProps;
