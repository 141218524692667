import { selectFiltersLikeCategories } from "src/redux/categories/categoriesSelectors";
import { useAppSelector } from "src/redux/hooks";
import { getCategoriesIcon } from "src/utils/categories/getCategoriesIcon";
import { getCustomCategoryUrl } from "../helpers/customCategoryPageHelpers";

const useFiltersLikeCategoriesMenuItems = () => {
  const filtersLikeCategories = useAppSelector(selectFiltersLikeCategories);
  const language = useAppSelector(s => s.app.language);

  const filteredItems = filtersLikeCategories?.filter(category => category.count > 0) || [];

  const menuItems = filteredItems.map(category => {
    return {
      title: category.title,
      icon: getCategoriesIcon(category.image_url),
      preview: category.image_url.main_category_preview,
      id: category.id,
      href: getCustomCategoryUrl({ language, categoryId: category.id }),
    };
  });

  return menuItems;
};

export default useFiltersLikeCategoriesMenuItems;
