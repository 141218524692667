import css from 'styled-jsx/css';
import { desktop, phone } from '../../../../../styles/media';
import { headerZIndex } from '../../headerZIndex';

export const styles = css`
    .MobileMenu__contentBody {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: ${headerZIndex.indexOf('mobileMenu')};

      width: 447px;
      margin: 0;
      border-radius: 0;
      background-color: var(--colors-white);
      overflow: scroll;
    }

    .MobileMenu__overlay {
      background-color: black;
      opacity: 0.3;
      z-index: ${headerZIndex.indexOf('mobileMenuOverlay')};
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .MobileMenu__scrollArrow {
      width: 7px;
      height: 7px;
      transform: rotate(45deg);
      border-bottom: 1px solid var(--uiColors-MobileMenu-arrowDown);
      border-right: 1px solid var(--uiColors-MobileMenu-arrowDown);
    }

    .MobileMenu__scrollArrowBlock {
      position: fixed;
      bottom: 0;
      width: 447px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to top, #ffffff, #ffffff, #ffffff00);
    }

    @media ${phone} {
      .MobileMenu__contentBody {
        width: 280px;
      }

      .MobileMenu__scrollArrow {
        width: 6px;
        height: 6px;
      }

      .MobileMenu__scrollArrowBlock {
        width: 280px;
        height: 30px;
      }
    }

    @media ${desktop} {
      .MobileMenu__overlay,
      .MobileMenu__contentBody {
        display: none;
      }
    }

  `;
