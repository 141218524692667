export const definePromotionsRobotsAndCanonicalMode = (props) => {
  const { urlStr } = props;

  const urlSplitted = urlStr.split('/?');
  const params = urlSplitted.length > 1
    ? urlSplitted[1].split('&').filter(p => typeof p === 'string').map(p => decodeURIComponent(p).split('=')[0])
    : [];

  if (params.length === 1 && params[0].includes('page')) {
    return { withCanonicalTag: true };
  }

  if (params.length) {
    return { noIndexNoFollow: true, withCanonicalTag: true };
  }

  return { indexAndFollow: true, withCanonicalTag: true };
};
