import css from 'styled-jsx/css';
import { desktop, phone_tablet, tablet } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  .CatalogFilters__section {
    margin-bottom: 50px;
  }
  .CatalogFilters__sectionTitle {
    ${typography.h3}
    margin-bottom: 14px;
  }
  @media ${desktop} {
    .CatalogFilters {
      padding: 0 10px 25px 0;
    }
  }
  @media ${phone_tablet} {
    .CatalogFilters {
      margin: 0 -10px 20px;
      background-color: var(--colors-white);
    }
    .CatalogFilters__section:last-child {
      margin-bottom: 0;
    }
  }
  @media ${tablet} {
    .CatalogFilters {
      margin: 0 -20px 20px;
    }
  }
  `;
