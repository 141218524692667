import { useRouter } from "next/router";
import { getUrlString } from "src/modules/Categories";
import getQueryWithMergedFilterParams from "src/utils/filter/getQueryWithMergedFilterParams";

type PaginationCallback = {
  (urlString: string): Promise<void>;
};
interface Params {
  onLoadMore: PaginationCallback;
}

const useCategoryLoadMoreHandler = (params: Params) => {
  const {
    onLoadMore,
  } = params;

  const {
    push,
    pathname,
    asPath,
    query,
  } = useRouter();


  const handleLoadMore = async () => {
    const newQuery = {
      ...query,
      page: query.page ? Number(query.page) + 1 : 2,
    };
    const queryWithMergedFilterParams = getQueryWithMergedFilterParams(newQuery);
    const urlString = getUrlString(queryWithMergedFilterParams);

    await onLoadMore(urlString);
    const path = asPath.split('?')[0];

    push({
      pathname,
      query: newQuery,
    }, `${path}?${urlString}`, { shallow: true });
  };

  return { handleLoadMore };
};

export default useCategoryLoadMoreHandler;

