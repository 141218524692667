import { phone, tablet } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .MobileMenuContent__menuItem {
    ${typography.text}
    padding: 0 15px 0 25px;
    height: 57px;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    border-bottom: 1px solid var(--colors-brandLightGrey1);
  }
  .MobileMenuContent__menuItemIcon {
    color: var(--colors-brandLightGrey2);
    font-size: 16px;
    margin: 0 10px 4px 0;
  }
  .MobileMenuContent__menuItemIcon_dark {
    color: var(--colors-text);
  }
  .MobileMenuContent__menuItemText {
    display: inline-block;
    width: 100%;
    line-height: 16px;
  }
  .MobileMenuContent__menuItemText_bold {
    ${typography.h3}
  }
  .MobileMenuContent__categoryIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .MobileMenuContent__menuItemArrow {
    width: 5px;
    height: 5px;
    transform: rotate(45deg);
    border-top: 1px solid var(--colors-brandLightGrey7);
    border-right: 1px solid var(--colors-brandLightGrey7);
  }
  .MobileMenuContent__menuItemCount {
    ${typography.h9}
    color: var(--colors-brandLightGrey8);
  }
  .MobileMenuContent__header {
    position: sticky;
    top: 0;
    padding: 16px 32px 16px 20px;
    background-color: var(--colors-white);
    z-index: 1;
    border-bottom: 1px solid var(--colors-brandLightGrey1);
  }
  .MobileMenuContent__title {
    ${typography.h3}
  }
  .MobileMenuContent__close {
    display: none;
  }

  @media ${tablet} {
    .MobileMenuContent__menuItemText:not(.MobileMenuContent__menuItemText_bold) {
      font-size: 16px;
    }
  }

  @media ${phone} {
    .MobileMenuContent__menuItem {
      padding: 0 15px;
      height: 49px;
    }
    .MobileMenuContent__menuItemText_bold {
      font-size: 15px;
    }
    .MobileMenuContent__close {
      display: block;
      font-size: 16px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 18px;
      background: transparent;
      border: 0;
      outline: 0;
      padding: 0;
      color: var(--colors-brandLightGrey8);
    }
  }
`;
