import { RectShape } from 'react-placeholder/lib/placeholders';
import { styles } from './CategoriesMainSlider.styles';
import { getCategoriesInSlideCount } from '../../helpers/getCategoriesInSlideCount';
import { useMediaQuery } from 'react-responsive';
import { tablet } from 'src/styles/media';

interface Props {
  title: string;
  dataMarker?: string;
}

export default function CategoriesMainSliderPlaceholder(props: Props) {
  const { title, dataMarker } = props;
  const isTablet = useMediaQuery({ query: tablet });
  const slidesCount = getCategoriesInSlideCount(isTablet);

  return (
    <div className="CategoriesMainSlider" data-marker={dataMarker}>
      <div className="CategoriesMainSlider__placeholderTitle">
        {title}
      </div>
      <div className="CategoriesMainSlider__slide CategoriesMainSlider__slide_placeholder">
        {
          Array(slidesCount).fill(null).map((_, i) => (
            <div key={i} className="CategoriesMainSlider__item CategoriesMainSlider__item_placeholder" >
              <RectShape color="#fff" />
            </div>
          ))
        }

      </div>
      <style jsx>{styles}</style>
    </div>
  );
}
