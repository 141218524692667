import * as React from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import useStoreService from 'src/services/storeManagement/useStoreService';
import FooterContactsItem from '../../views/FooterContactsItem/FooterContactsItem';
import { styles } from './FooterContacts.styles';

interface Props {
  title: string
}

const FooterContacts: React.FC<Props> = (props) => {
  const { title } = props;
  const localize = useLocalization();
  const store = useStoreService();
  const {
    email,
    address,
  } = store;
  const addressText = `${address?.city}, ${address?.street} ${address?.building}`;

  return (
    <ul className='FooterContacts'>
      <li className='FooterContacts__title'>{title}</li>

      {
        email &&
        <FooterContactsItem
          key={email}
          href={`mailto:${email}`}
          text={email}
          testId='email'
        />
      }

      <li className='FooterContacts__title FooterContacts__title_withTopMargin'>{localize('store_address')}</li>

      {
        address &&
        <FooterContactsItem
          key={addressText}
          text={addressText}
          dataMarker='Footer address'
          dataMarkerStoreId={store.id}
          testId='address'
        />
      }

      <style jsx>{styles}</style>
    </ul>
  );
};

export default FooterContacts;
