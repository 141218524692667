import { RangeNumbers } from 'src/components/ui-kit/SliderField/SliderField';
import { FILTER_PRICE_SEPARATOR } from 'src/redux/constants';
import { CustomRouter } from '../../../globals';
import toggleFilter from './toggleFilter';

interface Query {
  'category-id'?: string;
  lang: string;
  price: string;
}

const setFilterPriceValueToUrl = (
  value: RangeNumbers,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: CustomRouter<any>,
  excludeParams: string[],
) => {
  const { query } = router;
  const rangeValue = `${value[0]}${FILTER_PRICE_SEPARATOR}${value[1]}`;

  const queryWithoutFilterPriceValue = removeFilterPriceFromURL(query);

  const newRouter = {
    ...router,
    query: queryWithoutFilterPriceValue,
  };

  toggleFilter('price', rangeValue, newRouter, excludeParams);
};


export function removeFilterPriceFromURL (query: Query) {
  const { price, ...restQuery } = query;

  return restQuery;
}

export default setFilterPriceValueToUrl;
