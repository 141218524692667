import Head from 'next/head';

interface Props {
  description: string;
  title: string;
}

const OpenGraphCategory = ({ description, title }: Props) => {
  return (
    <Head>
      <meta property='og:title' content={title} key='og:title' />
      <meta property='og:description' content={description} key='og:description' />
    </Head>
  );
};

export default OpenGraphCategory;
