import css from 'styled-jsx/css';
import { desktop, phone, phone_tablet, tablet } from '../../../styles/media';

export const styles = css`
    .CheckListItem_link {
      display: block;
      color: inherit;
      text-decoration: none;
    }
    @media ${desktop} {
      .CheckListItem:not(:only-child) {
        padding-bottom: 10px;
      }
      .CheckListItem:last-child {
        padding-bottom: 0;
      }
    }
    @media ${phone_tablet} {
      .CheckListItem {
        display: flex;
        align-items: center;
      }
      .CheckListItem_fullWidth {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }
    @media ${phone} {
      .CheckListItem {
        height: 44px;
      }
    }
    @media ${tablet} {
      .CheckListItem {
        height: 56px;
      }
    }
  `;
