import css from 'styled-jsx/css';
import { desktop, phone_tablet } from '../../../styles/media';

export const styles = css`
  .PageWrap {
    width: 100%;
    padding-bottom: 72px;
    margin: 0 auto;
  }

  .PageWrap_opaqueBackground {
    background-color: var(--colors-white);
  }

  @media ${desktop} {
    .PageWrap {
      min-height: 640px;
      display: flex;
    }
  }
  @media ${phone_tablet} {
    .PageWrap {
      padding-top: 0;
    }
  }
  `;
