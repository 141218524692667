import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import useLocalization from 'src/services/localization/useLocalization';
import { openModal } from '../../../../redux/modals';
import { RootState } from '../../../../redux/reducers';
import { SortOption, getDefaultSort, getSortList } from '../../../../utils/filter/sortContent';
import { styles } from '../CatalogFiltersMobile.styles';

interface Props {
  excludeParams: string[],
}

const ContentSorterMobile = (props: Props) => {
  const localize = useLocalization();
  const dispatch = useDispatch();
  const { pathname, query: { sort: activeSort } } = useRouter();
  const sortOptionList = getSortList(pathname);
  const chosenFilter: SortOption = sortOptionList.find(item => activeSort === item.value);
  const contentSorterModalIsOpen =
    useSelector((state: RootState) => state.modals.contentSorterModal.isOpen);
  const filterToBeDisplayed = chosenFilter
    ? localize(chosenFilter.translation_key)
    : localize(sortOptionList.find(item => item.value === getDefaultSort(sortOptionList)).translation_key);

  return (
    <div className='CatalogFiltersMobile__panelItem' data-marker='Mobile content sorter'>
      <div
        className='CatalogFiltersMobile__toggle CatalogFiltersMobile__toggle_right'
        onClick={() => dispatch(openModal('contentSorterModal', { excludeParams: props.excludeParams }))}
      >
        <span>{filterToBeDisplayed}</span>
        <span className={
          `CatalogFiltersMobile__toggleArrow icon-caret-${contentSorterModalIsOpen ? 'top' : 'bottom'}`
        }/>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ContentSorterMobile;
