import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .FooterMenu__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .FooterMenu__item {
      margin-bottom: 11px;
    }

    .FooterMenu__item:last-child {
      margin-bottom: 0;
    }

    .FooterMenu__title {
      ${typography.h3}
      color: #fff;
      margin-bottom: 11px;
    }
  `;
