import { useMemo } from "react";
import { useAppSelector } from "src/redux/hooks";
import getSlotsErrorsFromServerCart from "../helpers/getSlotsErrorsFromServerCart";

const useScheduleProps = () => {
  const serverCartData = useAppSelector(s => s.serverCart.data);

  const timeWindowErrors = useMemo(() =>
    getSlotsErrorsFromServerCart(serverCartData.warnings),
    [serverCartData.warnings],
  );

  const timeWindow = timeWindowErrors.length > 0 ? null : serverCartData.time_window;

  return {
    timeWindow,
    timeWindowErrors,
  };

};

export default useScheduleProps;
