import css from 'styled-jsx/css';
import { desktop, phone, phone_portrait, tablet_md } from '../../../styles/media';

export default css`
.ProductTilePlaceholder {
  margin-bottom: 60px;
}
.ProductTilePlaceholder__list {
  display: flex;
  flex-wrap: wrap;
}
.ProductTilePlaceholder__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #efefef;
}
.ProductTilePlaceholder__itemShape {
  border-radius: 10px;
}
.ProductTilePlaceholder__itemImage {
  margin-bottom: 10px;
}
.ProductTilePlaceholder__itemButton {
  height: 55px;
  margin-bottom: 8px;
}
@media ${desktop} {
  .ProductTilePlaceholder__item {
    width: 20%;
    height: 364px;
  }
  .ProductTilePlaceholder__itemImage {
    height: 165px;
  }
}
@media ${tablet_md} {
  .ProductTilePlaceholder__item {
    width: 25%;
    height: 353px;
  }
  .ProductTilePlaceholder__item:nth-last-child(1) {
    display: none;
  }
  .ProductTilePlaceholder__itemImage {
    height: 165px;
  }
}
@media ${phone} {
  .ProductTilePlaceholder__item:nth-last-child(-n+2) {
    display: none;
  }
  .ProductTilePlaceholder {
    margin: 0 -10px 50px;
  }
  .ProductTilePlaceholder__item {
    width: 33.33%;
    height: 330px;
  }
  .ProductTilePlaceholder__itemImage {
    height: 140px;
  }
}

@media ${phone_portrait} {
  .ProductTilePlaceholder__item {
    width: 50%;
  }
}
`;
