import { CustomRouter } from 'globals';
import { ReactNode } from 'react';
import getAbsoluteUrl from '../api/getAbsoluteUrl';

export interface Query {
  page?: string;
}

interface Params {
  router: CustomRouter<Query>;
  countItem: number;
  withOffers?: boolean;
  host: string;
}
export interface getSeoPaginationFunc {
  (params: Params): ReactNode[];
}

const getSeoPagination: getSeoPaginationFunc = (params) => {
  const { router, countItem, withOffers, host } = params;

  const seoPagination: ReactNode[] = [];

  const { asPath, query } = router;
  const itemsOnPage = withOffers ? 18 : 30;
  const pageCount = Math.ceil(countItem / itemsOnPage);
  const page = parseInt(query.page);
  const currentPage = page - 1 || 0;
  const withGetPage = router.asPath.includes('/?page=2');
  const path = asPath.split('?')[0];

  const nextPage = {
    ...router,
    asPath: `${asPath.split('=')[0]}=${page + 1}`,
  };

  const prevPage = {
    ...router,
    asPath: `${asPath.split('=')[0]}=${page - 1}`,
  };

  if (currentPage === 0 && pageCount > 1) {
    seoPagination.push(
      <link rel="next" key="next" href={getAbsoluteUrl(`${asPath}?page=2`, host).fullUrl} />,
    );
  }

  if (page === pageCount) {
    seoPagination.push(
      <link rel="prev" key="prev" href={getAbsoluteUrl(prevPage.asPath, host).fullUrl} />,
    );
  }

  if (currentPage !== 0 && page !== pageCount && !withGetPage && pageCount > page) {
    seoPagination.push(
      <link rel="next" key="next" href={getAbsoluteUrl(nextPage.asPath, host).fullUrl} />,
      <link rel="prev" key="prev" href={getAbsoluteUrl(prevPage.asPath, host).fullUrl} />,
    );
  }

  if (withGetPage && pageCount > page) {
    seoPagination.push(
      <link rel="next" key="next" href={getAbsoluteUrl(nextPage.asPath, host).fullUrl} />,
      <link rel="prev" key="prev" href={getAbsoluteUrl(path, host).fullUrl} />,
    );
  }

  return seoPagination;
};

export default getSeoPagination;
