import css from 'styled-jsx/css';

export const styles = css`
    .CategoriesMenu {
      height: 100%;
      width: 0;
      overflow: visible;
      z-index: 10;
      position: relative;
    }
  `;
