import CheckboxWithLabel, { CheckboxWithLabelProps } from 'src/components/ui-kit/CheckboxWithLabel/CheckboxWithLabel';
import { styles } from './CheckboxOptionAmounted.styles';

interface Props extends Omit<CheckboxWithLabelProps, 'children'> {
  text: string;
  amount: number | undefined;
}

function CheckboxOptionAmounted(props: Props) {
  const { text, amount, ...rest } = props;

  return (
    <CheckboxWithLabel
      {...rest}
      fullWidth
    >
      <div className="CheckboxOptionAmounted">
        <span className="CheckboxOptionAmounted__text">{text}</span>
        {
          amount !== undefined && (
            <span
              className="CheckboxOptionAmounted__amount"
              data-marker='Checkbox amount'
            >
              {amount}
            </span>
          )
        }
        <style jsx>{styles}</style>
      </div>
    </CheckboxWithLabel>
  );
}

export default CheckboxOptionAmounted;
