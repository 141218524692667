import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .ContentSorterDropdown {
      position: relative;
      z-index: 3;
    }

    .ContentSorterDropdown__list {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: 4px;
      right: 0;
      width: 277px;
      padding: 10px 0;
      background: var(--colors-white);
      border: 1px solid var(--colors-brandLightGrey1);
      z-index: 2;
      box-shadow: 0px 3px 4px rgba(48, 48, 48, 0.12);
    }

    .ContentSorterDropdown__list_withLeftAlign {
      left: 0;
      right: auto;
    }

    .ContentSorterDropdown__listExpanded {
      display: block;
    }

    .ContentSorterDropdown__item {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 40px;
      border: 0;
      background: 0;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${typography.text}
    }

    .ContentSorterDropdown__item_active {
      ${typography.h6};
      padding-right: 44px;
    }

    .ContentSorterDropdown__item:hover,
    .ContentSorterDropdown__item_active {
      color: var(--uiColors-ContentSorter-controlActiveHoverText);
    }

    .ContentSorterDropdown__itemIcon {
      position: absolute;
      top: 50%;
      right: 22px;
      margin-top: -7px;
      z-index: 1;
    }

    .ContentSorterDropdown__line {
      display: flex;
      ${typography.text}
      font-size: 13px;
      line-height: 15px;
    }

    .ContentSorterDropdown__label {
      margin-right: 2px;
      color: var(--colors-doveGray);
    }

    .ContentSorterDropdown__selected {
      border: none;
      outline: none;
      padding: 0;
      background: none;
      cursor: pointer;
    }

    .ContentSorterDropdown__selected:hover {
      color: var(--colors-mainBrand);
    }

    .ContentSorterDropdown__caret {
      font-size: 10px;
      margin-left: 7px;
    }
  `;
