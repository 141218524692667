import classNames from 'classnames';
import { Banner } from 'src/redux/apiTypes';
import LoadingBarIndicator from '../LoadingBarIndicator/LoadingBarIndicator';
import { styles } from './MainSlider.styles';
import MainSliderCarousel from './MainSliderCarousel';

export type SliderPlace = 'home' | 'category'

interface Props {
  banners: Banner[];
  isFetching: boolean;
  hasError: boolean;
  place: SliderPlace;
  language: string;
}

const MainSlider = (props: Props) => {
  const {
    banners,
    isFetching,
    hasError,
    place,
    language,
  } = props;

  if (place === 'category' && banners.length === 0) {
    return null;
  }

  if ((hasError && !isFetching) || (!isFetching && !banners.length)) {
    return (
      <div className='MainSlider_emptyPlaceHolder'>
        <style jsx>{styles}</style>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div
        className={classNames(
          'MainSlider', {
          'MainSlider__categories': place === 'category',
        })}
      >
        <div className='MainSlider__placeholder' data-testid='main_slider_placeholder'>
          <LoadingBarIndicator
            isUnderContainer
          />
        </div>
        <style jsx>{styles}</style>
      </div>
    );
  }

  return <MainSliderCarousel
    banners={banners}
    place={place}
    language={language}
  />;
};

export default MainSlider;
