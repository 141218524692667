import { searchResultsSortOrderList, sortOrderList } from '../../fixtures';
import makeSearchString from '../system/makeSearchString';

export type SortOption = {
  id: string
  translation_key: string
  value: string
  default?: boolean
}

export function getDefaultSort(sortList: SortOption[]) {
  const defaultArray = sortList.find(item => item.default);
  return defaultArray ? defaultArray.value : sortList[0].value;
}

export function getSortList(pathname: string) {
  return /\/search/.test(pathname)
    ? searchResultsSortOrderList
    : sortOrderList;
}

export function navigateToSort(router, value, excludeParams) {
  const { pathname, asPath, push, query } = router;

  const path = asPath.split('?')[0];
  const newQuery = {...query};

  // clear pagination if we change sorting
  if (query.sort !== value && query.page) {
    newQuery.page = '1';
  }

  newQuery.sort = value;
  const searchParams = makeSearchString(newQuery, excludeParams);

  push({
    pathname,
    query: newQuery,
  }, `${path}${searchParams}`);
}
