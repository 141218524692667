import Impressionable from '../Impressionable/Impressionable';
import ProductsCarousel, { ProductsCarouselProps } from '../ProductsCarousel/ProductsCarousel';
import ProductsSliderPlaceholder from '../ProductsSliderPlaceholder/ProductsSliderPlaceholder';

export interface Props {
  onImpressed?(): void;
  dataMarker?: string;
  placeholderTitle: string;
  sliderShouldNotDisplayed: boolean;
  placeholderShouldBeDisplayed: boolean;
  carouselProps: ProductsCarouselProps;
}

export default function ProductsSliderWithPlaceholder(props: Props) {
  const {
    placeholderShouldBeDisplayed,
    sliderShouldNotDisplayed,
    placeholderTitle,
    carouselProps,
    onImpressed,
    dataMarker,
  } = props;

  if (sliderShouldNotDisplayed) {
    return null;
  }

  if (placeholderShouldBeDisplayed) {
    return (
      <Impressionable
        onImpressed={onImpressed}
        partialVisibility
      >
        <ProductsSliderPlaceholder
          title={placeholderTitle}
          dataMarker={dataMarker}
        />
      </Impressionable>
    );
  }

  return <ProductsCarousel {...carouselProps} />;
}
