import Image from 'next/image';

interface Props {
  country: string;
  imgRoot: string;
}

const ZakazLogo = (props: Props) => {
  const { country, imgRoot } = props;

  return (
    <Image
      src={`${imgRoot}/store_logos/logo_zakaz_${country}.svg`}
      alt='Zakaz banner'
      width={143}
      height={39}
    />
  );
};

export default ZakazLogo;
