import { DeliveryType, FetchConfig, QueryString } from "src/redux/apiTypes";
import { UserRetail } from "../types";

export const requestGetUserRetails = async (
  deliveryType: DeliveryType | null,
  queryString: QueryString,
  fetchConfig: FetchConfig,
): Promise<UserRetail[]> => {
  const { language, apiRoot, xVersion } = fetchConfig;
  const headers = {
    'Accept-Language': language,
    'x-version': xVersion,
  };

  if (deliveryType) {
    headers['x-delivery-type'] = deliveryType;
  }

  const url = `${apiRoot}/user/retail_chains/${queryString}`;

  try {
    const resp = await fetch(url, {
      credentials: 'include',
      headers,
    });

    if (!resp.ok) {
      return [];
    }

    return resp.json();

  } catch {
    return [];
  }
};
