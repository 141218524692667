import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { useUserAgent } from 'src/modules/user-agent';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { Banner } from '../../../redux/apiTypes';
import { smWidth } from '../../../styles/media';
import { sendBannerClick, sendBannerImpression } from '../../../utils/marketing/enhancedEcommerce';
import Impressionable from '../Impressionable/Impressionable';
import Slider from '../SliderV2/Slider';
import { useSlider } from '../SliderV2/helpers/useSlider';
import DefaultSlide from './DefaultSlide';
import { SliderPlace } from './MainSlider';
import { styles } from './MainSlider.styles';
import MainSliderControls from './MainSliderControls';

interface Props {
  banners: Banner[];
  place: SliderPlace;
  language: string;
}

const MainSliderCarousel = (props: Props) => {
  const {
    banners,
    place,
    language,
  } = props;

  const { marketing: { CHAIN_GTM_ID } } = useAppConfig();
  const { platform } = useUserAgent();

  const [isNavHidden, setIsNavHidden] = useState(true);
  const [isInteracted, setIsInteracted] = useState(false);

  const isAutoScrollEnabled =
    typeof window !== 'undefined' &&
    window.innerWidth > smWidth &&
    platform !== 'mobile' && !isInteracted;

  const {sliderRef, controls, sliderControls } = useSlider({
    withAutoScroll: isAutoScrollEnabled,
    autoScrollTimer: 6000,
    duration: 1000,
    withSwiperMode: true,
    endlessLeft: true,
    endlessRight: true,
  });

  const onMouseOver = () => {
    setIsNavHidden(false);
  };

  const onMouseLeave = () => {
    setIsNavHidden(true);
  };

  const handleBannerClick = (banner: Banner, slotNumber: number) => (event: React.MouseEvent) => {
    const destinationUrl = banner.image[language].link;
    event.preventDefault();
    const target = banner.target_blank ? '_blank' : '_self';

    if (typeof window.ga === 'function') {
      /**
       * Google analytics docs recommends an alternative way to execute main functionality,
       * in case of gtag.js library fails to run
       */
      setTimeout(() => {
        window.open( destinationUrl, target );
      }, 1000);
      sendBannerClick({
        id: banner.id,
        name: banner.name,
        destinationUrl,
        slotNumber,
        target,
        CHAIN_GTM_ID,
      });
    } else {
      window.open( destinationUrl, target );
    }
  };

  const handleBannerImpression = (banner: Banner, slotNumber: number) => () => {
    sendBannerImpression({
      id: banner.id,
      name: banner.name,
      slotNumber,
    });
  };

  return (
    <div
      className={classNames(
        'MainSlider', {
        'MainSlider__categories': place === 'category',
      })}
      data-marker='Banner'
      data-testid='main_slider'
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
        <Slider
          sliderRef={sliderRef}
          controls={sliderControls}
          customNavigation={
            <MainSliderControls onInteract={setIsInteracted} controls={controls} isNavHidden={isNavHidden} banners={banners} />
          }
        >
          {
            banners.map((banner, index) => {
              return(
                <a
                  key={banner.id}
                  className='MainSlider__slide'
                  rel="noopener noreferrer"
                  onClick={handleBannerClick(banner, index)}
                  data-testid='home_slider_banner_link'
                >
                  <Impressionable onImpressed={handleBannerImpression(banner, index)}>
                    <DefaultSlide
                      isFirst={index === 0}
                      image={banner.image[language]}
                    />
                  </Impressionable>
                </a>
              );
            })
          }
        </Slider>
      <style jsx>{styles}</style>
    </div>
  );
};

export default MainSliderCarousel;
