import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .DropdownChooseLanguage {
    position: relative;
  }

  .DropdownChooseLanguage__button {
    display: block;
    padding: 0;
    outline: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    ${typography.h9}
  }

  .DropdownChooseLanguage__icon {
    color: var(--colors-brandLightGrey8);
    font-size: 8px;
    margin-left: 5px;
  }

  .DropdownChooseLanguage__icon_open {
    color: var(--colors-linkAccented);
  }

  .DropdownChooseLanguage__dropdown {
    position: absolute;
    width: 56px;
    top: calc(100% + 9px);
    left: 0;
    z-index: 102;
    border: 1px solid var(--colors-brandLightGrey1);
    box-shadow: 0 3px 8px rgba(0,0,0,.1);
  }

  .DropdownChooseLanguage__dropdown:after{
    content:'';
    position: absolute;
    top: -8px;
    left: 20px;
    width: 0;
    z-index: 2;
    height: 0;
    border-bottom: solid 8px var(--colors-white);
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }

  .DropdownChooseLanguage__dropdown:before{
    content:'';
    position: absolute;
    top: -9px;
    left: 19px;
    width: 0;
    z-index: 1;
    height: 0;
    border-bottom: solid 9px var(--colors-brandLightGrey1);
    border-left: solid 9px transparent;
    border-right: solid 9px transparent;
  }

  .DropdownChooseLanguage__dropdownItem {
    display: block;
    padding: 8px 16px;
    cursor: pointer;
    background: var(--colors-white);
    text-decoration: none;
    ${typography.text}
    line-height: 24px;
  }

  .DropdownChooseLanguage__dropdownItem:hover{
    color: var(--colors-zakazGreen);
    background: var(--colors-brandLightGrey5);
  }
`;
