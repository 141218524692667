import css from 'styled-jsx/css';
import { desktop, tablet } from '../../../../../styles/media';
import typography from '../../../../../styles/typography';

export const styles = css`
  .AddressButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
  }
  .AddressButton:focus {
    outline: none;
  }
  .AddressButton__icon {
    font-size: 17px;
    color: var(--colors-brandLightGrey8);
  }
  .AddressButton__label {
    ${typography.h9}
    line-height: 1;
    margin-left: 4px;
  }
  .AddressButton__label::first-letter {
    text-transform: uppercase;
  }
  @media ${desktop} {
    .AddressButton {
      margin-right: 16px;
    }
    .AddressButton__label:hover {
      color: var(--colors-linkAccented);
    }
  }

  @media ${tablet} {
    .AddressButton__label {
      display: block;
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  `;
