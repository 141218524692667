import css from 'styled-jsx/css';
import { lg, phone, tablet } from '../../../../../styles/media';

export const styles = css`
  .HeaderBottomLine {
    background-color: var(--uiColors-HeaderBottomLine-background);
  }
  .HeaderBottomLine__menu {
    width: 220px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .HeaderBottomLine__bar {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 12px;
    margin-left: 15px;
  }
  .HeaderBottomLine__search {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 24px;
    flex-shrink: 20;
  }
  .HeaderBottomLine__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 10px;
    margin: 0 auto;
  }

  @media ${lg} {
    .HeaderBottomLine__container {
      width: 1366px;
    }
  }

  @media ${tablet} {
    .HeaderBottomLine__menu {
      width: 56px;
    }
  }

  @media ${phone} {
    .HeaderBottomLine__container {
      height: 40px;
      padding: 0 12px;
    }
    .HeaderBottomLine__menu {
      width: 20px;
    }
  }
  `;
