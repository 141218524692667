import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCategoryUrl, sendSubcategoryClick, useFiltersLikeCategoriesMenuItems } from 'src/modules/Categories';
import { selectCategories } from 'src/redux/categories/categoriesSelectors';
import { useAppSelector } from 'src/redux/hooks';
import { getCategoriesIcon } from 'src/utils/categories/getCategoriesIcon';
import { Category } from '../../../redux/apiTypes';
import { buttonElementId } from '../Header/components/CategoriesMenuButton/CategoriesMenuButton';
import { styles } from './CategoriesMenu.styles';
import CategoriesMenuHeader from './CategoriesMenuHeader';
import CategoriesMenuListContainer from './CategoriesMenuListContainer';
import CategoriesMenuListItem from './CategoriesMenuListItem';

interface CategoriesMenuProps {
  topPosition: number;
  onCloseMenu?(): void;
}

const CategoriesMenu = (props: CategoriesMenuProps) => {
  const { topPosition, onCloseMenu = () => { } } = props;
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<Category | null>(null);
  const selectCategory = useMemo(() => debounce<Dispatch<SetStateAction<Category | null>>>((c) => setSelectedCategory(c), 500), []);
  const selectSubCategory = useMemo(() => debounce<Dispatch<SetStateAction<Category | null>>>((c) => setSelectedSubCategory(c), 500), []);
  const categories = useAppSelector(selectCategories);
  const language = useAppSelector(s => s.app.language);
  const router = useRouter();
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event) => {
    if (menuRef.current &&
      !menuRef.current.contains(event.target) &&
      event.target.id !== buttonElementId &&
      !event.target.closest(`#${buttonElementId}`)
    ) {
      onCloseMenu();
    }
  }, [onCloseMenu]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const customMenuItems = useFiltersLikeCategoriesMenuItems();

  const clearSelected = () => {
    selectCategory(null);
    selectSubCategory(null);
  };

  const handleCategoryClick = (categoryId: string) => {
    const page = router.pathname.split('/')[2];

    sendSubcategoryClick({
      page: !page ? 'main' : page,
      place: 'category_list',
      sliderIndex: null,
      categoryId,
    });

    onCloseMenu();
  };


  return (
    <nav className='CategoriesMenu' ref={menuRef}>
      <CategoriesMenuListContainer
        topPosition={topPosition}
        dataMarker="First Level Categories Menu"
        onMouseLeave={clearSelected}
      >

        {
          customMenuItems.map(i => (
            <CategoriesMenuListItem
              key={`${i.id}-menu-item`}
              linkHref={i.href}
              imageUrl={i.icon}
              title={i.title}
              onMouseEnter={clearSelected}
              onClick={() => handleCategoryClick(i.id)}
            />
          ))
        }

        {categories?.map(category => (
          <CategoriesMenuListItem
            key={category.id}
            hasChild={category.children.length > 0}
            isActive={category.children.length > 0 && selectedCategory?.id === category.id}
            linkHref={getCategoryUrl({
              language,
              categoryId: category.id,
            })}
            imageUrl={getCategoriesIcon(category.image_url)}
            title={category.title}
            onMouseEnter={() => selectCategory(category)}
            onClick={() => handleCategoryClick(category.id)}
          />
        ))}
      </CategoriesMenuListContainer>

      {selectedCategory && !!selectedCategory.children.length &&
        <CategoriesMenuListContainer
          topPosition={topPosition}
          dataMarker="Second Level Categories Menu"
          categoryId={selectedCategory.id}
          onMouseLeave={clearSelected}
          level={2}
        >
          <CategoriesMenuHeader
            title={selectedCategory.title}
            imageSrc={getCategoriesIcon(selectedCategory.image_url)}
          />

          {selectedCategory.children.map(category => (
            <CategoriesMenuListItem
              key={category.id}
              hasChild={category.children.length > 0}
              isActive={category.children.length > 0 && selectedSubCategory?.id === category.id}
              linkHref={getCategoryUrl({
                language,
                categoryId: category.id,
              })}
              title={category.title}
              onMouseEnter={() => {
                selectCategory(p => p);
                selectSubCategory(category);
              }}
              onClick={() => handleCategoryClick(category.id)}
            />
          ))}
        </CategoriesMenuListContainer>
      }

      {selectedSubCategory && !!selectedSubCategory.children.length &&
        <CategoriesMenuListContainer
          topPosition={topPosition}
          dataMarker="Third Level Categories Menu"
          categoryId={selectedSubCategory.id}
          onMouseLeave={clearSelected}
          level={3}
        >
          <CategoriesMenuHeader
            title={selectedSubCategory.title}
            imageSrc={getCategoriesIcon(selectedSubCategory.image_url)}
          />

          {selectedSubCategory.children.map(category => (
            <CategoriesMenuListItem
              key={category.id}
              linkHref={getCategoryUrl({
                language,
                categoryId: category.id,
              })}
              title={category.title}
              onMouseEnter={() => {
                selectCategory(p => p);
                selectSubCategory(p => p);
              }}
              onClick={() => handleCategoryClick(category.id)}
            />
          ))}
        </CategoriesMenuListContainer>
      }

      <style jsx>{styles}</style>
    </nav>
  );
};

export default CategoriesMenu;
