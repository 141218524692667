import css from 'styled-jsx/css';
import { tablet, tablet_desktop } from '../../../styles/media';

export default css`
  @media ${tablet_desktop} {
    .PageWrapBody {
      flex-shrink: 0;
      width: calc(100% - 220px);
      padding-left: 30px;
      position: relative;
    }
  }

  @media ${tablet} {
    .PageWrapBody {
      width: 100%;
      padding-left: 0;
    }
}
`;