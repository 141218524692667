import { phone } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .SearchPopularProducts {
    padding: 24px 20px 18px;
  }

  .SearchPopularProducts__title {
    margin-bottom: 6px;
    ${typography.h6};
  }

  .SearchPopularProducts__items {
    margin-left: -6px;
    margin-right: -6px;
  }

  .SearchPopularProducts__item {
    display: inline-block;
    margin: 6px;
  }

  @media ${phone} {
    .SearchPopularProducts {
      padding: 16px 10px 10px;
    }
  }
`;
