import { LocalizeFunc } from "src/services/localization/types";
import { TimeWindow } from "src/swr/useScheduleDelivery/scheduleDeliveryTypes";
import convertTimeWindowToString from "src/utils/delivery/convertTimeWindowToString";
import upperCaseFirstChar from "src/utils/js-helpers/upperCaseFirstChar";

export interface Params {
  withDeliveryPrefix: boolean;
  isPickup: boolean;
  timeWindow: TimeWindow;
  localize: LocalizeFunc;
}

export const getScheduleInfoText = ({ withDeliveryPrefix, isPickup, localize, timeWindow }: Params) => {
  const deliveryPrefix = localize(isPickup ? 'slot_text_first_part_pickup' : 'slot_text_first_part_deliver');
  const startText = withDeliveryPrefix ? `${deliveryPrefix} ` : '';
  const timeWindowText = convertTimeWindowToString(timeWindow, localize);
  const endText = withDeliveryPrefix ? timeWindowText : upperCaseFirstChar(timeWindowText);
  return startText + endText;
};
