import Responsive from "src/components/views/Responsive/Responsive";
import ScheduleSelectInfoText from "src/components/views/ScheduleSelectInfoText/ScheduleSelectInfoText";
import { styles } from "./HeaderScheduleButton.styles";
import useHeaderScheduleButtonProps from "./useHeaderScheduleButtonProps";

const HeaderScheduleButton = () => {
  const {
    visibleSlot,
    deliveryType,
    storeIsChosen,
    language,
    withDeliveryPrefix,
    handleSlotsClick,
  } = useHeaderScheduleButtonProps();

  if (!storeIsChosen) {
    return null;
  }

  return (
    <div
      className='HeaderScheduleButton'
      onClick={handleSlotsClick}
      data-marker="Delivery schedule header"
      role="button"
    >
      <span className='HeaderScheduleButton__icon icon-clock' />
      <Responsive tabletDesktop>
        <span className='HeaderScheduleButton__label'>
          <ScheduleSelectInfoText
            timeWindow={visibleSlot}
            deliveryType={deliveryType}
            language={language}
            withDeliveryPrefix={withDeliveryPrefix}
          />
        </span>
      </Responsive>
      <style jsx>{styles}</style>
    </div>
  );
};

export default HeaderScheduleButton;
