import cn from 'classnames';
import { Fragment } from 'react';
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import styles from './ProductPlaceholder.styles';

interface Props {
  withHalfHeight?: boolean;
}

const ProductPlaceholder = (props: Props) => {
  const {withHalfHeight} = props;

  return (
    <Fragment>
      <div className={cn('ProductPlaceholder', { 'ProductPlaceholder_withHalfHeight': withHalfHeight })}>
        <div className='ProductPlaceholder__image'>
          <RectShape color={'var(--colors-brandLightGrey5)'} className='ProductPlaceholder__shape' />
        </div>
        <div className='ProductPlaceholder__price'>
          <TextBlock rows={2} color={'var(--colors-brandLightGrey0)'} />
        </div>
        <div className='ProductPlaceholder__details'>
          <TextBlock rows={3} color={'var(--colors-brandLightGrey5)'} />
        </div>
      </div>
      <style jsx>{styles}</style>
    </Fragment>
  );
};


export default ProductPlaceholder;
