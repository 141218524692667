import { useEffect, useState } from 'react';

const SCROLL_LIMIT = 50;

const useHeaderVisibilityOnScroll = () => {
  const [promotionLineIsVisibleByScroll, setPromotionLineIsVisibleByScroll] = useState(true);

  useEffect(() => {
    let prevScrollIsBlocked = document.body.style.overflow === 'hidden';
    let prevScrollTop = window.pageYOffset;
    let prevScrollHeight = getScrollHeight();

    /* hide promotion line when scrollTop is not zero after refreshing page */
    if (prevScrollTop !== 0 ) {
      setPromotionLineIsVisibleByScroll(false);
    }

    const handleScroll = () => {
      const curScrollIsBlocked = document.body.style.overflow === 'hidden';
      const curScrollHeight = getScrollHeight();
      const curScrollTop = window.pageYOffset;

      const scrollBlockingWasNotChanged = curScrollIsBlocked === prevScrollIsBlocked;
      const scrollHeightWasNotChanged = curScrollHeight === prevScrollHeight;

      if (scrollBlockingWasNotChanged && scrollHeightWasNotChanged && !curScrollIsBlocked) {
        /* The hack with scroll limits is needed because
          in safari the scroll jumps in the top and bottom positions */
        if (curScrollTop <= SCROLL_LIMIT) {
          setPromotionLineIsVisibleByScroll(true);
        } else {
          setPromotionLineIsVisibleByScroll(false);
        }
      } else if (curScrollTop === 0 && !curScrollIsBlocked) {
        /*
          For fix bugs on the category page.
          When you select a filter, sort or page, the page scrolls up,
          but since this changes the height of the content, nothing happens.
        */
        setPromotionLineIsVisibleByScroll(true);
      }

      prevScrollTop = curScrollTop;
      prevScrollIsBlocked = curScrollIsBlocked;
      prevScrollHeight = curScrollHeight;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    promotionLineIsVisibleByScroll,
  };
};

const getScrollHeight = () => Math.max(
  document.body.scrollHeight, document.documentElement.scrollHeight,
  document.body.offsetHeight, document.documentElement.offsetHeight,
  document.body.clientHeight, document.documentElement.clientHeight,
);

export default useHeaderVisibilityOnScroll;
