import { Fragment } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import getFilterNamesWithCurrency from 'src/utils/filter/getFilterNamesWithCurrency';
import getParsedFilterPriceValue from 'src/utils/filter/getParsedFilterPriceValue';
import getPriceAndCurrencyText from 'src/utils/filter/getPriceAndCurrencyText';
import { Filter } from '../../../redux/apiTypes';
import { Facet } from '../../containers/CatalogActiveFiltersList/getActiveFilters';
import { HandleFilterFunc } from '../../containers/CatalogFilters/CatalogFilters';
import CheckListItem from '../../containers/CheckListItem/CheckListItem';
import { DeleteFiltersFunc } from '../CatalogFiltersMobile/CatalogFiltersMobile';
import { styles } from './CatalogFiltersMobileCategories.styles';

interface Props {
  filters: Filter[]
  activeFilters: Facet[]
  openCategory(filter: Filter): void
  handleFilter: HandleFilterFunc
  deleteFilters: DeleteFiltersFunc
}

const CatalogFiltersMobileCategories = (props: Props) => {
  const {
    filters,
    activeFilters,
    openCategory,
    handleFilter,
    deleteFilters,
  } = props;

  const { currency } = useAppConfig();

  const localize = useLocalization();

  return (
    <Fragment>
      <div className='CatalogFiltersMobileCategories__header'>
        <span className='CatalogFiltersMobileCategories__headerTitle'>
          <span>{localize('general.filters')}</span>
        </span>
        {activeFilters.length > 0 && (
          <button
            type='button'
            onClick={deleteFilters()}
            className='CatalogFiltersMobileCategories__headerButton'
          >
            <span>{localize('general.clear-all')}</span>
          </button>
        )}
      </div>
      <div className='CatalogFiltersMobileCategories__content'>
        { filters.map(filter => {
            if (filter.type === 'single' && filter.options.length > 0) {
              return (
                <div
                  className='CatalogFiltersMobileCategories__categoryItem'
                  key={filter.options[0].value}
                >
                  <CheckListItem
                    filter={filter}
                    option={filter.options[0]}
                    handleFilter={handleFilter}
                    fullWidth
                  />
                </div>
              );
            }

            const activeTypeFilters = activeFilters.filter(item => item.type === filter.key);
            const activeFiltersText = activeTypeFilters.map((f) => f.option && f.option.name).join(', ');
            const filterPriceValue = activeTypeFilters.map((f) => f.price).join('');
            const filterPriceName = getFilterNamesWithCurrency(filter.name, currency, localize);
            const filterIsPrice = filter.key === 'price';

            return (
              <button
                key={filter.key}
                type='button'
                className='CatalogFiltersMobileCategories__categoryButton'
                onClick={() => openCategory(filter)}
                data-marker={filter.key}
              >
                <span className='CatalogFiltersMobileCategories__title'>
                  {filterIsPrice ? filterPriceName : filter.name}
                </span>

                {!!activeFiltersText.length && (
                  <span className='CatalogFiltersMobileCategories__activeName'>
                    {activeFiltersText}
                  </span>
                )}

                {filterIsPrice && filterPriceValue && (
                  <span className='CatalogFiltersMobileCategories__activeName'>
                    {getPriceAndCurrencyText({
                      price: getParsedFilterPriceValue(filterPriceValue),
                      localize,
                      isDesktop: false,
                      currency,
                    })}
                  </span>
                )}

                <span className='CatalogFiltersMobileCategories__categoryButtonIcon icon-caret-bottom' />
              </button>
            );
          })
        }
      </div>

      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default CatalogFiltersMobileCategories;
