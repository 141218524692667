import getUserAgent from 'src/utils/api/getUserAgent';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from "swr";

type PopularTagsRequestResult = {
  products: string[];
}

const fetcher = (url: Request | string, options?: RequestInit) => fetch(url, {
  ...options,
}).then(res => res.json());

const usePopularTags = (shouldFetch: boolean) => {
  const { fetchConfig } = useAppConfig();
  const { xChain, apiRoot, language, xVersion } = fetchConfig;

  const url = `${apiRoot}/retail_chains/${xChain}/popular_requests/`;

  const headers = {
    'Accept-Language': language,
    'x-chain': xChain,
    'User-Agent': getUserAgent(),
    'x-version': xVersion,
  };

  const { data, error } = useSWR<PopularTagsRequestResult>(
    () => shouldFetch ? url : null,
    () => fetcher(url, { headers }),
  );

  const isLoadingTags = shouldFetch ? data === undefined && error === undefined : false;
  const tags = data ? data.products : [];

  return {
    isLoadingTags,
    tags,
  };
};

export default usePopularTags;
