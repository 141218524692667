import SlotTooltip from 'src/modules/ScheduleDelivery/components/SlotTooltip/SlotTooltip';
import { DeliveryType } from 'src/redux/apiTypes';
import useLocalization from 'src/services/localization/useLocalization';
import { TimeWindow } from 'src/swr/useScheduleDelivery/scheduleDeliveryTypes';
import { getScheduleInfoText } from './getScheduleInfoText';

export interface ScheduleSelectInfoTextProps {
  timeWindow: TimeWindow | null;
  deliveryType: DeliveryType;
  language: string;
  withTooltip?: boolean;
  withDeliveryPrefix?: boolean;
}

const ScheduleSelectInfoText = (props: ScheduleSelectInfoTextProps) => {
  const localize = useLocalization();
  const { timeWindow, language, deliveryType, withTooltip, withDeliveryPrefix } = props;

  const deliveryIsPickup = deliveryType === 'pickup';

  if (!timeWindow) {
    return (
      <div
        className="ScheduleSelectInfoText"
        data-testid="empty-info"
        data-marker="Schedule info empty"
      >
        {localize(deliveryIsPickup ? 'select_pickup_time' : 'select_delivery_time')}
      </div>
    );
  }

  const scheduleInfoText = getScheduleInfoText({
    withDeliveryPrefix,
    isPickup: deliveryIsPickup,
    timeWindow,
    localize,
  });

  return (
    <div
      className="ScheduleSelectInfoText"
      data-testid="filled-info"
      data-marker="Schedule info filled"
    >
      {scheduleInfoText}
      {withTooltip && (
        <SlotTooltip
          endOrderingTime={timeWindow.end_ordering_time}
          language={language}
        />
      )}
    </div>
  );
};

export default ScheduleSelectInfoText;
