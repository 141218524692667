import { Category } from "src/redux/apiTypes";
import CategoriesSlider from "../CategoriesSlider/CategoriesSlider";
import CategoriesTags from "../CategoriesTags/CategoriesTags";

export interface Props {
  categoryLevel: number;
  title: string;
  categories: Category[];
  selectedCategory: Category;
}

const CategoryPageContentHeader = (props: Props) => {
  const { categoryLevel, title, categories, selectedCategory } = props;

  if (categoryLevel === 1) {
    return <CategoriesSlider title={title} categories={selectedCategory.children} />;
  }

  return (
    <CategoriesTags
      selectedCategory={selectedCategory}
      categories={categories}
      categoryLevel={categoryLevel}
      title={title}
    />
  );
};

export default CategoryPageContentHeader;
