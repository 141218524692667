import { phone } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .SearchHistory__header {
    padding: 16px 20px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .SearchHistory__title {
    ${typography.h6}
  }

  @media ${phone} {
    .SearchHistory__header {
      padding: 16px 10px 8px;
    }
  }
`;
