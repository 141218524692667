import css from 'styled-jsx/css';

export const styles = css`
    .Header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 299;
    }
  `;
