import * as React from 'react';
import { Fragment } from 'react';
import { styles } from './FooterContactsItem.styles';

interface Props {
  text: string;
  href?: string;
  icon?: string;
  dataMarker?: string;
  dataMarkerStoreId?: string;
  testId?: string;
}

const FooterContactsItem: React.FC<Props> = props => {
  const { href, icon, text, dataMarker, dataMarkerStoreId, testId } = props;

  return (
    <Fragment>
      <li className='FooterContactsItem' data-testid={testId} data-marker={dataMarker} data-marker-store-id={dataMarkerStoreId}>
        {
          href
            ? (
              <a href={href} rel="nofollow" className='FooterContactsItem__link'>
                {icon && <span className={`FooterContactsItem__icon icon icon-${icon}`}></span>}
                {text}
              </a>
            )
            : (
              <span className='FooterContactsItem__text'>
                {icon && <span className={`FooterContactsItem__icon icon icon-${icon}`}></span>}
                {text}
              </span>
            )
        }
      </li>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default FooterContactsItem;
