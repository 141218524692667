import { Banner, EnhancedError, FetchConfig } from 'src/redux/apiTypes';

export async function requestGetPartners(url: string, fetchConfig: FetchConfig): Promise<Banner[]> {
  const { xChain, xVersion, language } = fetchConfig;

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-chain': xChain,
      'x-version': xVersion,
      'Accept-Language': language,
    },
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    const error: EnhancedError = new Error('An error occurred while fetching the data.');

    error.data = await response.json();
    error.status = response.status;

    throw error;
  }

  return response.json();
}
