import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .FooterContacts {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .FooterContacts__title {
      ${typography.h3}
      color: #ffffff;
      margin-bottom: 11px;
    }
    .FooterContacts__title_withTopMargin {
      margin-top: 16px;
    }
  `;
