import css from 'styled-jsx/css';

export const styles = css`
    .MobileMenuIcon__iconContainer {
      width: 20px;
      height: 14px;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
    }

    .MobileMenuIcon__iconContainer span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: var(--colors-text);
      border-radius: 10px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      transform-origin: left center;
    }

    .MobileMenuIcon__iconContainer span:nth-child(1) {
      top: 0px;
    }

    .MobileMenuIcon__iconContainer span:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    .MobileMenuIcon__iconContainer span:nth-child(3) {
      bottom: 0;
    }

    .MobileMenuIcon__iconContainer_active span:nth-child(1) {
      transform: rotate(45deg);
      width: 20px;
      left: 3px;
      top: -1px;
    }

    .MobileMenuIcon__iconContainer_active span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    .MobileMenuIcon__iconContainer_active span:nth-child(3) {
      transform: rotate(-45deg);
      width: 20px;
      left: 3px;
      bottom: -1px;
    }

  `;
