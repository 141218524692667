import { CustomRouter } from "../../../globals";
import { Category } from "../../redux/apiTypes";
import getCategoryLevel from "./getCategoryLevel";

export interface Query {
  'category-id': string;
}

const getPlace = (router: CustomRouter<Query>, categories: Category[]) => {
  const page = router.pathname.split('/')[2];

  if (!page) {
    return 'main_page';
  }

  if (page === 'categories') {
    const categoryId = router.query['category-id'];
    const level = getCategoryLevel(categories, categoryId);

    return `catalogue_lv${level}`;
  }

  return page;
};

export default getPlace;
