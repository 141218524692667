import { LocalizeFunc } from "src/services/localization/types";
import { Currency } from '../appConfig/getInitialAppConfig';

const getFilterNamesWithCurrency = (
  name: string,
  currency: Currency,
  localize: LocalizeFunc,
): string => {
  const currencyText = localize(`general.currency.${currency}`);

  return `${name}, ${currencyText}`;
};

export default getFilterNamesWithCurrency;
