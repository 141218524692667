import css from 'styled-jsx/css';
import { desktop } from '../../../styles/media';

export default css`
  .PageWrapAside {
    background-color: transparent;
  }
  @media ${desktop} {
    .PageWrapAside {
      margin-left: -30px;
      padding-left: 30px;
      width: 250px;
    }
  }
  @media phone {
    .PageWrapAside {
      border: none;
    }
  }
}
`;
