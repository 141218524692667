import cn from 'classnames';
import { Fragment } from 'react';
import { Banner } from 'src/redux/apiTypes';
import { styles } from './MainSliderControls.styles';

export interface Props {
  banners: Banner[];
  isNavHidden: boolean;
  onInteract(event: boolean): void;
  controls: {
    onNextSlide(): void;
    onPrevSlide(): void;
    onGoToSlide(index: number): void;
    sliderState: {
      currentPosition: number;
    };
  };
}

export default function MainSliderControls(props: Props) {
  const { banners, isNavHidden, onInteract, controls } = props;
  const { onNextSlide, onPrevSlide, onGoToSlide, sliderState } = controls;
  const { currentPosition } = sliderState;

  if (banners.length < 2) {
    return null;
  }

  return (
    <Fragment>
      <div className={cn(
        'MainSliderControls',
        {'MainSliderControls_showNav': !isNavHidden},
        )}>
        <div
          className='MainSliderControls__control MainSliderControls__control_prev icon-arrow-small-left'
          data-marker='Left Arrow'
          onClick={() => {onPrevSlide(); onInteract(true);}}
        />

        <div className='MainSliderControls__pagination' data-marker='Bullets'>
          {banners.map((banner, index) => {
            return (<span
              key={banner.id}
              data-marker='Bullet'
              className={cn(
                'MainSliderControls__bullet',
                {
                  'MainSliderControls__bullet_active': index === currentPosition,
                },
              )}
              onClick={() => {onGoToSlide(index); onInteract(true);}}
            ></span>);
          })}
        </div>
        <div
          className='MainSliderControls__control MainSliderControls__control_next icon-arrow-small-right'
          data-marker='Right Arrow'
          onClick={() => {onNextSlide(); onInteract(true);}}
        />
      </div>
      <style jsx>{styles}</style>
    </Fragment>
  );
}
