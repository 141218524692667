import { Fragment } from 'react';
import { styles } from './Divider.styles';

function Divider() {

  return (
    <Fragment>
      <hr className='Divider' />
      <style jsx>{styles}</style>
    </Fragment>
  );
}

export default Divider;
