import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";
import { CategoryProducts } from "src/redux/apiTypes";
import { getPageMustBeReset } from "../helpers/getPageMustBeReset";

export const useResetPageForB2B = (categoryData: CategoryProducts) => {
  const { query, push, pathname } = useRouter();

  const pageMustBeReset = useMemo(() => getPageMustBeReset({
    categoryData,
    query,
  }), [categoryData, query]);

  const resetPageInQuery = useCallback(() => {
    const { page, ...restQuery } = query;

    push({
      pathname,
      query: restQuery,
    });
  }, [query, push, pathname]);

  useEffect(() => {
    if(pageMustBeReset) {
      resetPageInQuery();
    }
  }, [pageMustBeReset, resetPageInQuery]);

};
