import SearchHistoryItem from "src/components/containers/SearchHistory/SearchHistoryItem/SearchHistoryItem";
import Button from "src/components/ui-kit/Button/Button";
import useLocalization from 'src/services/localization/useLocalization';
import { styles } from "./SearchHistory.styles";

interface Props {
  onItemClick(item: string): void;
  onRemoveFromHistory(item: string): void;
  onClearHistory(): void;
  history: string[];
}

const SearchHistory = (props: Props) => {
  const { onItemClick, onClearHistory, onRemoveFromHistory, history } = props;
  const localize = useLocalization();

  return (
    <div className="SearchHistory" data-marker='History Results'>
      <div className="SearchHistory__header">
        <div className="SearchHistory__title">
          {localize('recent_searches')}
        </div>
        <Button
          variant="ghost"
          onClick={onClearHistory}
          dataMarkerValue="Clear history"
          regularText
        >
          {localize('general.clear-all')}
        </Button>
      </div>
      <div className="SearchHistory__list">
        {
          history.map(i => (
            <SearchHistoryItem
              key={i}
              text={i}
              onClick={() => onItemClick(i)}
              onRemove={() => onRemoveFromHistory(i)}
            />
          ))
        }
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default SearchHistory;
