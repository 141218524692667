import cn from 'classnames';
import { useRouter } from 'next/router';
import * as React from 'react';
import { RefObject, useEffect } from 'react';
import { getCategoryUrl, getCustomCategoryUrl, useFiltersLikeCategoriesMenuItems } from 'src/modules/Categories';
import { Category } from 'src/redux/apiTypes';
import { useAppSelector } from 'src/redux/hooks';
import useLocalization from 'src/services/localization/useLocalization';
import { styles } from './MobileMenuContent.styles';

interface Props {
  selectedCategories: Category[]
  setSelectedCategories: (value: Category[]) => void
  isMainMenu: boolean
  contentBodyRef: RefObject<HTMLDivElement>
  setIsScrollArrowDisplayed: (value: boolean) => void
}

const MobileMenuCategoriesBlock: React.FC<Props> = props => {
  const {
    selectedCategories,
    setSelectedCategories,
    isMainMenu,
    contentBodyRef,
    setIsScrollArrowDisplayed,
  } = props;

  const language = useAppSelector(s => s.app.language);
  const router = useRouter();
  const localize = useLocalization();
  const currentCategory = selectedCategories[selectedCategories.length - 1];
  const customMenuItems = useFiltersLikeCategoriesMenuItems();

  const handleCategoryClick = (category: Category) => {
    if (category.children.length) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      const url = getCategoryUrl({
        language,
        categoryId: category.id,
      });
      router.push(url);
    }
  };

  const handleViewAllClick = () => {
    const url =  getCategoryUrl({
      language,
      categoryId: currentCategory.id,
    });
    router.push(url);
  };

  const handleCustomCategoryClick = (categoryId: string) => () => {
    const url = getCustomCategoryUrl({
      language,
      categoryId,
    });

    router.push(url);
  };

  useEffect(() => {
    const contentBody = contentBodyRef.current;
    if (contentBody) {
      contentBody.scrollTop = 0;
      const canScrollDown = contentBody.clientHeight < contentBody.scrollHeight;
      setIsScrollArrowDisplayed(canScrollDown);
    }
  }, [contentBodyRef, currentCategory, setIsScrollArrowDisplayed]);

  return (
    <ul
      data-marker='Categories Menu'
    >
      {isMainMenu ?
        <React.Fragment>
          {
            customMenuItems.map(i => (
              <li
                key={`${i.id}-mobile-menu-item`}
                className='MobileMenuContent__menuItem'
                data-testid={i.id}
                data-marker={i.id}
                onClick={handleCustomCategoryClick(i.id)}
              >
                <img
                  className='MobileMenuContent__categoryIcon'
                  src={i.icon}
                  alt=''
                />
                <span className='MobileMenuContent__menuItemText'>
                  {i.title}
                </span>
              </li>
            ))
          }
        </React.Fragment>
        : (
          <li
            className='MobileMenuContent__menuItem MobileMenuContent__menuItem_withoutIcon'
            onClick={handleViewAllClick}
            data-testid='category'
            data-marker='View all'
          >
            <span className={'MobileMenuContent__menuItemText'}>
              {localize('categories.view_all')}
            </span>
            <div className='MobileMenuContent__menuItemCount'>{currentCategory.count}</div>
          </li>
        )
      }

      {currentCategory.children.map(category => {
        return (
          <li
            key={category.title}
            className={cn(
              'MobileMenuContent__menuItem',
              { 'MobileMenuContent__menuItem_withoutIcon': !isMainMenu },
            )}
            onClick={() => handleCategoryClick(category)}
            data-testid='category'
            data-marker={category.id}
          >
            {isMainMenu && category.image_url &&
              <img
                className='MobileMenuContent__categoryIcon'
                src={category.image_url.svg || category.image_url.png}
                alt={category.title}
              />
            }
            <span className='MobileMenuContent__menuItemText'>
              {category.title}
            </span>
            {category.children.length
              ? <div className='MobileMenuContent__menuItemArrow' />
              : <div className='MobileMenuContent__menuItemCount'>{category.count}</div>
            }
          </li>
        );
      })}
      <style jsx>{styles}</style>
    </ul>
  );
};

export default MobileMenuCategoriesBlock;
