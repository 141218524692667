import Link from "next/link";
import Tag from 'src/components/ui-kit/Tag/Tag';

export interface CategoryTagProps {
  title: string;
  href: string;
  isActive: boolean;
  onClick?(): void;
}

const CategoryTag = (props: CategoryTagProps) => {
  const { title, href, isActive, onClick } = props;

  return (
    <Link href={href} onClick={onClick}>
      <Tag variant={isActive ? 'active' : 'clickable'}>
        {title}
      </Tag>
    </Link>
  );
};

export default CategoryTag;
