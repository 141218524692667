import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
  .CategoriesMenuHeader {
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 0 10px 0 15px;
    border-bottom: 1px solid var(--colors-brandLightGrey1);
  }
  .CategoriesMenuHeader__title {
    ${typography.h4}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CategoriesMenuHeader__title:first-letter {
    text-transform: uppercase;
  }
  .CategoriesMenuHeader__container {
    display: flex;
    flex-shrink: 0;
    margin-left: 10px;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
  }
  `;
