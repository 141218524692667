import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from '../../../../../utils/system/scrollLock';
import MobileMenuContent from '../MobileMenuContent/MobileMenuContent';
import { styles } from './MobileMenu.styles';

const contentBodyRef = React.createRef<HTMLDivElement>();
interface Props {
  onClose(): void;
}
const MobileMenu = (props: Props) => {
  const { onClose } = props;
  const [isScrollArrowDisplayed, setIsScrollArrowDisplayed] = useState(true);

  useEffect(() => {
    disableBodyScroll();
    return () => {
      enableBodyScroll();
    };
  }, []);

  const handleScrollArrowClick = (event: React.MouseEvent) => {
    const contentBody = event.currentTarget.parentElement;
    
    if(contentBody) {
      contentBody.scrollTop = contentBody.scrollHeight - contentBody.clientHeight;
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement> & { target: HTMLElement }) => {
    const canScrollDown = (event.target.scrollTop + event.target.clientHeight) < event.target.scrollHeight;
    setIsScrollArrowDisplayed(canScrollDown);
  };

  return (
    <Fragment>
      <div
        className='MobileMenu__contentBody'
        onScroll={handleScroll}
        data-testid='contentBody'
        ref={contentBodyRef}
      >
        <MobileMenuContent
          setIsScrollArrowDisplayed={setIsScrollArrowDisplayed}
          contentBodyRef={contentBodyRef}
          onClose={onClose}
        />
        {isScrollArrowDisplayed &&
          <div
            className='MobileMenu__scrollArrowBlock'
            onClick={handleScrollArrowClick}
            data-testid='scrollArrow'
          >
            <div className='MobileMenu__scrollArrow' />
          </div>
        }
      </div>
      <div
        className='MobileMenu__overlay'
        onClick={onClose}
      ></div>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default MobileMenu;
