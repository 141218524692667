import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useAppSelector } from "src/redux/hooks";
import { Language } from "src/utils/appConfig/getInitialAppConfig";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";

const useChooseLanguageProps = () => {
  const { languages } = useAppConfig();
  const { asPath } = useRouter();
  const currentLanguage = useAppSelector(s => s.app.language);

  const getClickHandler = (url: string, language: Language) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    Cookies.set('language', language, { expires: 365 });
    window.location.href = url;
  };

  const getUrl = (language: Language) => asPath.replace(currentLanguage, language);

  return {
    languages,
    currentLanguage,
    getClickHandler,
    getUrl,
  };
};

export default useChooseLanguageProps;
