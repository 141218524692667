import css from 'styled-jsx/css';

export const styles = css`
    .ScrollUpButton {
      position: fixed;
      right: 20px;
      bottom: 76px;
      z-index: 10;
      padding: 0;
      border: none;
      cursor: pointer;
      background: none;
    }

    .ScrollUpButton__icon:hover {
      box-shadow: 0px 2px 4px rgba(34, 36, 33, 0.12);
    }

    .ScrollUpButton__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: var(--colors-brandLightGrey1);
      color: var(--colors-text);
      font-size: 27px;
      transition: 0.2s all ease-in;
    }

  `;
