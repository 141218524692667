import css from 'styled-jsx/css';
import { phone, tablet } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  .CatalogFiltersMobileCategories__categoryItem {
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--colors-brandLightGrey10);
  }
  .CatalogFiltersMobileCategories__categoryButton {
    background-color: transparent;
    height: 50px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--colors-brandLightGrey10);
    ${typography.h6}
  }
  .CatalogFiltersMobileCategories__activeName {
    ${typography.text}
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    color: var(--colors-doveGray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CatalogFiltersMobileCategories__title{
    flex-shrink: 0;
  }
  .CatalogFiltersMobileCategories__activeFilters {
    margin-left: 4px;
  }
  .CatalogFiltersMobileCategories__categoryButtonIcon {
    font-size: 8px;
    transform: rotate(-90deg);
    color: var(--colors-brandLightGrey8);
  }
  .CatalogFiltersMobileCategories__headerTitle {
    ${typography.h4}
  }
  .CatalogFiltersMobileCategories__headerButton {
    flex-shrink: 0;
    font-size: 13px;
    color: var(--colors-doveGray);
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background-color: transparent;
  }
  .CatalogFiltersMobileCategories__header {
    display: flex;
    height: 50px;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--colors-brandLightGrey10);
  }
  .CatalogFiltersMobileCategories__content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media ${tablet} {
    .CatalogFiltersMobileCategories__categoryItem {
      padding: 14px 24px;
    }
    .CatalogFiltersMobileCategories__categoryButton {
      padding: 14px 24px;
      height: 56px;
    }
    .CatalogFiltersMobileCategories__headerTitle {
      ${typography.h3}
    }
    .CatalogFiltersMobileCategories__header {
      ${typography.h3}
      padding: 14px 24px;
    }
  }
  @media ${phone} {
    .CatalogFiltersMobileCategories__categoryItem {
      padding: 10px;
    }
    .CatalogFiltersMobileCategories__categoryButton {
      padding: 10px;
      height: 44px;
    }
    .CatalogFiltersMobileCategories__header {
      padding: 10px;
      font-size: 15px;
    }
  }
  `;
