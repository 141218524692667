import { MutableRefObject, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useStableFunction } from '../useStableFunction/useStableFunction';

const useResizeObserver = (headerElRef: MutableRefObject<HTMLElement>, handler: () => void) => {
  const handlerStable = useStableFunction(handler);

  useEffect(() => {
    const headerElement = headerElRef.current;

    if (headerElement) {
      const resizeObserver = new ResizeObserver(handlerStable);
      resizeObserver.observe(headerElement);

      return () => resizeObserver.unobserve(headerElement);
    }
  }, [headerElRef, handlerStable]);
};

export default useResizeObserver;
