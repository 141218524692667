import { Dispatch } from 'redux';
import { Category } from 'src/redux/apiTypes';
import isArrayValid from 'src/utils/js-helpers/isArrayValid';
import { openModal } from '../../../redux/modals';
import { RootState } from '../../reducers';

export interface Params {
  selectedCategoryId?: string,
  AGE_CONFIRMATION_IS_ENABLED: boolean;
}

const checkExcisableAvailability = (params: Params) => (
  dispatch: Dispatch, getState: () => RootState,
) => {
  const { selectedCategoryId, AGE_CONFIRMATION_IS_ENABLED } = params;
  const state = getState();
  const { confirmedAge } = state.userProfile;
  const { isLoggedIn } = state.user;
  const isConfirmedAge = confirmedAge === 'confirmed';

  if (!AGE_CONFIRMATION_IS_ENABLED || isLoggedIn || isConfirmedAge) {
    return;
  }

  const { categories } = state.categories;
  const parentCategories = getParentCategories(categories, selectedCategoryId);
  const hasExcisable = parentCategories[0]?.excisable;

  if (hasExcisable || !selectedCategoryId) {
    dispatch(openModal('confirmAgeModal'));
  }
};

export default checkExcisableAvailability;

const getParentCategories = (
  categories: Category[],
  categoryId: string,
): Category[] => {
  if (categories && categoryId) {
    for (let i = 0; i < categories.length; i += 1) {
      if (categories[i].id === categoryId) {
        return [categories[i]];
      }

      const arr = getParentCategories(categories[i].children, categoryId);

      if (isArrayValid(arr)) {
        arr.unshift(categories[i]);
        return arr;
      }
    }
  }

  return [];
};
