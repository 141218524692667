import css from 'styled-jsx/css';

export const styles = css`
  .FooterLink {
    font-size: 14px;
    text-decoration: none;
    color: var(--colors-white);
    opacity: 0.6;
  }

  .FooterLink:hover {
    opacity: 1;
  }
`;
