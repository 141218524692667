import { phone } from "src/styles/media";
import typography from "src/styles/typography";
import css from "styled-jsx/css";

export const styles = css`
  .MainCategoryCard {
    position: relative;
    width: 100%;
    height: 96px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    border-radius: var(--border-radius-block);
    background-color: var(--colors-white);
    overflow: hidden;
    transition: all 0.2s ease-out;
  }

  .MainCategoryCard:hover {
    box-shadow: 0 8px 15px 0 rgba(34, 36, 33, 0.08);
    background: #fff;
  }
  .MainCategoryCard__title {
    position: relative;
    z-index: 2;
    width: 122px;
    ${typography.h6}
    line-height: 24px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .MainCategoryCard__imageWrapper {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    width: 100px;
    height: 96px;
  }

  @media ${phone} {
    .MainCategoryCard {
      width: 91px;
      height: 155px;
      padding: 12px 8px;
    }

    .MainCategoryCard__title {
      width: 100%;
      ${typography.h9}
      font-weight: 600;
      line-height: 16px;
    }

    .MainCategoryCard__imageWrapper {
      top: inherit;
      bottom: 0;
    }
  }

`;
