import { CategoryProducts, DeliveryType, FetchConfig } from "src/redux/apiTypes";

export interface CustomCategoryRequestParams {
  categoryId: string;
  queryString?: string;
  storeId: string;
  deliveryType: DeliveryType;
  fetchConfig: FetchConfig,
}

export const requestGetFiltersLikeCategoriesProducts = async (params: CustomCategoryRequestParams): Promise<CategoryProducts> => {
  const {
    fetchConfig,
    categoryId,
    storeId,
    deliveryType,
    queryString,
  } = params;
  const { language, apiRoot, xVersion, xChain } = fetchConfig;
  const searchString = queryString ? `?${queryString}` : '';
  const url = `${apiRoot}/stores/${storeId}/products/filter_by_category/${categoryId}/${searchString}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
      'X-Delivery-Type': deliveryType,
    },
    credentials: 'include',
  });

  return response.json();
};

