import typography from 'src/styles/typography';
import css from 'styled-jsx/css';
import { desktop, phone, phone_tablet, tablet_desktop } from '../../../../../styles/media';

export const styles = css`
  .CartButton {
    position: relative;
    ${typography.btn}
    font-size: 16px;
    color: var(--colors-white);
    border-radius: var(--border-radius-roundBox);
    padding: 0 30px;
    border: 0 none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    user-select: none;
    transition: 0.15s background ease-in-out;
  }
  .CartButton__icon {
    font-size: 18px;
  }
  @media ${desktop} {
    .CartButton {
      display: flex;
      min-width: 150px;
      padding: 0 30px;
      transition: 0.2s background-color ease-in-out;
      margin-left: 10px;
      background-color: var(--uiColors-CartButton-background);
    }
    .CartButton:hover {
      background-color: var(--uiColors-CartButton-hoverBackground);
    }
    .CartButton__badge {
      position: absolute;
      top: 4px;
      left: 45px;
      min-width: 15px;
      height: 16px;
      padding: 0 3px;

      background-color: var(--uiColors-CartButton-badgeBackground);
      border-radius: 16px;

      color: var(--uiColors-CartButton-badgeText);
      font-size: 8px;
      font-weight: 600;
      text-align: center;
      line-height: 16px;
    }
    .CartButton__label {
      margin-left: 15px;
      padding-top: 1px;
    }
  }
  @media ${tablet_desktop} {
    .CartButton__label {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5pt;
    }

  }
  @media ${phone_tablet} {
    .CartButton {
      padding: 0 10px;
      background-color: transparent;
    }
    .CartButton__badge {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: var(--colors-orange);
      min-height: 16px;
      min-width: 16px;
      padding: 2px 3px;

      font-size: 12px;
      color: var(--colors-text);
      border-radius: 16px;
    }
    .CartButton__label {
      display: none;
      font-weight: 600;
    }
  }
  @media ${phone} {
    .CartButton {
      padding-right: 2px;
    }

    .CartButton__badge {
      right: -6px;
    }
  }
`;
