import { Fragment } from "react";
import { styles } from './FooterLink.styles';

interface FooterLinkProps {
  title: string;
  href: string;
  rel: string;
  target?: string;
}

const FooterLink = (props: FooterLinkProps) => {
  const { title, ...restProps } = props;

  return (
    <Fragment>
      <a
        className='FooterLink'
        {...restProps}
      >
        {title}
      </a>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default FooterLink;
