import makeSearchString from '../../../utils/system/makeSearchString';

export const excludeParams = ['lang', 'custom_category_id'];

export function getUrlString<T>(query: T) {
  const searchString = makeSearchString(query, excludeParams);
  const params = searchString.split('?')[1];
  return params;
}

interface Params {
  language: string;
  categoryId: string;
  queryParams?: string;
}

export const getCustomCategoryUrl = (params: Params) => {
  const {
    language,
    categoryId,
    queryParams,
  } = params;
  const searchString = queryParams ? `?${queryParams}` : '';
  return `/${language}/custom-categories/${categoryId}/${searchString}`;
};

export const getB2BPageUrl = (language: string) => getCustomCategoryUrl({ language, categoryId: 'b2b-products' });
