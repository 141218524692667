import { QueryString } from "src/redux/apiTypes";
import { Chain } from 'src/services/chain/types';

interface RetailInfoLinkUrlParams {
  language: string;
  chain: Chain;
  withDeliveryData: boolean;
  deliveryAsQueryStringForLink: QueryString;
}


const getRetailInfoLinkUrl = (params: RetailInfoLinkUrlParams) =>{
  const {
    language,
    chain,
    withDeliveryData,
    deliveryAsQueryStringForLink,
  } = params;

  if (withDeliveryData) {
    return `https://${chain.hostname}/${language}/${deliveryAsQueryStringForLink}`;
  }

  return `https://${chain.hostname}/${language}/`;
};

export default getRetailInfoLinkUrl;
