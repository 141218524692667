import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'src/components/ui-kit/Button/Button';
import { getB2BPageUrl, getCustomCategoryUrl } from 'src/modules/Categories';
import { NOT_MULTI_FILTER_PARAMS } from 'src/redux/constants';
import { useAppSelector } from 'src/redux/hooks';
import useLocalization from 'src/services/localization/useLocalization';
import filterSearchParamsArr from 'src/utils/filter/filterSearchParamsArr';
import toggleFilter from 'src/utils/filter/toggleFilter';
import { openModal } from '../../../redux/modals';
import { styles } from './EmptyCategory.styles';

interface Query {
  firstLevelFilter: string;
}

interface Props {
  excludeParams: string[];
  activeFilters: boolean;
  emptyMessageSlot?: ReactNode;
  iconSelectedCategory?: string;
}

const EmptyCategory = (props: Props) => {
  const { excludeParams, activeFilters, emptyMessageSlot, iconSelectedCategory } = props;
  const localize = useLocalization();
  const router = useRouter<Query>();
  const firstLevelFilter = router.query.firstLevelFilter;
  const language = useAppSelector(s => s.app.language);
  const categoryIsAvailable = useAppSelector(s => !s.catalogue.isNotAvailableForNP);
  const customPages = [
    {
      url: `/${language}/search`,
    },
    {
      url: getB2BPageUrl(language),
      img: `/i/b2b_products.svg`,
    },
    {
      url: getCustomCategoryUrl({ language, categoryId: 'promotions' }),
      img: `/i/promotionsIcon.svg`,
    },
  ];



  const customPagesCategory = customPages.find((page) => router.asPath.includes(page.url));
  const dispatch = useDispatch();

  const removeLastFilter = () => {
    if (!firstLevelFilter && !activeFilters) {
      return;
    }

    if (activeFilters) {
      const params = router.asPath.split('?')[1];
      let filterParams: string[] = [];

      if (!firstLevelFilter) {
        // to delete when price last filter
        const noFilterParams = NOT_MULTI_FILTER_PARAMS.filter(param => param !== 'price');
        const searchParamsArr = [...params.split('&')];
        filterParams = filterSearchParamsArr(searchParamsArr, noFilterParams);
      } else {
        const searchParamsArr = params ? [firstLevelFilter, ...params.split('&')] : [firstLevelFilter];
        filterParams = filterSearchParamsArr(searchParamsArr, NOT_MULTI_FILTER_PARAMS);
      }

      const lastFilterParam = filterParams[filterParams.length - 1];
      const [type, name] = decodeURIComponent(lastFilterParam).split('=');
      toggleFilter(type, name, router, excludeParams);
    }
  };

  const getTitleKey = () => {
    if (activeFilters) {
      return 'products.empty';
    }

    if (!categoryIsAvailable) {
      return 'not_available_category_title';
    }

    return 'empty_category_title';
  };

  if (!activeFilters && customPagesCategory && emptyMessageSlot) {
    return <div data-testid={'emptyMessageSlot'}>{emptyMessageSlot}</div>;
  }

  const iconCategory = customPagesCategory
    ? customPagesCategory?.img
    : iconSelectedCategory;

  return (
    <div className='EmptyCategory' data-testid='emptyCategory'>
      <span
        className={classNames(
          'EmptyCategory__icon', {
          'icon-filters': activeFilters,
          'icon-nova-poshta': !firstLevelFilter && !categoryIsAvailable,
        })}
      />

      {!!categoryIsAvailable && !activeFilters && iconCategory &&
        <Image
          src={iconCategory}
          alt={'icon category'}
          width={62}
          height={65}
        />
      }

      <h2 className='EmptyCategory__title'>
        {localize(getTitleKey())}
      </h2>

      <div className='EmptyCategory__buttons'>
        {activeFilters &&
          <Button
            dataMarkerValue='Remove last filter'
            onClick={removeLastFilter}
          >
            {localize('products.empty.button')}
          </Button>
        }

        {!firstLevelFilter && !categoryIsAvailable &&
          <Button
            dataMarkerValue='Choose another delivery method'
            onClick={() => dispatch(openModal('addressModal'))}
          >
            {localize('choose_another_delivery_type')}
          </Button>
        }

        {!activeFilters &&
          <div className='EmptyCategory__link'>
            <Button
              fullWidth
              variant={!categoryIsAvailable ? 'secondary' : 'primary'}
              nextLinkProps={{ href: '/[lang]', as: `/${language}/` }}
              dataMarkerValue='Go back to the main page'
            >
              {localize('return_to_main')}
            </Button>
          </div>
        }
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default EmptyCategory;
