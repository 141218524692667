import ProductsSliderWithPlaceholder from "src/components/views/ProductsSliderWithPlaceholder/ProductsSliderWithPlaceholder";
import useProductsCarouselProps from "src/hooks/useProductsCarouselProps/useProductsCarouselProps";
import useLocalization from 'src/services/localization/useLocalization';
import { addToCartButtonsEnum, impressionListEnum, sendViewProductsListEvent } from "src/utils/marketing/enhancedEcommerce";
import WithHorecaAuth from "../../../../components/containers/WithAuthHoreca/WithAuthHoreca";
import { getB2BPageUrl } from "../../helpers/customCategoryPageHelpers";
import useCustomCategoryProducts from "../../hooks/useCustomCategoryProducts";

const B2BProductsSlider = () => {
  const localize = useLocalization();
  const { data: b2bResults, isFetching } = useCustomCategoryProducts('b2b-products');
  const title = localize('category_horeca_goods');

  const carouselProps = useProductsCarouselProps({
    items: b2bResults,
    title,
    handlersPlace: addToCartButtonsEnum.MAIN_PAGE_B2B_PRODUCTS,
    place: impressionListEnum.B2B_PRODUCTS,
  });
  const lang = carouselProps.language;
  const itemsToBeShown = carouselProps.items;

  const handleImpressed = () => {
    sendViewProductsListEvent('b2b_products', title, itemsToBeShown);
  };

  return <ProductsSliderWithPlaceholder
    carouselProps={{
      ...carouselProps,
      withShowAllButton: true,
      buttonUrl: getB2BPageUrl(lang),
      dataSuffix: 'b2b',
    }}
    onImpressed={handleImpressed}
    placeholderTitle={title}
    placeholderShouldBeDisplayed={isFetching}
    sliderShouldNotDisplayed={itemsToBeShown.length === 0}
  />;
};

export default function B2BSliderWithAuth() {
  return (
    <WithHorecaAuth conditionalMode>
      <B2BProductsSlider />
    </WithHorecaAuth>
  );
}
