
import Tag from 'src/components/ui-kit/Tag/Tag';
import useLocalization from 'src/services/localization/useLocalization';
import { styles } from './SearchPopularProducts.styles';

export interface SearchPopularProductsProps {
  items: string[];
  onItemClick: (item: string) => void
}

const SearchPopularProducts = (props: SearchPopularProductsProps) => {
  const { items, onItemClick } = props;
  const localize = useLocalization();

  return (
    <div className="SearchPopularProducts" data-marker='Popular Results'>
      <div className='SearchPopularProducts__title'>
        {localize('popular_searches')}
      </div>
      <div className='SearchPopularProducts__items'>
        {items.map((item) => (
          <div
            className="SearchPopularProducts__item"
            key={item}
            onClick={() => onItemClick(item)}
          >
            <Tag dataMarker="Popular Tag">{item}</Tag>
          </div>
        ))}
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default SearchPopularProducts;
