import { ParsedUrlQuery } from "querystring";
import { CategoryProducts } from "src/redux/apiTypes";

interface Params {
  categoryData: CategoryProducts;
  query: ParsedUrlQuery;
}

export const getPageMustBeReset = (params: Params) => {
  const {
    categoryData,
    query,
  } = params;
  const pageMustBeReset = categoryData.results.length === 0
    && categoryData.count > 0
    && Number(query.page) > 1;

  return pageMustBeReset;
};
