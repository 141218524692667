import { desktop } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .HeaderScheduleButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
  }
  .HeaderScheduleButton__icon {
    font-size: 17px;
    color: var(--colors-brandLightGrey8);
  }
  .HeaderScheduleButton__label {
    display: block;
    ${typography.h9};
    line-height: 1;
    margin-left: 4px;
  }
  .HeaderScheduleButton__label:hover {
    color: var(--colors-linkAccented);
  }

  @media ${desktop} {
    .HeaderScheduleButton {
      margin-right: 16px;
    }
  }
`;
