const filterSearchParamsArr = (searchParamsArr: string | string[], excludeParams: string[] = []) => {
  if(!Array.isArray(searchParamsArr)) {
    return [];
  }

  return searchParamsArr.filter(param => {
    const [paramName] = decodeURIComponent(param).split('=');
    return !excludeParams.some(p => p === paramName);
  });
};

export default filterSearchParamsArr;
