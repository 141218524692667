import { ButtonVariant } from "src/components/ui-kit/Button/Button";
import { useAppSelector } from "src/redux/hooks";
import useLocalization from "src/services/localization/useLocalization";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";

export interface LinkBaseParams {
  title: string;
  href: string;
}

export interface ButtonLinksParams extends LinkBaseParams {
  variant: ButtonVariant;
}

const useContactsButtons = (): ButtonLinksParams[] => {
  const localize = useLocalization();
  const { country } = useAppConfig();

  const currentLanguage = useAppSelector(s => s.app.language);
  const lang = currentLanguage === 'en' ? 'en-us' : currentLanguage;

  return [
    {
      "title": localize('questions_answers'),
      "href": `https://help.zakaz.${country}/hc/${lang}`,
      "variant": "primary",
    },
    {
      "title": localize('contact_center.support_button'),
      "href": `https://help.zakaz.${country}/hc/${lang}/requests/new`,
      "variant": "secondary",
    },
  ];
};

export default useContactsButtons;
