import { Fragment, ReactNode } from 'react';
import styles from './PageWrapAside.styles';

export interface Props {
  children?: ReactNode;
  dataMarker?: string;
}

export default function PageWrapAside(props: Props) {
  const { children = null, dataMarker } = props;

  return (
    <Fragment>
      <div className='PageWrapAside' data-marker={dataMarker}>{children}</div>
      <style jsx>{styles}</style>
    </Fragment>
  );
}
