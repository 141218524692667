import { useMediaQuery } from 'react-responsive';
import Responsive from 'src/components/views/Responsive/Responsive';
import SearchBox from '../../../SearchBox/SearchBox';
import CartButton from '../CartButton/CartButton';
import CategoriesMenuButton from '../CategoriesMenuButton/CategoriesMenuButton';
import PageButtons from '../PageButtons/PageButtons';
import SearchButton from '../SearchButton/SearchButton';
import { styles } from './HeaderBottomLine.styles';

export interface Props {
  searchBoxIsActive: boolean;
  desktopMenuIsFixed: boolean;
  desktopMenuIsOpen: boolean;
  toggleDesktopMenu(isActive: boolean): void;
  headerBottomPosition: number;
}

export const HeaderBottomLine = (props: Props) => {
  const {
    searchBoxIsActive,
    desktopMenuIsOpen,
    desktopMenuIsFixed,
    toggleDesktopMenu,
    headerBottomPosition,
  } = props;

  const notLargeDesktop = useMediaQuery({ maxWidth: 1230 });
  const pageButtonsAreVisible = !(searchBoxIsActive && notLargeDesktop);

  return (
    <div className='HeaderBottomLine' data-marker='ChainHeader'>
      <div className='HeaderBottomLine__container'>
        <div className='HeaderBottomLine__menu'>
          <CategoriesMenuButton
            desktopMenuIsFixed={desktopMenuIsFixed}
            desktopMenuIsOpened={desktopMenuIsOpen}
            toggleDesktopMenu={toggleDesktopMenu}
          />
        </div>
        <div className='HeaderBottomLine__search'>
          <SearchBox overlayTopPosition={headerBottomPosition} />
        </div>
        <div className='HeaderBottomLine__bar'>
          <Responsive phone>
            <SearchButton />
          </Responsive>
          {pageButtonsAreVisible && <PageButtons />}
          <CartButton />
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default HeaderBottomLine;
