import { useContext } from 'react';
import { ServicePage } from 'src/redux/apiTypes';
import ServicePagesContext from './servicePagesContext';

export default function useServicePagesService(): ServicePage[] {
  const context = useContext(ServicePagesContext);

  if (!context) {
    throw new Error('ServicePagesContext is used without provider');
  }

  return context;
}
