import { MouseEventHandler } from "react";
import MobileMenuIcon from "../MobileMenuIcon/MobileMenuIcon";

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
  dataMarker?: string;
  isActive: boolean;
  count?: number | null;
}

const BurgerButton = (props: Props) => {
  const { onClick, isActive, dataMarker, count } = props;

  return (
    <button
      type='button'
      onClick={onClick}
      data-marker={dataMarker}
      className='BurgerButton'
      data-testid='burger'
    >
      {
        Number(count) > 0 && <span
          className="BurgerButton__redDot"
          data-marker='Red dot'
          data-testid='red-dot'
        >
          {count}
        </span>
      }
      <MobileMenuIcon
        isActive={isActive}
      />
      <style jsx>{`
        .BurgerButton {
          position: relative;
          background: transparent;
          border: none;
          outline: none;
          transition: 0.1s transform ease-in-out;
          cursor: pointer;
          padding: 0;
        }

        .BurgerButton__redDot {
          position: absolute;
          display: block;
          padding: 0 4px;
          min-width: 17px;
          height: 17px;
          font-size: 8px;
          font-weight: 600;
          line-height: 17px;
          color: var(--colors-white);
          background-color: var(--colors-red);
          border-radius: 50%;
          right: -8px;
          top: -10px;
          z-index: 1;
        }
      `}</style>
    </button>
  );
};

export default BurgerButton;
