import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
  .FooterActions__label {
    margin-bottom: 16px;
    ${typography.h3};
    color: var(--colors-white);
  }

  .FooterActions__buttons {
    margin-top: 16px;
  }

  .FooterActions__buttonItem:not(:last-child) {
    margin-bottom: 12px;
  }
`;
