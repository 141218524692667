import Image from 'next/image';
import { BannerImage } from '../../../redux/apiTypes';
import { styles } from './MainSlider.styles';

interface Props {
  image: BannerImage;
  /** sets priority prop to the first next/image for resolve LCP issue*/
  isFirst: boolean;
}

const DefaultSlide = (props: Props) => {
  const {
    image: {
      mobile: { url: mobileUrl },
      desktop: { url: desktopUrl },
    },
    isFirst,
  } = props;

  /**
   * At the moment next/image doesn't support <picture> and <source> functionality
   * We use this solution from https://github.com/vercel/next.js/discussions/25393
   * */
  return (
    <picture className="MainSlider__imgWrapper">
      <source media="(max-width: 767px)" srcSet={ mobileUrl }/>
      <source media="(min-width: 768px)" srcSet={ desktopUrl }/>
      <Image
        fill
        src={ mobileUrl }
        alt="Banner"
        priority={isFirst}
      />
      <style jsx>{ styles }</style>
    </picture>
  );
};

export default DefaultSlide;
