import css from 'styled-jsx/css';

export const styles = css`
    .Pagination__wrapper {
      display: flex;
      justify-content: center;

      width: 100%;
    }

    :global(.Pagination) {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 9px 5px;
      background-color: var(--colors-white);
      border: 1px solid var(--colors-brandLightGrey1);
      border-radius: 24px;
    }

    :global(.Pagination__item) {
      display: block;
      width: 28px;
      line-height: 28px;
      border-radius: 50%;
      text-align: center;
      transition: 0.2s all ease-in-out;
      text-decoration: none;
      color: inherit;
    }

    :global(.Pagination__item_active) {
      background-color: var(--uiColors-CategoryProductBox-paginationItemActiveBackground);
      color: var(--uiColors-CategoryProductBox-paginationItemActiveText);
    }

    :global(.Pagination__item:not(.pagination__item_active):hover) {
      background-color: transparent;
      color: var(--uiColors-CategoryProductBox-paginationItemHoverText);
      cursor: pointer;
    }

    :global(.Pagination__direction) {
      padding: 0 8px;
    }

    :global(.Pagination__directionLink) {
      display: flex;
      width: 28px;
      height: 28px;
      justify-content: center;
      align-items: center;
      font-size: 22px;

      text-decoration: none;
      color: inherit;
    }

    :global(.Pagination__direction_disabled) {
      opacity: 0.5;
    }

    :global(.Pagination__direction:not(.Pagination__direction_disabled))
    :global(.Pagination__direction-link:hover) {
      color: var(--uiColors-CategoryProductBox-paginationDirectionHoverIcon);
      cursor: pointer;
    }

    :global(.pagination__break) {
      color: var(--colors-darkGrey);
    }

  `;
