import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .ProductsSliderPlaceholder {
      position: relative;
      margin: 0 0 60px;
      width: 100%;
    }

    .ProductsSliderPlaceholder__inner {
      background: white;
      border-radius: var(--border-radius-block);
      border-color: var(--colors-brandLightGrey1);
      border-width: 1px 0 1px 1px;
      border-style: solid;
      padding: 8px;
    }

    .ProductsSliderPlaceholder__title {
      ${typography.h2}
      margin: 7px 0 5px;
      padding: 0;
      height: 42px;
      text-align: start;
    }

    .ProductsSliderPlaceholder__title:first-letter {
      text-transform: uppercase;
    }

    .ProductsSliderPlaceholder__shape {
      border-radius: 10px;
    }

    .ProductsSliderPlaceholder__image {
      margin-bottom: 10px;
      height: 188px;
    }

    .ProductsSliderPlaceholder__price {
      height: 70px;
    }
  `;
