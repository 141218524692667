import { getChains } from 'src/ajax/getChains';
import { Chain } from 'src/services/chain/types';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from "swr";

const emptyArray: Chain[] = [];

const useChains = () => {
  const { fetchConfig } = useAppConfig();

  const retailChainsFetcher = () => getChains(fetchConfig);

  const { data, error } = useSWR(
    ['retailChains'],
    retailChainsFetcher,
  );

  const chainsAreFetching = !data && !error;
  const chains = data && data.length ? data : emptyArray;

  return {
    chains,
    chainsAreFetching,
  };
};

export default useChains;
