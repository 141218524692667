import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import SliderWrapper from "src/components/views/SliderWrapper/SliderWrapper";
import { Category } from "src/redux/apiTypes";
import { tablet } from "src/styles/media";
import { groupArrayElements } from "src/utils/js-helpers/groupArrayElements";
import { sendSubcategoryClick } from "../../helpers/analytics";
import { CategoryPageQuery, getCategoryHrefWithSort } from "../../helpers/getCategoryHrefWithSort";
import CategoryCard from "../CategoryCard/CategoryCard";
import { itemsOnDesktop, itemsOnTablet, styles } from "./CategoriesSlider.styles";

interface Props {
  categories: Category[];
  title: string;
}

const CategoriesSlider = (props: Props) => {
  const { categories, title } = props;
  const { query } = useRouter<CategoryPageQuery>();
  const isTablet = useMediaQuery({ query: tablet });
  const rows = 2;
  const categoriesInSlideCount = rows * (isTablet ? itemsOnTablet : itemsOnDesktop);
  const categoriesSlides = groupArrayElements(categories, categoriesInSlideCount);
  const withNavButtons = categories.length > categoriesInSlideCount;

  return (
    <div className="CategoriesSlider">
      <SliderWrapper
        title={title}
        dataMarker='categories slider'
        withNavButtons={withNavButtons}
        titleIsH1
      >
        {
          categoriesSlides.map((categories, index) => (
            <div className="CategoriesSlider__slide" key={`slide ${index}`}>
              {
                categories.map((category) => (
                  <div key={category.id} className='CategoriesSlider__item'>
                    <CategoryCard
                      imgSrc={category.image_url.png}
                      title={category.title}
                      href={getCategoryHrefWithSort({ categoryId: category.id, query })}
                      onClick={() => sendSubcategoryClick({
                        place: 'category_tiles',
                        page: 'category_lvl1',
                        sliderIndex: index,
                        categoryId: category.id,
                      })}
                    />
                  </div>
                ))
              }
            </div>
          ))
        }
      </SliderWrapper>
      <style jsx>{styles}</style>
    </div>
  );
};

export default CategoriesSlider;
