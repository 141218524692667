import { styles } from './GoToTopButton.styles';

const GoToTopButton = () => {
  const handleClick = () => {
    window.scroll(0, 0);
  };


  return (
    <button
      className='GoToTopButton'
      onClick={handleClick}
      data-testid='goToTopButton'
    >
      <span className='icon-arrow-up'/>
      <style jsx>{styles}</style>
    </button>
  );
};

export default GoToTopButton;
