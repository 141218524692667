import { Category } from "../../redux/apiTypes";

type FindCategoryObjectResult = Category | null;

export default function findCategoryObject(
  categories: Category[],
  categoryId: string | null,
): FindCategoryObjectResult {
  let result: FindCategoryObjectResult = null;

  categories.forEach((obj) => {
    let foundCategory: Category | null = null;

    if (obj.id === categoryId) {
      foundCategory = obj;
    } else if (obj.children && obj.children.length > 0) {
      foundCategory = findCategoryObject(
        obj.children,
        categoryId,
      );
    }

    if (foundCategory) {
      result = {...foundCategory};
    }
  });

  return result;
}
