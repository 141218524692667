import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { styles } from './Pagination.styles';

interface Props {
  paginationOptions: ReactPaginateProps;
}

export const Pagination = ({ paginationOptions }: Props) => {

  return (
    <div className='Pagination__wrapper'>
      <ReactPaginate
        breakLabel='...'
        breakClassName='Pagination__break'
        containerClassName='Pagination'
        pageLinkClassName='Pagination__item'
        activeLinkClassName='Pagination__item_active'
        previousLabel={<span className='icon-arrow-left' />}
        nextLabel={<span className='icon-arrow-right' />}
        previousClassName='Pagination__direction'
        previousLinkClassName='Pagination__directionLink'
        nextClassName='Pagination__direction'
        nextLinkClassName='Pagination__directionLink'
        disabledClassName='Pagination__direction_disabled'
        {...paginationOptions}
      />
      <style jsx>{styles}</style>
    </div>
  );
};

export default Pagination;
