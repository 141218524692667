import Head from 'next/head';

interface Props {
  title: string;
  description: string;
}

const CustomCategoryPageHead = (props: Props) => {
  const { title, description } = props;

  return (
    <Head>
      <title key='title'>
        {title}
      </title>
      <meta name='description' content={description} key='meta-description' />
    </Head>
  );
};

export default CustomCategoryPageHead;
