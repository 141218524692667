import Image from 'next/image';
import { styles } from './FooterCertificate.styles';

interface Props {
  imgRoot: string;
}

const FooterCertificate = (props: Props) => {
  const { imgRoot } = props;

  return (
    <div className="FooterCertificate">
      <Image
        src={`${imgRoot}/haccp.png`}
        alt="Logo HACCP"
        data-marker="Logo HACCP"
        width={80}
        height={80}
      />
      <style jsx>{styles}</style>
    </div>
  );
};

export default FooterCertificate;
