import { useDispatch } from "react-redux";
import { sendOpenRecommendationsClick } from "src/components/containers/EmptyCart/helpers/analytics";
import { useAppSelector } from "src/redux/hooks";
import { openModal } from "src/redux/modals";
import useLocalization from 'src/services/localization/useLocalization';

export interface PageButton {
  linkProps?: {
    href: string;
    as: string;
    dataMarker?: string;
  },
  buttonProps?: {
    onClick(): void;
  },
  label: string;
  iconName: 'heart-full' | 'magic';
  dataMarker?: string;
}

const usePageButtons = () => {
  const localize = useLocalization();
  const language = useAppSelector(s => s.app.language);
  const dispatch = useDispatch();

  const openRecommendations = () => {
    dispatch(openModal('recommendationsModal'));
    sendOpenRecommendationsClick();
  };

  const pageButtons: PageButton[] = [
    {
      linkProps: {
        href: '/[lang]/lists',
        as:  `/${language}/lists`,
      },
      label: localize('lists.title-2'),
      iconName: 'heart-full',
      dataMarker: 'Lists',
    },
    {
      buttonProps: {
        onClick: openRecommendations,
      },
      dataMarker: 'Recommendations button',
      label: localize('my_recommendations'),
      iconName: 'magic',
    },
  ];

  return pageButtons;
};

export default usePageButtons;
