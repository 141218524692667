import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectCartItems } from "src/redux/cart/general/cartSelectors";
import { useAppSelector } from "src/redux/hooks";
import { openModal } from "src/redux/modals";
import { getEmptyCartWasDisplayed, setEmptyCartWasDisplayed } from "./helpers";

const EmptyCartWatcher = () => {
  const dispatch = useDispatch();
  const cartType = useAppSelector(s => s.app.cartType);
  const cartTypeIsServer = cartType === 'server';
  const userHasProductsInCart = useAppSelector(selectCartItems).length > 0;
  const emptyCartWasDisplayed = getEmptyCartWasDisplayed();
  const cartModalIsOpened = useAppSelector(s => s.modals.cartModal.isOpen);
  const recommendationsModalIsOpened = useAppSelector(s => s.modals.recommendationsModal.isOpen);

  const modalShouldBeDisplayed = !userHasProductsInCart
    && !emptyCartWasDisplayed
    && !cartModalIsOpened
    && cartTypeIsServer
    && !recommendationsModalIsOpened;

  useEffect(() => {
    if (modalShouldBeDisplayed) {
      dispatch(openModal('cartModal'));
      setEmptyCartWasDisplayed();
    }

  }, [modalShouldBeDisplayed, dispatch]);

  return null;
};

export default EmptyCartWatcher;
