import css from 'styled-jsx/css';

export const styles = css`
    .Divider {
      border: 0;
      margin: 0;
      padding: 0;
      height: 1px;
      background: var(--colors-sensitivePlatinum);
    }
  `;
