import { tablet_desktop } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .PageButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
  }
  :global(.PageButtons__item a) {
    text-decoration: none;
  }
  .PageButtons__itemWrapper {
    ${typography.btn}
    color: var(--colors-white);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 13px;
    height: 18px;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: var(--border-radius-roundBox);
    background-color: transparent;
  }
  .PageButtons__icon {
    font-size: 18px;
  }
  .PageButtons__label {
    margin-left: 8px;
  }

  @media ${tablet_desktop} {
    .PageButtons__itemWrapper {
      height: 40px;
      transition: 0.2s background-color ease-in-out;
      padding: 0 15px;
    }
    .PageButtons__itemWrapper:hover {
      background-color: var(--uiColors-CategoriesMenu-toggleBackground);
    }
  }
`;
