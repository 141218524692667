import useLocalization from 'src/services/localization/useLocalization';
import { Language } from 'src/utils/appConfig/getInitialAppConfig';
import { styles } from './ChooseLanguage.styles';
import useChooseLanguageProps from './useChooseLanguageProps';

const ChooseLanguage = () => {
  const localize = useLocalization();
  const { languages, getUrl, getClickHandler, currentLanguage } = useChooseLanguageProps();

  return (
    <div className='ChooseLanguage' data-marker='Choose language'>
      {languages.map((lang: Language) => {
        const url = getUrl(lang);
        const dataMarker = `Language ${lang}`;
        const languageText = localize(`general.lang.${lang}`);

        if (lang === currentLanguage) {
          return (
            <span
              key={lang}
              className='ChooseLanguage__language ChooseLanguage__language_active'
              data-marker={dataMarker}
            >
              {languageText}
            </span>
          );
        }

        return (
          <a
            key={lang}
            href={url}
            onClick={getClickHandler(url, lang)}
            className='ChooseLanguage__language'
            data-marker={dataMarker}
          >
            {languageText}
          </a>
        );
      })}

      <style jsx>{styles}</style>
    </div>
  );
};

export default ChooseLanguage;
