import css from 'styled-jsx/css';
import { desktop, phone, phone_portrait, sm, tablet } from '../../../styles/media';

export const styles = css`
  .ProductsBox__title {
    margin: 0 0 20px;
    font-size: 36px;
    font-weight: 600;
  }
  .ProductsBox__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  .ProductsBox__listItem {
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: var(--colors-brandLightGrey1);
  }
  .ProductsBox__listItem:hover {
    z-index: 2;
  }
  .ProductsBox__listItem {
    height: 360px;
  }
  @media ${desktop} {
    .ProductsBox__listItem {
      width: 20%;
    }
    .ProductsBox__listItem:nth-child(-n + 5) {
      border-top-width: 1px
    }
    .ProductsBox__listItem:nth-child(5n + 1) {
      border-left-width: 1px
    }
  }
  @media ${sm} {
    .ProductsBox__listItem {
      width: 25%;
    }
    .ProductsBox__listItem:nth-child(-n + 5),
    .ProductsBox__listItem:nth-child(5n + 1) {
      border-top-width: 0;
      border-left: 0;
    }
    .ProductsBox__listItem:nth-child(-n + 4) {
      border-top-width: 1px
    }
    .ProductsBox__listItem:nth-child(4n + 1) {
      border-left-width: 1px
    }
  }
  @media ${tablet} {
    .ProductsBox__listItem {
      width: 25%;
    }
    .ProductsBox__listItem:nth-child(-n + 4) {
      border-top-width: 1px
    }
    .ProductsBox__listItem:nth-child(4n + 1) {
      border-left-width: 1px
    }
  }
  @media ${phone} {
    .ProductsBox__listItem {
      width: calc(100% / 3);
      height: 330px;
    }
    .ProductsBox__listItem:nth-child(-n + 3) {
      border-top-width: 1px
    }
    .ProductsBox__listItem:nth-child(3n + 1) {
      border-left-width: 1px
    }
    .ProductsBox {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
  @media ${phone_portrait} {
    .ProductsBox__listItem {
      width: 50%;
    }
    .ProductsBox__listItem:nth-child(-n + 3),
    .ProductsBox__listItem:nth-child(3n + 1) {
      border-top-width: 0;
      border-left: 0;
    }
    .ProductsBox__listItem:nth-child(-n + 2) {
      border-top-width: 1px
    }
    .ProductsBox__listItem:nth-child(2n + 1) {
      border-left-width: 1px
    }
  }
  `;
