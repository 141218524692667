interface SendSubcategoryClickParams {
  place: 'category_tags' | 'category_bread_crumbs' | 'category_tiles' | 'category_list';
  page: string;
  categoryId: string;
  sliderIndex?: number;
}

export const sendSubcategoryClick = ({
  place,
  page,
  categoryId,
  sliderIndex,
}: SendSubcategoryClickParams) => {
  window.dataLayer.push({
    event: "category_click",
    place,
    page,
    category_id: categoryId,
    ...(sliderIndex && { slider_index: sliderIndex }),
  });
};
