import css from 'styled-jsx/css';

export const styles = css`
  .CategoriesMenuListItem {
    list-style: none;
  }
  .CategoriesMenuListItem__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 9px 28px 9px 18px;
    min-height: 36px;
    text-decoration: none;
    transition: 0.2s background-color ease-in-out;
  }
  .CategoriesMenuListItem__link:after {
    color: var(--colors-brandLightGrey7);
  }
  .CategoriesMenuListItem__link_withChildren:after {
    content: "${'\\e901'}";
    font-family: 'icons';
    position: absolute;
    top: 50%;
    right: 11px;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: top;
    font-size: 8px;
    transition: 0.2s color ease-in-out;
  }
  .CategoriesMenuListItem__link_active,
  .CategoriesMenuListItem__link:hover {
    background-color: var(--colors-lightGrey1);
  }
  .CategoriesMenuListItem__link_active:after,
  .CategoriesMenuListItem__link:hover:after {
    color: var(--colors-site);
  }
  .CategoriesMenuListItem__icon {
    flex-shrink: 0;
    display: flex;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
  }

  .CategoriesMenuListItem__icon svg {
    display: block;
    width: 100%;
  }
  .CategoriesMenuListItem__icon path {
    transition: 0.2s fill ease-in-out;
  }
  .CategoriesMenuListItem__title {
    color: var(--colors-site);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CategoriesMenuListItem__title:first-letter {
    text-transform: uppercase;
  }
  `;
