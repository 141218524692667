import { useRouter } from "next/router";
import { RangeNumbers } from "src/components/ui-kit/SliderField/SliderField";
import { RangeFilter } from "src/redux/apiTypes";
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import getFilterNamesWithCurrency from "src/utils/filter/getFilterNamesWithCurrency";
import getParsedFilterPriceValue from 'src/utils/filter/getParsedFilterPriceValue';
import setFilterPriceValueToUrl from "src/utils/filter/setFilterPriceValueToUrl";
import CollapsePanel from "../CollapsePanel/CollapsePanel";
import FilterPrice from "../FilterPrice/FilterPrice";

export interface FilterPriceWrapProps {
  filter: RangeFilter;
  excludeParams?: string[];
  isMobile?: boolean;
}

const FilterPriceWrap = (props: FilterPriceWrapProps) => {
  const { filter, excludeParams = [], isMobile } = props;

  const { currency } = useAppConfig();

  const localize = useLocalization();
  const router = useRouter();

  const handleFilterPrice = (filterValue: RangeNumbers) => {
    setFilterPriceValueToUrl(filterValue, router, excludeParams);
  };

  const filterPriceValue = router.query.price;
  const min = Math.floor(filter.min / 100) * 100;
  const max = Math.ceil(filter.max / 100) * 100;
  const [minValue, maxValue] = filterPriceValue
    ? getParsedFilterPriceValue(filterPriceValue)
    : [min, max];
  const resultMin = minValue < min ? minValue : min;
  const resultMax = maxValue > max ? maxValue : max;

  const filterName = getFilterNamesWithCurrency(filter.name, currency, localize);

  return (
    <div key={filter.key}>
      {isMobile
        ? <FilterPrice
            min={resultMin}
            max={resultMax}
            minValue={minValue}
            maxValue={maxValue}
            onFilter={handleFilterPrice}
          />
        : <CollapsePanel
            label={filterName}
            isCollapse={true}
          >
            <FilterPrice
              min={resultMin}
              max={resultMax}
              minValue={minValue}
              maxValue={maxValue}
              onFilter={handleFilterPrice}
            />
          </CollapsePanel>
      }
    </div>
  );
};

export default FilterPriceWrap;
