import css from 'styled-jsx/css';
import { desktop, lg, phone, tablet } from '../../../styles/media';

export const styles = css`
  .MainSlider {
    position: relative;
  }
  .MainSlider__imgWrapper {
    position: relative;
    display: block;
    padding-bottom: 31%;
  }
  .MainSlider__categories .MainSlider__imgWrapper {
    padding-bottom: 21.5%
  }

  @media ${desktop} {
    .MainSlider {
      height: calc((100vw - 260px) * 0.31);
      margin-top: 16px;
      overflow: hidden;
      margin-bottom: 20px;
    }
    .MainSlider__categories {
      height: calc((100vw - 260px) * 0.215);
      overflow: hidden;
    }
  }
  @media ${lg} {
    .MainSlider {
      height: 342px;
    }
    .MainSlider__categories {
      height: 240px;
    }
  }
  @media ${tablet} {
    .MainSlider {
      height: 36vw;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 16px;
    }
    .MainSlider__categories {
      height: 21vw;
      margin: 0;
    }
  }
  @media ${phone} {
    .MainSlider {
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 13px;
    }
    .MainSlider__categories {
      height: 44vw;
      margin: 0 0 16px;
    }
    .MainSlider__imgWrapper {
      padding-bottom: 60%;
    }
    .MainSlider__categories .MainSlider__imgWrapper {
      padding-bottom: 43.73%
    }
  }
  .MainSlider_emptyPlaceHolder {
    margin-top: 20px;
  }
  .MainSlider__placeholder {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--colors-lightGrey1);
    z-index: 3;
  }
  .MainSlider__wrapper {
    height: auto
  }
  .MainSlider__slide {
    line-height: 0;
    width: 100%;
    flex-shrink: 0;
    cursor: pointer;
  }
  `;
