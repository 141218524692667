import cn from 'classnames';
import * as React from 'react';
import { styles } from './MobileMenuIcon.styles';

interface Props {
  isActive: boolean
}

const MobileMenuIcon: React.FC<Props> = props => {
  const { isActive } = props;
  return (
    <div className={cn(
      'MobileMenuIcon__iconContainer', {
      'MobileMenuIcon__iconContainer_active': isActive,
    })}>
      <span></span>
      <span></span>
      <span></span>
      <style jsx>{styles}</style>
    </div>
  );
};

export default MobileMenuIcon;
