import { MutableRefObject, useState } from 'react';
import useResizeObserver from '../useResizeObserver/useResizeObserver';
import { useHeaderPromotionContext } from 'src/modules/HeaderPromotionLine';
import { useUserAgent } from 'src/modules/user-agent';
import { getDefaultBottomPosition } from './getDefaultBottomPosition';

const useHeaderBottomPosition = (headerElRef: MutableRefObject<HTMLElement>) => {
  const { promotionLineData, isClosed } = useHeaderPromotionContext();
  const { platform } = useUserAgent();
  const isMobile = platform === 'mobile';
  const withPromoLine = !!promotionLineData && !isClosed;
  const [headerBottomPosition, setHeaderBottomPosition] = useState(
    () => getDefaultBottomPosition({
      isMobile,
      withPromoLine,
    }));

  useResizeObserver(headerElRef, () => {
    requestAnimationFrame(() => {
      if (headerElRef.current) {
        setHeaderBottomPosition(
          headerElRef.current.getBoundingClientRect().bottom);
      }
    });
  });

  return {
    headerBottomPosition,
  };
};

export default useHeaderBottomPosition;
