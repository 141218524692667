import css from 'styled-jsx/css';

export const styles = css`
    .GoToTopButton {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 10px;
      margin-right: -10px;
      font-size: 18px;
      color: white;
    }
  `;
