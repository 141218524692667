import { phone } from "src/styles/media";
import typography from "src/styles/typography";
import css from "styled-jsx/css";

export const styles = css`
  .CategoriesTags {
    margin: 20px 0 32px;
  }
  .CategoriesTags__wrapper{
    margin-bottom: 12px;
  }
  .CategoriesTags__title {
    ${typography.h1}
    margin: 0;
  }
  .CategoriesTags__item {
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  @media ${phone} {
    .CategoriesTags {
      margin: 16px 0 12px;
    }
    .CategoriesTags__wrapper {
      display: flex;
      overflow: auto;
      margin-bottom: 20px;
    }
    .CategoriesTags__item {
      margin-bottom: 0;
      margin-right: 8px;
      flex-shrink: 0;
    }
    .CategoriesTags__title {
      font-size: 22px;
      line-height: normal;
    }
  }
`;
