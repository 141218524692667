import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from "swr";
import { ApiErrorShape, Filter, Product } from "../redux/apiTypes";
import getUserAgent from '../utils/api/getUserAgent';

interface Category {
  children: Category[]
  count: number
  id: string
  title: string
}

export interface SearchResponse {
  categories: Category[]
  category_results: string[]
  count: number
  filters: Filter[]
  results: Product[]
  errors?: ApiErrorShape[]
}

const emptyArray = [];

interface SearchParams {
  searchValue: string;
  storeId: string;
  deliveryType: string;
  perPage?: number;
  page?: number;
  sort_by?: string;
}

const fetcher = (url: Request | string, options?: RequestInit) => fetch(url, {
  ...options,
}).then(res => res.json());

const useSearch = (params: SearchParams) => {
  const {
    searchValue,
    storeId,
    deliveryType,
    perPage,
    page,
    sort_by,
  } = params;
  const q = encodeURIComponent(searchValue);

  const { fetchConfig, settings } = useAppConfig();
  const { MIN_SEARCH_LENGTH } = settings;
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const validSearchValue = searchValue.length >= MIN_SEARCH_LENGTH;
  const sortByQuery = sort_by ? `&sort=${sort_by}` : '';
  const pageQuery = page ? `&page=${page}` : '';
  const perPageQuery = perPage ? `&per_page=${perPage}` : '&per_page=10';

  const url = validSearchValue
    ? `${apiRoot}/stores/${storeId}/products/search/?q=${q}${perPageQuery}${pageQuery}${sortByQuery}`
    : null;

  const headers = {
    'Accept-Language': language,
    'x-chain': xChain,
    'x-delivery-type': deliveryType,
    'User-Agent': getUserAgent(),
    'x-version': xVersion,
  };

  const {
    data,
    error: swrError,
  } = useSWR(
    validSearchValue ? [url] : null,
    () => fetcher(url, { headers }),
  );

  const fetching = validSearchValue && !data && !swrError;
  const products = (data && data.results) || emptyArray;
  const count = data?.count || 0;
  const availableCount = data?.count_available || 0;
  const categories = (data && data.category_results) || emptyArray;
  const notFound = validSearchValue && data && !products.length && !categories.length;

  return {
    fetching,
    products,
    count,
    availableCount,
    categories,
    notFound,
  };
};

export default useSearch;
