import css from 'styled-jsx/css';

export const styles = css`
  .SocialNetworkLink {
    width: 40px;
    height: 40px;
    border: 1px solid var(--colors-brandLightGrey1);
    transition: 0.2s border-color ease-in-out;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SocialNetworkLink:hover {
    border-color: var(--colors-mainBrand);
  }

  .SocialNetworkLink,
  .SocialNetworkLink:hover,
  .SocialNetworkLink:visited {
    color: var(--colors-text);
    text-decoration: none;
  }

  .SocialNetworkLink_light {
    border-color: 'var(--colors-brandLightGrey7)';
  }

  .SocialNetworkLink_light:hover {
    border-color: var(--colors-white);
  }

  .SocialNetworkLink_light,
  .SocialNetworkLink_light:hover,
  .SocialNetworkLink_light:visited {
    color: var(--colors-white);
  }

  .SocialNetworkLink__icon {
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
  }
`;

