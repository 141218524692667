import { useAppConfig } from '../../../../utils/appConfig/useAppConfig';
import { socialNetworksMap } from '../../data';
import { SocialNetworksBlockProps } from '../../types';
import SocialNetworkLink from '../SocialNetworkLink/SocialNetworkLink';

export default function SocialNetworksBlock(props: SocialNetworksBlockProps) {
  const { theme, dataMarker } = props;
  const { country } = useAppConfig();

  return (
    <article className='SocialNetworksBlock' data-marker={dataMarker}>
      <ul className='SocialNetworkBlock__list'>
        {socialNetworksMap[country].map(n => (
          <li className='SocialNetworkBlock__item' key={n.href}>
            <SocialNetworkLink href={n.href} name={n.name} theme={theme} />
          </li>
        ))}
      </ul>

      <style jsx>{`
        .SocialNetworkBlock__list {
          list-style: none;
          display: flex;
        }

        .SocialNetworkBlock__item {
          margin-right: 16px;
        }
      `}</style>
    </article>
  );
}
