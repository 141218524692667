import { ApiErrorShape } from "src/redux/apiTypes";

const getSlotsErrorsFromServerCart = (
  warnings: ApiErrorShape[],
) => {
  const errors: ApiErrorShape[] = [];

  warnings.forEach((warning) => {
    const alcoholAndReadyMealWarningCodes = [4161, 4165];
    const segments = warning.field.split('.');
    const [firstSegment] = segments;

    // code 4010 - Missed required parameter.
    if (firstSegment === 'time_window_id' && warning.error_code !== 4010) {
      errors.push(warning);
    } else if (alcoholAndReadyMealWarningCodes.includes(warning.error_code)) {
      errors.push(warning);
    }
  });

  return errors;
};

export default getSlotsErrorsFromServerCart;
