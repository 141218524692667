/**
 * needs to decrease CLS value
 * for google page speed insights
 */

export const headerDefaultHeightsMap = {
  general: 112,
  mobile: 80,
};
export const headerWithPromoHeightsMap = {
  general: 144,
  mobile: 128,
};

export const getDefaultBottomPosition = ({ withPromoLine, isMobile }) => {
  const mapKey = isMobile ? 'mobile' : 'general';
  const initialHeaderHeight = withPromoLine
    ? headerWithPromoHeightsMap[mapKey]
    : headerDefaultHeightsMap[mapKey];

  return initialHeaderHeight;
};
