import css from 'styled-jsx/css';
import { desktop } from '../../../styles/media';

export const styles = css`
  .FilterableLayout {
    padding-top: 32px;
  }
  .FilterableLayout__productsCount {
    font-size: 14px;
    line-height: 29px;
    color: var(--colors-doveGray);
  }

  .FilterableLayout__sortingWrapper {
    margin-bottom: 25px;
  }

  @media ${desktop} {
    .FilterableLayout__sortingWrapper {
      display: flex;
      justify-content: space-between;
      height: 21px;
    }
  }
`;
