import useSearchExperiment from "src/components/containers/SearchBox/useSearchExperiment";
import { useLocalStorage } from "usehooks-ts";

const useSearchHistory = () => {
  const searchVariant = useSearchExperiment();

  const MAX_HISTORY_LENGTH = searchVariant ? 8 : 12;

  const [history, setHistory] = useLocalStorage<string[]>('searchHistory', []);

  const removeTextFromCurrentHistory = (text: string) => history.filter(i => i !== text);

  const addToHistory = (text: string) => {
    const filteredHistory = removeTextFromCurrentHistory(text);
    setHistory([text, ...filteredHistory].slice(0, MAX_HISTORY_LENGTH));
  };

  const removeFromHistory = (text: string) => {
    const filteredHistory = removeTextFromCurrentHistory(text);
    setHistory(filteredHistory);
  };

  const clearHistory = () => {
    setHistory([]);
  };

  return {
    clearHistory,
    addToHistory,
    removeFromHistory,
    history,
  };
};

export default useSearchHistory;
