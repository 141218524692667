import { phone } from "src/styles/media";
import typography from "src/styles/typography";
import css from "styled-jsx/css";

export const styles = css`
  .CategoryCard {
    display: flex;
    border-radius: var(--border-radius-block);
    background-color: var(--colors-white);
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 4px 12px;
  }
  .CategoryCard:hover {
    box-shadow: 0px 8px 15px 0px rgba(34, 36, 33, 0.08);
  }
  .CategoryCard__title {
    ${typography.h6}
    line-height: 20px;
    flex: 1 1 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CategoryCard__imgWrapper {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    margin-left: 8px;
  }

  @media ${phone} {
    .CategoryCard {
      display: block;
      -webkit-line-clamp: 3;
      padding: 8px;
    }

    .CategoryCard__title {
      font-size: 12px;
      line-height: 1.33;
      height: 48px;
      margin-bottom: 18px;
      -webkit-line-clamp: 3;
      word-break: break-word;
    }
    .CategoryCard__imgWrapper {
      width: 64px;
      height: 64px;
    }
`;
