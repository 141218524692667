import { phone } from 'src/styles/media';
import css from 'styled-jsx/css';

export const styles = css`
  .HeaderTopLine {
    height: 48px;
    background-color: var(--colors-white);
  }
  .HeaderTopLine__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1366px;
    padding: 0 10px;
    margin: 0 auto;
  }
  .HeaderTopLine__actionBox,
  .HeaderTopLine__left,
  .HeaderTopLine__right {
    display: flex;
    align-items: center;
  }
  .HeaderTopLine__separator {
    width: 16px;
  }
  .HeaderTopLine__logoSeparator {
    width: 12px;
  }

  @media ${phone} {
    .HeaderTopLine {
      height: 40px;
    }
    .HeaderTopLine__container {
      padding: 0 12px;
    }
  }
`;
