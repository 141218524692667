import { UniversalObject } from "globals";

const getQueryWithMergedFilterParams = (query: UniversalObject) => {
  const modifiedQuery = { ...query };
  const firstLevelFilter = modifiedQuery.firstLevelFilter;

  if(firstLevelFilter) {
    const [type, name] = firstLevelFilter.split('=');
    delete modifiedQuery.firstLevelFilter;

    if(!modifiedQuery[type]) {
      modifiedQuery[type] = name;
    } else {
      if(Array.isArray(modifiedQuery[type])) {
        modifiedQuery[type] = [name, ...modifiedQuery[type]];
      } else {
        modifiedQuery[type] = [name, modifiedQuery[type]];
      }
    }
  }

  return modifiedQuery;
};

export default getQueryWithMergedFilterParams;
