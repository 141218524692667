import css from 'styled-jsx/css';
import { phone_tablet } from '../../../styles/media';

export const styles = css`
  .MainSliderControls__pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8px;
    display: flex;
    align-items: center;
    height: 20px;
    background: rgba(255, 255, 255, 0.44);
    border-radius: 24px;
    padding: 0 8px;
  }
  :global(.MainSliderControls__bullet) {
    width: 5px;
    height: 5px;
    margin: 0 5px;
    border-radius: 50%;
    transition: 0.2s background-color ease-in-out;
    background-color: none;
    border: 1px solid var(--uiColors-MainSlider-bulletBorder);
    cursor: pointer;
  }
  .MainSliderControls__control {
    position: absolute;
    font-size: 8px;
    transition: 0.2s opacity ease-in-out;
    color: var(--colors-site);
    display: flex;
    height: 100%;
    top: 0;
    width: 42px;
    justify-content: center;
    align-items: center;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
  }
  .MainSliderControls__control_prev {
    left: 0;
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.04) 7.78%, #FFFFFF 100%);
  }
  .MainSliderControls__control_next {
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.04) 7.78%, #FFFFFF 100%);
  }
  .MainSliderControls__control_prev:hover,
  .MainSliderControls__control_next:hover {
    cursor: pointer;
  }
  :global(.MainSliderControls__bullet_active) {
    background-color: var(--uiColors-MainSlider-buttonBackground);
  }
  .MainSliderControls {
    left: 0;
    top: 0;
    display: flex;
    z-index: 2;
    align-items: flex-end;
    justify-content: space-between;
  }

  .MainSliderControls_showNav .MainSliderControls__control {
    opacity: 1;
    pointer-events: auto;
  }

  @media ${phone_tablet} {
    .MainSliderControls {
      position: relative;
      justify-content: space-between;
      align-items: center;
      border-left: none;
      border-right: none;
      background: #fff;
    }

    .MainSliderControls__control {
      position: relative;
      font-size: 12px;
      width: 45px;
      height: 45px;
      background: none;
      opacity: 1;
      pointer-events: auto;
    }

    .MainSliderControls__pagination {
      position: relative;
      background: none;
      margin: 0;
      left: 0;
      bottom: 0;
      transform: translateX(0);
    }
  }
  `;
