import { desktop, phone, tablet } from "src/styles/media";
import css from "styled-jsx/css";
import getSliderItemCssWidth from "../../helpers/getSliderItemCssWidth";
import typography from "src/styles/typography";
import { itemsOnDesktop, itemsOnTablet } from "../../helpers/getCategoriesInSlideCount";

const marginBetweenItems = '16px';

export const styles = css`
  .CategoriesMainSlider {
    position: relative;
    margin: 0 0 60px;
  }
  .CategoriesMainSlider__slide {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
  }

  .CategoriesMainSlider__placeholderTitle {
    ${typography.h2}
    margin: 7px 0 5px;
    padding: 0;
    height: 42px;
    text-align: start;
  }

  .CategoriesMainSlider__item_placeholder{
    height: 96px;
  }

  @media ${desktop} {
    .CategoriesMainSlider__item {
      width: ${getSliderItemCssWidth(marginBetweenItems, itemsOnDesktop)};
      margin-bottom: ${marginBetweenItems};
      margin-right: ${marginBetweenItems};
    }

    .CategoriesMainSlider__item:nth-child(${itemsOnDesktop}n){
      margin-right: 0;
    }
  }

  @media ${tablet} {
    .CategoriesMainSlider__item {
      width: ${getSliderItemCssWidth(marginBetweenItems, itemsOnTablet)};
      margin-bottom: ${marginBetweenItems};
      margin-right: ${marginBetweenItems};
    }
    .CategoriesMainSlider__item:nth-child(${itemsOnTablet}n){
      margin-right: 0;
    }

    .CategoriesMainSlider__item {
      margin-right: ${marginBetweenItems};
    }
    .CategoriesMainSlider__item:last-child {
      margin-right: 0;
    }
  }

  @media ${phone} {
    .CategoriesMainSlider__slide {
      flex-wrap: nowrap;
      width: auto;
    }

    .CategoriesMainSlider__slide_placeholder {
      overflow: hidden;
    }

    .CategoriesMainSlider__item {
      margin-right: 12px;
    }

    .CategoriesMainSlider__item_placeholder {
      min-width: 91px;
      height: 155px;
    }
  }
`;
