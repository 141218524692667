import useContactsButtons from "src/hooks/useContactsButtons/useContactsButtons";
import { Messengers } from "src/modules/messengers";
import useLocalization from "src/services/localization/useLocalization";
import { styles } from './FooterActions.styles';
import FooterLink from "./FooterLink";

const FooterActions = () => {
  const localize = useLocalization();
  const buttons = useContactsButtons();

  return (
    <div className="FooterActions">
      <div className="FooterActions__label">
        {localize('contact_us')}
      </div>

      <Messengers variant='line' />

      {buttons.length > 0 && (
        <div className="FooterActions__buttons">
          {buttons.map((link, i) => (
            <div className='FooterActions__buttonItem' key={i}>
              <FooterLink
                href={link.href}
                target='_blank'
                rel='noreferrer nofollow'
                title={link.title}
              />
            </div>
          ))}
        </div>
      )}

      <style jsx>{styles}</style>
    </div>
  );
};

export default FooterActions;
