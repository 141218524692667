import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .FooterAppsLinks {
      width: 280px;
      margin-left: -5px;
      margin-right: -5px;
      margin-bottom: 35px;
    }
    .FooterAppsLinks__title {
      ${typography.h3};
      color: #ffffff;
      display: inline-block;
      padding-left: 5px;
      margin-bottom: 11px;
    }
    .FooterAppsLinks__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }
    .FooterAppsLinks__item {
      width: 130px;
      height: 40px;
      margin: 5px;
    }
    .FooterAppsLinks__link {
      width: 100%;
      height: 100%;
      display: block;
      transition: 0.2s opacity ease-in-out;
    }
    .FooterAppsLinks__link:hover {
      opacity: 0.6;
    }
  `;
