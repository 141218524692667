import cn from 'classnames';
import { Fragment, ReactNode } from 'react';
import { styles } from './PageWrap.styles';

export interface Props {
  children: ReactNode;
  opaqueBackground?: boolean;
}

export default function PageWrap(props: Props) {
  return (
    <Fragment>
      <div
        className={cn(
          'PageWrap', {
          'PageWrap_opaqueBackground': props.opaqueBackground,
      })}>
        {props.children}
      </div>
      <style jsx>{styles}</style>
    </Fragment>
  );
}
