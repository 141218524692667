import css from 'styled-jsx/css';
import { desktop } from '../../../../styles/media';
import typography from '../../../../styles/typography';

export const styles = css`
    .CheckboxOptionAmounted {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-size: 13px;
    }
    .CheckboxOptionAmounted__amount {
      color: var(--colors-doveGray);
    }
    @media ${desktop} {
      .CheckboxOptionAmounted__amount {
        ${typography.h9}
        color: var(--colors-doveGray);
      }
      .CheckboxOptionAmounted__text {
        ${typography.text}
      }
    }
  `;
