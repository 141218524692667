import classNames from "classnames";
import { SocialNetwork } from "../../types";
import { styles } from './SocialNetworkLink.styles';

interface SocialNetworkProps extends SocialNetwork {
  theme?: 'dark' | 'light',
}

export default function SocialNetworkLink(props: SocialNetworkProps) {
  const { name, href, theme = 'dark' } = props;

  return (
    <a
      className={classNames(
        'SocialNetworkLink',
        { SocialNetworkLink_light: theme === 'light' },
      )}
      href={href}
      rel='nofollow noopener noreferrer'
      target='_blank'
      data-marker={`SocialNetworkLink ${name}`}
      aria-label={name}
    >
      <span className={`SocialNetworkLink__icon icon-${name}`} />

      <style jsx>{styles}</style>
    </a>
  );
}
