import cn from 'classnames';
import { useState } from 'react';
import { styles } from './ContentSorterDropdown.styles';

export interface SortItem {
  value: string;
  label: string;
}

interface Props {
  label: string;
  filters: SortItem[];
  active: SortItem;
  onChange: (item: SortItem) => void;
  withLeftAlign?: boolean;
  dataMarker?: string;
}

const ContentSorterDropdown = (props: Props) => {
  const { filters, active, onChange, label, withLeftAlign, dataMarker = 'ContentSorterDropdown'} = props;

  const [expanded, setExpanded] = useState(false);

  const close = () => setExpanded(false);
  const open = () => setExpanded(true);

  const getHandleChange = (option: SortItem) => () => {
    close();
    onChange(option);
  };

  return (
    <div
      className="ContentSorterDropdown"
      tabIndex={0}
      onBlur={close}
      data-marker={`${dataMarker} Box`}
    >
      <div className="ContentSorterDropdown__line">
        <div className="ContentSorterDropdown__label">{label}</div>
        <div
          className="ContentSorterDropdown__selected"
          onClick={open}
          data-marker={`${dataMarker} Button`}
          data-testid="sort-button"
        >
          <span
            className="ContentSorterDropdown__selectedLabel"
          >
            {active.label}
          </span>
          <span
            className={cn('ContentSorterDropdown__caret', {
              'icon-caret-top': expanded,
              'icon-caret-bottom': !expanded,
            })}
          />
        </div>
      </div>
      <div
        className={cn('ContentSorterDropdown__list', {
          ContentSorterDropdown__listExpanded: expanded,
          ContentSorterDropdown__list_withLeftAlign: withLeftAlign,
        })}
        data-testid="sort-dropdown"
        data-marker={`${dataMarker} List`}
      >
        {filters.map((filter) => {
          const itemIsActive = active.value === filter.value;
          const itemStatus = itemIsActive ? 'active' : 'inactive';

          return (
            <div
              className={cn('ContentSorterDropdown__item', {
                ContentSorterDropdown__item_active: itemIsActive,
              })}
              onClick={getHandleChange(filter)}
              key={filter.value}
              data-status={itemStatus}
              data-marker={`${dataMarker} ${filter.value}`}
              data-testid={filter.value}
            >
              <span className="ContentSorterDropdown__itemLabel">
                {filter.label}
              </span>
              {itemIsActive && (
                <span className="ContentSorterDropdown__itemIcon icon-check" />
              )}
            </div>
          );
        })}
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ContentSorterDropdown;
