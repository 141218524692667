import css from 'styled-jsx/css';
import { phone, tablet } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
    .CatalogFiltersMobileOptions__header {
      display: flex;
      height: 50px;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--colors-brandLightGrey1);
    }
    .CatalogFiltersMobileOptions__headerBack {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: transparent;
      border: none;
    }
    .CatalogFiltersMobileOptions__headerBackIcon {
      margin-right: 15px;
      font-size: 22px;
    }
    .CatalogFiltersMobileOptions__headerTitle {
      ${typography.h4}
    }
    .CatalogFiltersMobileOptions__headerButton {
      flex-shrink: 0;
      font-size: 13px;
      color: var(--colors-darkGrey);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-color: transparent;
    }
    .CatalogFiltersMobileOptions__content {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    @media ${phone} {
      .CatalogFiltersMobileOptions__headerTitle {
        ${typography.h3}
      }
      .CatalogFiltersMobileOptions__options {
        padding: 10px 0 10px 0;
      }
      .CatalogFiltersMobileOptions__header {
        padding: 0 10px;
        font-size: 15px;
        height: 48px;
      }
    }
    @media ${tablet} {
      .CatalogFiltersMobileOptions__headerTitle {
        ${typography.h2}
      }
      .CatalogFiltersMobileOptions__header {
        padding: 0 24px;
        height: 64px;
      }
      .CatalogFiltersMobileOptions__options {
        padding: 10px 0 10px 0;
      }
    }
  `;
