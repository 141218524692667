import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import { Language } from 'src/utils/appConfig/getInitialAppConfig';
import useChooseLanguageProps from '../ChooseLanguage/useChooseLanguageProps';
import { styles } from './DropdownChooseLanguage.styles';

const DropdownChooseLanguage = () => {
  const localize = useLocalization();
  const { languages, getUrl, getClickHandler, currentLanguage } = useChooseLanguageProps();
  const parentRef = useRef(null);

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const toggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);

  useEffect(() => {
    const handleMenuOutClick = (e: MouseEvent) => {
      const parentCurrent = parentRef.current;
      if (dropdownIsOpen && parentCurrent && !parentCurrent.contains(e.target)) {
        setDropdownIsOpen(false);
      }
    };

    window.addEventListener('click', handleMenuOutClick);
    return () => { window.removeEventListener('click', handleMenuOutClick); };
  }, [dropdownIsOpen]);

  return (
    <div className='DropdownChooseLanguage' ref={parentRef}>
      <button
        className='DropdownChooseLanguage__button'
        onClick={toggleDropdown}
        data-marker='Choose language'
        aria-haspopup="true"
        aria-controls="menu"
        id="choose-language"
      >
        {localize(`general.lang.${currentLanguage}`)}
        <span className={cn('DropdownChooseLanguage__icon',
          {
            'icon-caret-bottom': !dropdownIsOpen,
            'DropdownChooseLanguage__icon_open icon-caret-top': dropdownIsOpen,
          })}
        />
      </button>

      {dropdownIsOpen && (
        <div
          className='DropdownChooseLanguage__dropdown'
          data-marker="Language dropdown"
          role="menu"
          aria-labelledby="choose-language"
        >
          {languages.map((lang: Language) => {
            const url = getUrl(lang);
            return (
              <a
                key={lang}
                href={url}
                onClick={getClickHandler(url, lang)}
                className='DropdownChooseLanguage__dropdownItem'
                data-marker={`Language ${lang}`}
                role="menuitem"
              >
                {localize(`general.lang.${lang}`)}
              </a>
            );
          })}
        </div>
      )}

      <style jsx>{styles}</style>
    </div>
  );
};

export default DropdownChooseLanguage;
