import typography from "src/styles/typography";
import css from "styled-jsx/css";

export const styles = css`
  .SeeMoreCard {
    ${typography.h9}
    color: var(--colors-linkAccented);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: var(--colors-white);
    text-align: center;
  }
  .SeeMoreCard__icon {
    font-size: 8px;
    margin-bottom: 8px;
  }
`;
