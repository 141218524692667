import css from 'styled-jsx/css';
import { phone, phone_tablet, tablet, tablet_desktop } from '../../../styles/media';
import typography from '../../../styles/typography';
import { headerZIndex } from '../Header/headerZIndex';

export const styles = css`
    .SearchBox {
      position: relative;
      display: block;
      width: 100%;
      z-index: ${headerZIndex.indexOf('searchBox')};
    }

    .SearchBox__overlay {
      position: fixed;
      z-index: 3;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--colors-nightRider);
      opacity: 0.45;
    }

    .SearchBox__back {
      position: absolute;
      left: 0;
      top: 0;
      height: 48px;
      width: 34px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      z-index: 5;
      color: var(--colors-text);
      font-size: 16px;
    }

    .SearchBox__icons {
      display: flex;
      position: absolute;
      top: 10px;
      right: 16px;
      z-index: 3;
    }

    .SearchBox__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      color: var(--colors-brandLightGrey8);
      cursor: pointer;
    }

    .SearchBox__icon:hover {
      color: var(--colors-text);
    }

    .SearchBox__clearSearch {
      margin-right: 10px;
      font-size: 13px;
    }

    .SearchBox__loupeIcon {
      line-height: 1;
      text-decoration: none;
      outline: none;
      font-size: 15px;
    }

    .SearchBox__loupeIcon_active {
      cursor: pointer;
    }

    :global(.SearchBox__loupeIcon_active:hover) {
      color: var(--uiColors-Button-notAccentedHoverText);
    }

    .SearchBox__resultsContainer {
      position: absolute;
      z-index: 4;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      max-height: calc(100vh - 168px);
      border-radius: var(--border-radius-block);
      overflow: auto;
    }

    .SearchBox__input {
      ${typography.h5}
      display: block;
      width: 100%;
      outline: 0 none;
      -webkit-appearance: none;
      height: 40px;
      padding: 10px 20px;
      border-radius: var(--border-radius-roundBox);
      border: none;
      padding-right: 80px;
      text-overflow: ellipsis;
      border: 1px solid var(--colors-brandLightGrey2);
    }

    .SearchBox__input::placeholder {
      color: var(--colors-brandLightGrey8);
    }

      /* clears the 'X' from Internet Explorer */
    .SearchBox__input::-ms-clear {  display: none; width : 0; height: 0; }
    .SearchBox__input::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    .SearchBox__input::-webkit-search-decoration,
    .SearchBox__input::-webkit-search-cancel-button,
    .SearchBox__input::-webkit-search-results-button,
    .SearchBox__input::-webkit-search-results-decoration { display: none; }

    .SearchBox__spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .SearchBox__notFound {
      height: 225px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      text-align: center;
    }

    .SearchBox__notFoundImage {
      width: 150px;
      height: 67px;
    }

    .SearchBox__notFoundMessage {
      ${typography.text}
      margin-top: 25px;
    }

    @media ${tablet_desktop} {
      .SearchBox__resultsContainer {
        width: 650px;
        margin-top: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
      }
      .SearchBox__back {
        display: none;
      }
    }

    @media ${tablet} {
      .SearchBox__resultsContainer {
        width: 650px;
        position: fixed;
        left: calc((100vw - 650px) / 2);
      }
      .SearchBox__input {
        border: 1px solid var(--colors-brandLightGrey1);
        border-radius: var(--border-radius-roundBox);
        padding-left: 20px;
        padding-right: 45px;
      }
      .SearchBox__input:focus {
        border-color: var(--uiColors-globals-formControlHintText);
      }
    }

    @media ${phone_tablet} {
      .SearchBox__input {
        padding-left: 20px;
      }

      .SearchBox__resultsContainer {
        max-height: calc(100vh - 80px);
        -webkit-overflow-scrolling: touch;
      }
    }

    @media ${phone} {
      .SearchBox {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .SearchBox__overlay {
        position: absolute;
        /* the top position is set dynamically in the component */
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
        background: var(--colors-white);
        opacity: 1;
      }
      .SearchBox__input {
        position: relative;
        height: 48px;
        padding-right: 45px;
        border: none;
        border-bottom: 1px solid var(--colors-brandLightGrey1);
        border-radius: 0;
        padding-left: 35px;
      }
      .SearchBox__icons {
        top: 14px;
      }
      .SearchBox__resultsContainer {
        position: absolute;
        top: 48px;
        left: 0;
        bottom: 0;
        right: 0;
        max-height: 100%;
      }
      .SearchBox__results {
        height: 100%;
      }
      .SearchBox__tabletDesktopOverlay {
        display: none;
      }
      .SearchBox_hidden {
        height: 0px;
        overflow: hidden;
      }
      .SearchBox__loupeIcon_hidden {
        display: none;
      }
    }
  `;
