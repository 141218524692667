import css from 'styled-jsx/css';
import { desktop, phone, phone_tablet, tablet } from '../../../styles/media';

export const checkListItemsStyles = css`
  .CheckList__items {
    list-style: none;
  }

  @media ${phone_tablet} {
    .CheckList__item {
      border-bottom: 1px solid var(--colors-brandLightGrey10);
    }
  }

  @media ${tablet} {
    .CheckList__emptyListMessage,
    .CheckList__item {
      padding: 0 24px;
    }
  }

  @media ${phone} {
    .CheckList__emptyListMessage,
    .CheckList__item {
      padding: 0 10px;
    }
  }

  @media ${desktop} {
    .CheckList__items {
      max-height: 310px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      margin-right: -16px;
      padding-right: 16px;
      margin-bottom: 4px;
    }
  }
  .CheckList__collapseButtonIcon {
    font-size: 7px;
    margin-left: 10px;
  }
`;

export const styles = css`
  .CheckList {
    padding-bottom: 10px;
  }
  .CheckList_loading {
    position: relative;
  }
  .CheckList_loading:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(255,255,255,0.3);
  }
  .CheckList__search {
    position: relative;
  }
  .CheckList__searchField {
    padding: 8px 40px 8px 16px;
    text-transform: capitalize;
  }
  .CheckList__searchField::placeholder {
    color: var(--colors-brandLightGrey8);
  }

  .CheckList__icon {
    align-items: center;
    background: none;
    border: 0 none;
    color: var(--colors-brandLightGrey8);
    cursor: pointer;
    display: flex;
    justify-content: center;
    outline: none;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 10px;
    transition: 0.2s color ease-in-out;
    user-select: none;
    width: 20px;
    height: 20px;
  }

  .SearchBox__icon_search {
    font-size: 15px;
    pointer-events: none;
  }

  .CheckList__icon_clear {
    line-height: 1;
    text-decoration: none;
    outline: none;
    font-size: 13px;
  }

  .CheckList__icon_clear:hover {
    color: var(--colors-text);
  }
  .CheckList__emptyListMessage {
    font-size: 13px;
  }
  @media ${desktop} {
    .CheckList__search {
      margin-bottom: 15px;
    }
    .CheckList {
      padding-bottom: 14px;
    }
  }
  @media ${phone_tablet} {
    .CheckList__search {
      margin-bottom: 8px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }
  @media ${phone} {
    .CheckList__search {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  `;
