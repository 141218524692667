import { Fragment, useEffect, useState } from 'react';
import { styles } from './ScrollUpButton.styles';

interface Props {
  behavior?: ScrollBehavior;
}

const ScrollButton = (props: Props) =>{
  const [visible, setVisible] = useState(false);

  const handleOnPress = () => {
    const { behavior } = props;

    window.scrollTo({
      top: 0,
      behavior,
    });
  };

  useEffect(() => {
    let lastScrollTop = document.documentElement.scrollTop;

    const toggleVisible = () => {
      const windowHeight = window.innerHeight;
      const scrolled = document.documentElement.scrollTop;

      if (scrolled === 0) {
        setVisible(false);
      } else if (scrolled > windowHeight) {
        if (scrolled > lastScrollTop) {
          setVisible(false);
        } else {
          setVisible(true);
        }
      }

      lastScrollTop = scrolled;
    };

    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <Fragment>
      {visible && (
        <button
          className="ScrollUpButton"
          onClick={handleOnPress}
          data-marker="Scroll Up"
        >
          <span className="ScrollUpButton__icon icon-arrow-up"></span>
        </button>
      )}

      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default ScrollButton;
