import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
    .SearchResults__productsListTitle {
      ${typography.h6}
      display: block;
      margin-top: 16px;
      margin-left: 20px;
    }

    .SearchResults__viewAll {
      ${typography.h6}
      color: var(--uiColors-Button-accentedText);
      display: flex;
      height: 52px;
      width: 100%;
      border: none;
      outline: none;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: var(--colors-white);
      border-top: 1px solid var(--colors-brandLightGrey1);
      border-bottom: 1px solid var(--colors-brandLightGrey1);
      position: sticky;
      bottom: 0;
    }

    .SearchResults__viewAll:hover {
      color: var(--uiColors-Button-accentedHoverText);
    }

    :global(.SearchResults__categoriesListItem a) {
      ${typography.text}
    }

    .SearchResults__categoriesListItem {
      padding: 15px 10px;
      flex-shrink: 0;
      cursor: pointer;
      transition: none;
    }

    .SearchResults__categoriesListItem:first-of-type {
      padding-left: 20px;
    }

    .SearchResults__categoriesListItem:last-of-type {
      padding-right: 20px;
    }

    :global(.SearchResults__categoriesListItem:hover a),
    .SearchResults__categoriesListItem:hover {
      color: var(--uiColors-Button-notAccentedHoverText);
    }

    .SearchResults__separator {
      width: 100%;
      height: 4px;
      background: var(--colors-lightGrey1);
    }

    @media ${phone} {
      .SearchResults__productsListTitle {
        margin-left: 10px;
      }
      .SearchResults__categoriesListItem:first-of-type {
        padding-left: 10px;
      }

      .SearchResults__categoriesListItem:last-of-type {
        padding-right: 10px;
      }
    }

  `;
