import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
  .ProductTileMini {
    ${typography.h9}
    text-decoration: none;
    background-color: var(--colors-white);
    width: 33.33%;
    padding: 10px;
    cursor: pointer;
    min-height: 147px;
    position: relative;
  }
  .ProductTileMini__badges {
    position: absolute;
  }
  :global(.ProductTileMini__link) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .ProductTileMini_withBorderInSlider {
    border: 1px solid var(--colors-brandLightGrey1);
    border-left-width: 0;
  }
  .ProductTileMini_withBorderInSlider:nth-child(3n + 1) {
    border-left-width: 1px;
  }
  .ProductTileMini_withBorderInSlider:nth-child(n+4) {
    border-top-width: 0;
  }
  .ProductTileMini__productInfo {
    display: flex;
  }
  .ProductTileMini__imageWrapper {
    width: 50px;
    min-width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .ProductTileMini__image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
  .ProductTileMini__title {
    color: var(--colors-text);
    line-height: 1.3;
    margin: 2px 0;
    max-height: 47px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: -o-ellipsis-lastline;
  }
  .ProductTileMini__title:hover {
    color: var(--uiColors-ProductTile-titleHoverText);
  }
  .ProductTileMini__weight {
    color: var(--colors-darkenGrey1);
  }
  .ProductTileMini__footer {
    display: flex;
    justify-content: flex-end;
  }
  .ProductTileMini__add {
    position: relative;
  }
  `;
