import cn from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useStableFunction } from '../../../hooks/useStableFunction/useStableFunction';
import { styles } from './CategoriesMenuListContainer.styles';

interface Props {
  onMouseLeave: () => void;
  dataMarker: string;
  categoryId?: string;
  level?: 1 | 2 | 3;
  topPosition: number;
}

const CategoriesMenuListContainer: FC<Props> = props => {
  const {
    onMouseLeave,
    dataMarker,
    children,
    categoryId,
    level = 1,
    topPosition,
  } = props;
  const containerRef = useRef<HTMLUListElement>();
  const [showScrollButton, setShowScrollButton] = useState(true);

  const handleScrollStable = useStableFunction(() => {
    if (containerRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = containerRef.current;
      const isFullScroll = scrollHeight < scrollTop + offsetHeight + 5;

      if (showScrollButton && isFullScroll) {
        setShowScrollButton(false);
      } else if (!showScrollButton && !isFullScroll) {
        setShowScrollButton(true);
      }
    }
  });

  const handleClick = () => {
    if (containerRef.current) {
      const { offsetHeight, scrollTop } = containerRef.current;
      containerRef.current.scrollTop = scrollTop + offsetHeight;
    }
  };

  useEffect(() => {
    handleScrollStable();
  }, [categoryId, handleScrollStable]);

  return (
    <div
      className={cn({
        'CategoriesMenuListContainer__firstLevel': level === 1,
        'CategoriesMenuListContainer__secondLevel': level === 2,
        'CategoriesMenuListContainer__thirdLevel': level === 3,
      })}
    >
      <ul
        className={cn(
          'CategoriesMenuListContainer__list', {
          'CategoriesMenuListContainer__list_nested': level !== 1,
        })}
        data-testid={level === 1 ? 'categories_menu_nav' : 'categories_sub_menu'}
        data-marker={dataMarker}
        onMouseLeave={onMouseLeave}
        ref={containerRef}
        onScroll={handleScrollStable}
      >
        {children}
        {showScrollButton &&
          <div
            onClick={handleClick}
            className={cn(
              'CategoriesMenuListContainer__scrollButton', {
              'CategoriesMenuListContainer__scrollButton_nested': level !== 1,
            })}
            role="button"
            tabIndex={0}
            data-marker="Scroll_down"
          />
        }
      </ul>
      <style jsx>{styles}</style>
      <style jsx>{`
        .CategoriesMenuListContainer__firstLevel {
          top: ${topPosition + 16}px;
          height: calc(100vh - ${topPosition + 26}px);
        }
        .CategoriesMenuListContainer__list {
          top: ${topPosition + 16}px;
        }
        .CategoriesMenuListContainer__list_nested {
          max-height: calc(100vh - ${topPosition + 26}px);
        }
      `}</style>
    </div>
  );
};

export default CategoriesMenuListContainer;
