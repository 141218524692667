import Impressionable from "src/components/views/Impressionable/Impressionable";
import SliderWrapper from "src/components/views/SliderWrapper/SliderWrapper";
import useLocalization from 'src/services/localization/useLocalization';
import { sendSubcategoryClick } from "../../helpers/analytics";
import MainCategoryCard from "../MainCategoryCard/MainCategoryCard";
import { styles } from "./CategoriesMainSlider.styles";
import CategoriesMainSliderPlaceholder from "./CategoriesMainSliderPlaceholder";
import useCategoriesMainSliderProps from "./useCategoriesMainSliderProps";

export interface SliderItem {
  href: string;
  imageSrc?: string;
  title: string;
  categoryId: string;
}

const CategoriesMainSlider = () => {
  const localize = useLocalization();
  const {
    withNavButtons,
    slidesGroup,
    placeholderIsVisible,
    handleImpression,
  } = useCategoriesMainSliderProps();
  const title = localize('search.categories');

  if (placeholderIsVisible) {
    return (
      <Impressionable
        onImpressed={handleImpression}
        partialVisibility
      >
        <CategoriesMainSliderPlaceholder title={title}/>
      </Impressionable>
    );
  }

  return (
    <div className="CategoriesMainSlider">
      <SliderWrapper
        title={localize('search.categories')}
        dataMarker='categories main slider'
        withNavButtons={withNavButtons}
      >
        {
          slidesGroup.map((slides, index) => (
            <div className="CategoriesMainSlider__slide" key={`slide ${index}`}>
              {
                slides.map((slide) => (
                  <div key={slide.categoryId} className='CategoriesMainSlider__item'>
                    <MainCategoryCard
                      imageSrc={slide?.imageSrc}
                      title={slide.title}
                      linkProps={{
                        href: slide.href,
                        onClick: () => sendSubcategoryClick({
                          page: 'main',
                          place: 'category_tiles',
                          sliderIndex: index,
                          categoryId: slide.categoryId,
                        }),
                      }}
                    />
                  </div>
                ))
              }
            </div>
          ))
        }
      </SliderWrapper>
      <style jsx>{styles}</style>
    </div>
  );
};

export default CategoriesMainSlider;
