import Image from 'next/image';
import * as React from 'react';
import { styles } from './FooterAppsLinks.styles';

interface Props {
  title: string;
  appLinks: {
    android: string | null;
    ios: string | null;
  };
}

export const FooterAppsLinks: React.FC<Props> = props => {
  const { title, appLinks } = props;


  if(!appLinks.ios  && !appLinks.android) {
    return null;
  }

  return (
    <div className='FooterAppsLinks' data-testid='FooterAppsLinks'>
      <span className='FooterAppsLinks__title'>{title}</span>

      <ul className='FooterAppsLinks__list'>
        {appLinks.android && (
          <li
            className='FooterAppsLinks__item'
            data-testid='FooterAppsLinks_android'
          >
            <a
              className='FooterAppsLinks__link'
              href={appLinks.android}
              rel='nofollow'
            >
              <Image
                src={`/i/app-google.svg`}
                alt='Play Market'
                width={130}
                height={40}
              />
            </a>
          </li>
        )}

        {appLinks.ios && (
          <li
            className='FooterAppsLinks__item'
            data-testid='FooterAppsLinks_ios'
          >
            <a
              className='FooterAppsLinks__link'
              href={appLinks.ios}
              rel='nofollow'
            >
              <Image
                src={`/i/app-apple.svg`}
                alt='App Store'
                width={130}
                height={40}
              />
            </a>
          </li>
        )}
      </ul>

      <style jsx>{styles}</style>
    </div>
  );
};

export default FooterAppsLinks;
