import { BreadcrumbsItem } from '../../../components/views/Breadcrumbs/Breadcrumbs';

export default function getBreadcrumbsMicroMarking(
  breadcrumbs: BreadcrumbsItem[], baseURL: string,
) {
  const itemListElement = breadcrumbs.map((breadcrumb, index) => {
    const item = breadcrumb.as ? { item: baseURL + breadcrumb.as } : {};
    const name = breadcrumb.title;

    return {
      '@type': 'ListItem',
      position: index + 1,
      name,
      ...item,
    };
  });

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
}
