import { headerZIndex } from 'src/components/containers/Header/headerZIndex';
import css from 'styled-jsx/css';
import { desktop, phone, tablet } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  .CatalogFiltersMobile__panel {
    display: flex;
    border-top: 1px solid var(--colors-brandLightGrey1);
  }
  .CatalogFiltersMobile__panelItem {
    flex-shrink: 0;
    width: 50%;
    height: 45px;
  }
  .CatalogFiltersMobile__toggle {
    height: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    user-select: none;
  }
  .CatalogFiltersMobile__toggle_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .CatalogFiltersMobile__toggleIcon {
    margin-right: 15px;
    font-size: 18px;
    color: var(--colors-brandLightGrey2);
  }
  .CatalogFiltersMobile__wrap {
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: var(--colors-white);
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
  }
  .CatalogFiltersMobile__header {
    display: flex;
    height: 50px;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--colors-brandLightGrey1);
  }
  .CatalogFiltersMobile__content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .CatalogFiltersMobile__footer {
    margin-top: auto;
    border-top: 1px solid var(--colors-brandLightGrey10);
    display: flex;
    align-items: center;
  }
  .CatalogFiltersMobile__toggleLabel {
    ${typography.text}
    line-height: normal;
  }
  .CatalogFiltersMobile__toggle {
    border-bottom: 1px solid #efefef;
    background-color: white;
  }
  .CatalogFiltersMobile__panel_sticky {
    position: sticky;
    top: 50px;
    z-index: ${headerZIndex.indexOf('filtersMobile')};
  }
  @media ${phone} {
    .CatalogFiltersMobile__panelItem {
      border-right: 1px solid var(--colors-brandLightGrey1);
    }
    .CatalogFiltersMobile__panelItem:last-child {
      border-right: 0 none;
    }
    .CatalogFiltersMobile__wrap {
      right: 0;
    }
    .CatalogFiltersMobile__header {
      padding: 10px;
      font-size: 15px;
    }
    .CatalogFiltersMobile__footer {
      padding: 0 10px;
      height: 60px;
    }
    .CatalogFiltersMobile__toggleArrow {
      margin-left: auto;
      font-size: 8px;
    }
  }
  @media ${tablet} {
    .CatalogFiltersMobile__toggleArrow {
      margin-left: auto;
      margin-right: 0;
      display: flex;
    }
    .CatalogFiltersMobile__toggleArrow:before {
      font-size: 8px;
    }
    .CatalogFiltersMobile__wrap {
      right: 50%;
    }
    .CatalogFiltersMobile__header {
      ${typography.h3}
      padding: 14px 20px;
    }
    .CatalogFiltersMobile__footer {
      padding: 0 24px;
      height: 88px;
    }
    .CatalogFiltersMobile__overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      z-index: 9999;
      background: var(--colors-black);
      opacity: 0.3;
      height: 100vh;
      height: 100dvh;
    }
    .CatalogFiltersMobile__panel_sticky {
      top: 65px;
    }
    :global(.filters-open) {
      height: 100vh;
    }
  }
  @media ${desktop} {
    .CatalogFiltersMobile__panel {
      display: none;
    }
    .CatalogFiltersMobile__wrap {
      display: none;
    }
  }
  `;
