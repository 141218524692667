import Image from "next/image";
import Link from "next/link";
import { defaultProductImage } from "src/fixtures";
import { styles } from "./MainCategoryCard.styles";

export interface MainCategoryCardProps {
  imageSrc?: string;
  title: string;
  linkProps: {
    onClick: () => void;
    href: string;
  }
}

const MainCategoryCard = (props: MainCategoryCardProps) => {
  const { imageSrc, title, linkProps } = props;

  return (
    <Link {...linkProps}>
      <div className="MainCategoryCard">
        <div className="MainCategoryCard__title">
          {title}
        </div>
        <div className="MainCategoryCard__imageWrapper">
          <Image
            src={imageSrc || defaultProductImage}
            alt={title}
            width={110}
            height={96}
          />
        </div>
        <style jsx>{styles}</style>
      </div>
    </Link>
  );
};

export default MainCategoryCard;
