import css from 'styled-jsx/css';
import { desktop, phone, tablet_desktop } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  @media ${desktop} {
    .FooterContactsItem:last-child {
      margin-bottom: 0;
    }
  }
  .FooterContactsItem {
    margin-bottom: 11px;
    display: flex;
    align-items: center;
  }
  .FooterContactsItem__link,
  .FooterContactsItem__text {
    color: var(--colors-white);
    opacity: 0.6;
  }
  .FooterContactsItem__link {
    text-decoration: none;
  }
  .FooterContactsItem__link:hover {
    opacity: 1;
  }
  .FooterContactsItem__icon {
    font-size: 15px;
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.4);
  }

  @media ${tablet_desktop} {
    .FooterContactsItem {
      ${typography.text}
    }
  }

  @media ${phone} {
    .FooterContactsItem:nth-child(1) {
      order: 3;
      font-size: 26px;
      font-weight: 300;
    }
    .FooterContactsItem:nth-child(2) {
      order: 1;
    }
    .FooterContactsItem:nth-child(3) {
      order: 2;
    }
    .FooterContactsItem__icon {
      display: none;
    }
  }
  `;
