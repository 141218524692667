import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import { styles } from './ProductsSliderPlaceholder.style';

interface Props {
  title: string;
  dataMarker?: string;
}

export default function ProductsSliderPlaceholder(props: Props) {
  const { title, dataMarker } = props;

  return (
    <div className="ProductsSliderPlaceholder" data-marker={dataMarker}>
      <div className="ProductsSliderPlaceholder__title">
        {title}
      </div>
      <div className="ProductsSliderPlaceholder__inner">
        <div className="ProductsSliderPlaceholder__image">
          <RectShape color="#F8F8F8" className="ProductsSliderPlaceholder__shape" />
        </div>
        <div className="ProductsSliderPlaceholder__price">
          <TextBlock rows={2} color="#eee" />
        </div>
        <TextBlock rows={3} color='#F8F8F8' />
      </div>
      <style jsx>{styles}</style>
    </div>
  );
}
