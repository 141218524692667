import { ParsedUrlQuery } from 'querystring';

const toggleQueryFilter = (type: string, name: string, query: ParsedUrlQuery) => {
  const { page, ...newQuery } = query;

  const value = newQuery[type];
  
  if (!value) {
    newQuery[type] = name;
    return newQuery;
  }

  if (value === name) {
    delete newQuery[type];
    return newQuery;
  }
  
  if (typeof value === 'string') {
    newQuery[type] = [value, name];
    return newQuery;
  }
  
  if (Array.isArray(value)) {
    const valueIndex = value.indexOf(name);

    if (valueIndex !== -1) {
      value.splice(valueIndex, 1);
      newQuery[type] = [...value];

      if(newQuery[type].length === 1) {
        newQuery[type] = newQuery[type][0];
      }

      return newQuery;
    }

    newQuery[type] = [...value, name];
    return newQuery;
  }
  return newQuery;
};

export default toggleQueryFilter;