import { DeliveryType, QueryString } from 'src/redux/apiTypes';
import { Chain } from 'src/services/chain/types';
import { LocalizeFunc } from "src/services/localization/types";
import { RetailInfoLists, UserRetail } from '../types';
import getRetailInfo from './getRetailInfo';

interface GetRetailInfoListsParams {
  chains: Chain[];
  userRetails: UserRetail[];
  deliveryType: DeliveryType | null;
  deliveryAsQueryStringForLink: QueryString;
  language: string;
  localize: LocalizeFunc;
}

const getRetailInfoLists = (params: GetRetailInfoListsParams) => {
  const {
    chains,
    userRetails,
    deliveryType,
    language,
    deliveryAsQueryStringForLink,
    localize,
  } = params;

  const chainsMap: {
    [key: string]: Chain;
  } = chains.reduce((acc, chain) => {
    acc[chain.id] = chain;
    return acc;
  }, {});

  return userRetails.reduce<RetailInfoLists>((acc, userRetail) => {
    const chain = chainsMap[userRetail.chain_id];
    const isAvailable = !deliveryType || userRetail.is_delivery_available;

    if (!chain) {
      return acc;
    }

    const retailInfo = getRetailInfo({
      isAvailable,
      chain: chain,
      userRetail,
      deliveryType,
      deliveryAsQueryStringForLink,
      language,
      localize,
    });

    if (isAvailable) {
      acc.available.push(retailInfo);
    } else {
      acc.unavailable.push(retailInfo);
    }

    return acc;
  }, { available: [], unavailable: [] });
};

export default getRetailInfoLists;
