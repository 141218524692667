import Image from 'next/image';
import * as React from 'react';
import { styles } from './CategoriesMenuHeader.styles';

interface Props {
  title: string
  imageSrc?: string
}

const CategoriesMenuHeader: React.FC<Props> = props => {
  const { title, imageSrc } = props;

  return (
    <div className='CategoriesMenuHeader' title={title}>
      <div className='CategoriesMenuHeader__title'>
        {title}
      </div>
      <div className='CategoriesMenuHeader__container'>
        {
          imageSrc && (
            <Image
              src={imageSrc}
              alt={title}
              width={44}
              height={44}
            />
          )
        }
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default CategoriesMenuHeader;
