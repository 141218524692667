import { getCategoryUrl } from "src/modules/Categories";

export interface CategoryPageQuery {
  'category-id': string;
  lang: string;
  sort?: string;
}

interface Params {
  query: CategoryPageQuery;
  categoryId: string;
}

export const getCategoryHrefWithSort = ({ query, categoryId }: Params) => {
  const { sort, lang } = query;
  const sortQuery = sort ? `sort=${sort}` : '';
  const categoryUrl = getCategoryUrl({
    language: lang,
    categoryId,
    queryParams: sortQuery,
  });

  return categoryUrl;
};
