interface Params {
  language: string;
  categoryId: string;
  queryParams?: string;
}

export const getCategoryUrl = (params: Params) => {
  const {
    language,
    categoryId,
    queryParams,
  } = params;
  const searchString = queryParams ? `?${queryParams}` : '';
  return `/${language}/categories/${categoryId}/${searchString}`;
};
