import { Fragment } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import pluralize from '../../../utils/product/pluralize';

interface Props {
  number: number
}

export default function GoodsNumber(props: Props) {
  const { number } = props;
  const localize = useLocalization();
  const pluralization = pluralize(number, localize('general.items-plural'));

  return (
    <Fragment>
      {`${number} ${pluralization}`}
    </Fragment>
  );
}
