
import { useRouter } from "next/router";
import { Category } from "src/redux/apiTypes";
import useLocalization from 'src/services/localization/useLocalization';
import findCategoryObject from "src/utils/categories/findCategoryObject";
import { sendSubcategoryClick } from "../../helpers/analytics";
import { CategoryPageQuery, getCategoryHrefWithSort } from "../../helpers/getCategoryHrefWithSort";
import { CategoryTagProps } from "../CategoryTag/CategoryTag";

interface Params {
  categories: Category[];
  categoryLevel: number;
  selectedCategory: Category;
}

const useCategoriesTags = ({ categories, categoryLevel, selectedCategory }: Params): CategoryTagProps[] => {
  const { query } = useRouter<CategoryPageQuery>();
  const localize = useLocalization();
  const currentCategoryId = query['category-id'];

  const isSecondLevel = categoryLevel === 2;

  const firstTagCategoryId = isSecondLevel
    ? selectedCategory.id
    : selectedCategory.parent_id;
  const place = 'category_tags';

  const firstCategoryTag: CategoryTagProps = {
    title: localize('all'),
    href: getCategoryHrefWithSort({
      query,
      categoryId: firstTagCategoryId,
    }),
    isActive: isSecondLevel,
    onClick: () => sendSubcategoryClick({
      categoryId: firstTagCategoryId,
      page: `category_lvl${categoryLevel}`,
      place,
      sliderIndex: 0,
    }),
  };

  const categoriesToShow: Category[] = (isSecondLevel
    ? selectedCategory.children
    : findCategoryObject(categories, selectedCategory.parent_id)?.children)
    || [];

  const categoriesTags: CategoryTagProps[] = categoriesToShow.map((c, i) => ({
    title: c.title,
    href: getCategoryHrefWithSort({
      query,
      categoryId: c.id,
    }),
    isActive: currentCategoryId === c.id,
    onClick: () => sendSubcategoryClick({
      categoryId: c.id,
      page: `category_lvl${categoryLevel}`,
      place,
      sliderIndex: i + 1,
    }),
  }));

  return [firstCategoryTag, ...categoriesTags];
};

export default useCategoriesTags;
