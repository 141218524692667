import { Banner, EnhancedError } from 'src/redux/apiTypes';
import { getFetchBannersParams } from 'src/swr/useBanners/helpers/getFetchBannersParams';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from 'swr';
import { getFetcher } from "./helpers/getFetcher";
import { Banners, UseBannersOptions } from './types';

export default function useBanners(options: UseBannersOptions): Banners {
  const { fetchConfig } = useAppConfig();

  const { url, cacheKey } = getFetchBannersParams({
    ...options,
    apiRoot: fetchConfig.apiRoot,
  });

  const fetcher = getFetcher(url, fetchConfig);
  const key = options.canFetch ? cacheKey : null;

  const { data = [], error, isValidating } = useSWR<Banner[], EnhancedError>(key, fetcher);

  return {
    data,
    errors: error?.data?.errors,
    isFetching: isValidating,
  };
}
