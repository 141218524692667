import { ReactNode } from 'react';
import { styles } from './Tag.styles';

type TagVariant = 'clickable' | 'active';

interface TagProps {
  children: ReactNode;
  dataMarker?: string;
  variant?: TagVariant;
}

const Tag = (props: TagProps) => {
  const { dataMarker, children, variant = 'clickable' } = props;

  return (
    <span
      className={`Tag Tag_${variant}`}
      data-marker={dataMarker}
    >
      {children}
      <style jsx>{styles}</style>
    </span>
  );
};

export default Tag;
