import { RangeNumbers } from 'src/components/ui-kit/SliderField/SliderField';
import { LocalizeFunc } from "src/services/localization/types";
import { Currency } from '../appConfig/getInitialAppConfig';

interface Params {
  price: RangeNumbers;
  isDesktop?: boolean;
  localize: LocalizeFunc;
  currency: Currency;
}

const getPriceAndCurrencyText = (params: Params): string => {
  const { price, isDesktop = true, localize, currency } = params;

  const min = price[0] / 100;
  const max = price[1] / 100;

  if (isDesktop) {
    const currencyText = localize(`general.currency.${currency}`);

    return `${min} ${currencyText} - ${max} ${currencyText}`;
  }

  return `${min} - ${max}`;
};

export default getPriceAndCurrencyText;
