import { Selector, createSelector } from '@reduxjs/toolkit';
import { Category, CategoryFilterLike } from '../apiTypes';
import { RootState } from '../reducers';
import { CustomCategory } from './categoriesReducers';

export interface CategoriesMap {
  [categoryId: string]: Category
}

export const selectCategories: Selector<
  RootState, Category[]
> = createSelector(
  [
    (state: RootState) => state.categories.categories,
  ],
  (
    categories,
  ) => {
    return categories;
  },
);

export const selectFiltersLikeCategories: Selector<
  RootState, CategoryFilterLike[]
> = createSelector(
  [
    (state: RootState) => state.categories.filtersLikeCategories,
  ],
  (
    filtersLikeCategories,
  ) => {
    return filtersLikeCategories;
  },
);

export const selectOwnBrandsCount: Selector<
  RootState, number
> = createSelector(
  selectFiltersLikeCategories,
  filtersLikeCategories => {
    return filtersLikeCategories.find(c => c.id === 'own-brands')?.available_count || 0;
  },
);

export const selectB2BData: Selector<
  RootState, CustomCategory
> = createSelector(
  [(state: RootState) => state.categories.b2bProducts],
  customCategory => {
    return customCategory;
  },
);

export const getFirstLevelCategoriesMap = createSelector(
  selectCategories,
  categories => {
    const categoriesMap: CategoriesMap = {};
    categories.forEach(category => {
      categoriesMap[category.id] = category;
    });
    return categoriesMap;
  },
);


