import { UniversalObject } from 'globals';
import { FC } from 'react';

interface Props {
  body: UniversalObject;
}

const ScriptLdJson: FC<Props> = props => {
  const { body } = props;

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={ { __html: JSON.stringify(body, null, 2) } }
    />
  );
};

export default ScriptLdJson;
